import Button from "@material-ui/core/Button";
import useLongOp from "../CommonUtils/LongOp";
import WheelAPI from "../BackendLayer/WheelAPI";
import WaitingContainer from "../Wrappers/WaitingContainer";

export default function HaloSeedView(){
    let seedHaloOp = useLongOp("Seeding halo wheels");

    function seedHalo(){
        seedHaloOp.runner(() => WheelAPI.seedHaloWheels())
    }

    return (
        <WaitingContainer waitingTrackers={[seedHaloOp.waitingTracker]}>
            <Button variant={"contained"} onClick={() => seedHalo()}>Seed halo wheels</Button>
        </WaitingContainer>
    )
}