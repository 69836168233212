import React, {useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles, Tooltip, Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import AuthAPI, {UserInfo} from "../BackendLayer/AuthAPI";
import Link from "@material-ui/core/Link";
import ButcherSortMenu from "./AppBarMenus/ButcherSortMenu";
import UserMenu from "./AppBarMenus/UserMenu";
import TokenRegular from "../images/SilverTokenSmall.png"
import TokenBrutal from "../images/BrutalTokenSmall.png"
import OrderOption from "../Constants/OrderOptions";
import ButcherBrowserSearch from "./ButcherBrowserSearch";
import SortIcon from '@material-ui/icons/Sort';
import {Help, Refresh} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        alignContent: "center",
        width: "100%",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('xl')]: {
            display: 'block',
            margin: "0 20px"
        },
    },
    searchControls: {
        display: 'flex',
        marginLeft: "auto",
        alignContent: "flex-end",
        alignItems: "center",
        justifyContent: "stretch",
    },
    sectionDesktop: {
        display: 'flex',
        marginLeft: "auto",
        justifyContent: "stretch",
        alignContent: "flex-end",
        alignItems: "center"

    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    infoElement:{
        margin: "0 10px"
    },
    userName: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "block",
            margin: "0 10px"
        },
    },
    tokenIcon: {
        width: 32,
        height: 32,
    },
    twitchLogo: {
        height: "24px",
        position: "relative",
        top: "10px",
    },
    refreshIcon: {
        height: "100%",
        color: "white",
    }
}));

function useMenu(onClose: (p?: any) => void, Component: any, parameters: null | object = {}){
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (value: any) => {
        setAnchorEl(null);
        onClose(value)
    }
    const menu = (<Component {...parameters} anchorEl={anchorEl} open={open} handleClose={handleClose}></Component>)
    return { handleMenu, menu }
}

export interface HomykAppBarProps {
    setSearchOrder: (searchOrder: OrderOption) => void,
    userData: UserInfo | null,
    embedded: boolean,
    onClickBack: (p: any) => void,
    searchFilter: string,
    setSearchFilter: (newFilter: string) => void,
    isMobile: boolean | undefined,
}

export default function HomykAppBar(props: HomykAppBarProps){
    const setSearchOrder = (searchOrder: OrderOption) =>{
        if (searchOrder){
            props.setSearchOrder(searchOrder);
        }
    }

    let {handleMenu: handleSortMenu, menu: sortMenu } = useMenu(setSearchOrder, ButcherSortMenu)
    let {handleMenu: handleUserMenu, menu: userMenu } = useMenu(() => {}, UserMenu, {userData: props.userData, embedded: props.embedded})

    let [searching, setSearching] = useState<boolean>(false);
    const loginLink = props.embedded ? undefined : AuthAPI.GetTwitchLoginLink();
    const useCompactLoginButton = window.innerWidth < 600;
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar variant={"dense"}>
                {props.onClickBack && props.embedded &&
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={props.onClickBack}>
                    {"<"}
                </IconButton>}
                <Typography variant={"h6"} className={classes.title}>
                    The Mosh Pit's Brawler Gallery
                </Typography>
                <div className={classes.searchControls}>
                    <Tooltip title={"Sort"}>
                        <SortIcon onClick={handleSortMenu}></SortIcon>
                    </Tooltip>
                    {sortMenu}
                    <ButcherBrowserSearch
                        searchFilter={props.searchFilter}
                        onSearchingToggle={(value) => setSearching(value)}
                        onChange={(event) => props.setSearchFilter(event.target.value)}
                        isMobile={props.isMobile}
                    />
                </div>
                {(!!props.userData && (!props.isMobile || !searching)) ? <div className={classes.sectionDesktop} >
                    {props.embedded ? <Tooltip disableFocusListener title={"For more info on how this whole thing works, type !vbhelp in chat or go to https://vbmoshpit.com/help"}><IconButton size="small" className={classes.refreshIcon}><Help/></IconButton></Tooltip>
                        : <a href={"https://vbmoshpit.com/help"}><Tooltip disableFocusListener title={"Click for more info on how this whole thing works"}><IconButton size="small" onClick={() => window.location.reload()} className={classes.refreshIcon}><Help/></IconButton></Tooltip></a>}
                        <Tooltip disableFocusListener title={"Refresh"}><IconButton size="small" onClick={() => window.location.reload()} className={classes.refreshIcon}><Refresh/></IconButton></Tooltip>
                        <IconButton>
                            <Tooltip disableFocusListener title={"Regular brawler tokens. Each allows you to activate a non-BRÜTAL brawler"}>
                                <Badge showZero badgeContent={props.userData.butcherTokens} color={"secondary"}>
                                    <img alt="Brawler tokens" className={classes.tokenIcon} src={TokenRegular}/>
                                </Badge>
                            </Tooltip>
                        </IconButton>
                        <IconButton>
                            <Tooltip disableFocusListener title={"BRÜTAL tokens. Each allows you to activate a BRÜTAL brawler"}>
                                <Badge showZero badgeContent={props.userData.brutalButcherTokens} color={"secondary"}>
                                    <img alt="Brütal brawler tokens" className={classes.tokenIcon} src={TokenBrutal}/>
                                </Badge>
                            </Tooltip>
                        </IconButton>
                        {!props.embedded &&
                            <React.Fragment>
                                <Button onClick={handleUserMenu}>
                                    <Typography variant={"h6"} className={classes.userName}>
                                        {props.userData.displayName}
                                    </Typography>
                                    <Avatar alt={props.userData.displayName} src={props.userData.avatarUrl}/>
                                </Button>
                            {userMenu}
                            </React.Fragment>
                        }
                    </div>
                    : <div className={classes.sectionDesktop}>
                        {!props.embedded &&
                        <Link href={loginLink}>
                            {/*{!useCompactLoginButton && <img src={TwitchLogo} className={classes.twitchLogo}/>}*/}
                            <Button>Login with Twitch</Button>
                        </Link>
                        }
                    </div>}
            </Toolbar>
        </AppBar>
    );
}

