
interface BackendConfig{
    host: string | undefined,
    port: string | undefined,
    frontendPort: string | undefined,
    protocol: string | undefined,
    twitchAppClientId: string | undefined,
    getBaseRoute: () => string
    dumpEverything: () => void
}

export const backendConfig : BackendConfig =
    {
        host: process.env.REACT_APP_BACKENDHOST,
        port: process.env.REACT_APP_BACKENDPORT,
        frontendPort: process.env.REACT_APP_FRONTENDPORT,
        protocol: process.env.REACT_APP_BACKENDPROTOCOL,
        twitchAppClientId: process.env.REACT_APP_TWITCH_APP_CLIENT_ID,
        getBaseRoute: () => {
            const port = backendConfig.port ? ":" + backendConfig.port : "";
            return backendConfig.protocol + "://" + backendConfig.host + port + "/"
        },
        dumpEverything: () => {
            console.log(process.env.REACT_APP_BACKENDHOST)
            console.log(process.env.REACT_APP_BACKENDPORT)
            console.log(process.env.REACT_APP_BACKENDPROTOCOL)
            console.log(process.env.REACT_APP_FOX)
        }
    }