import React from "react"
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { PricingTier } from "../Constants/PricingCategories";

const useStyles = makeStyles((theme) => ({
    butcherTitle: {
        color: theme.palette.primary.light,
        [theme.breakpoints.down('xs')]: {
            display: "block",
            fontSize: theme.typography.h6.fontSize
        },
        [theme.breakpoints.down('sm')]: {
            display: "block",
            fontSize: theme.typography.h4.fontSize
        },
    },
    butcherSubtitle: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3)
    },
}));

export interface ButcherDetailsDataProps
{
    id?: string,
    name: string,
    tagline: string,
    description: string,
    tags: string,
    origin: string,
    pricingCategory: PricingTier
}

export default function ButcherDetailsData(props: ButcherDetailsDataProps){
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid item>
                <Typography component={"h3"} variant={"h3"} className={classes.butcherTitle}>
                    {props.name}
                </Typography>
                <Typography component={"h6"} variant={"h6"} className={classes.butcherSubtitle}>
                    {props.tagline}
                </Typography>
            </Grid>
            {props.description &&
            <Grid item>
                <Typography variant={"body1"}>
                    {props.description}
                </Typography>
            </Grid>}
            {props.origin &&
            <Grid item>
                <Typography color="primary" variant={"body1"}>
                    (From: {props.origin})
                </Typography>
            </Grid>}
        </React.Fragment>
    )
}