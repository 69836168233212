import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    table: {
        width: "300px"
    }
}))

export default function WheelchanInstructions(){
    let classes = useStyles();
    return (
        <div>
            <Table size="small" className={classes.table}>
                <TableRow>
                    <TableCell>Play/Pause music</TableCell>
                    <TableCell>P</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Rewind music</TableCell>
                    <TableCell>R</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Start Intro</TableCell>
                    <TableCell>I</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Skip Intro</TableCell>
                    <TableCell>K</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Start spinning</TableCell>
                    <TableCell>S</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Fade out</TableCell>
                    <TableCell>B</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Change music volume</TableCell>
                    <TableCell>V</TableCell>
                </TableRow>
            </Table>
        </div>
    )
}