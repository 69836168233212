import axios from 'axios'
import {backendConfig} from "./Config";
import {Debug} from "../Constants/DevelopmentConstants";
import AccessTier from "../Constants/AccessTiers";

const baseURL = backendConfig.getBaseRoute();
if (Debug) { console.log("baseURL: " + baseURL)}
const configUrl = baseURL + "Configuration/";

const instance = axios.create(({
    withCredentials: true
}))

export interface BroadcasterConfiguration {
    normalButcher: ButcherPriceConfiguration;
    brutalButcher: ButcherPriceConfiguration;
    butcherDurationInMs: number;
    breakDurationInMs: number;
    userPermissions: UserPermissionConfigurationClientSide[];
}

export interface ButcherPriceConfiguration {
    cheerPrice: number;
    tipPrice: number;
}

export interface UserPermissionConfigurationClientSide {
    id: string,
    userName: string;
    userTwitchId: string | null;
    accessTier: AccessTier;
    verified: boolean,
    alreadyInDatabase: boolean
}

function GetConfiguration(){
    return instance.get<BroadcasterConfiguration>(configUrl).then(result => {
        result.data.userPermissions.forEach(row => {
            row.verified = true;
            row.alreadyInDatabase = true
        });
        return result;
    })
}

function UpdateConfiguration(newConfig: BroadcasterConfiguration){
    return instance.put<BroadcasterConfiguration>(configUrl, newConfig);
}

const ConfigurationApi = {
    GetConfiguration,
    UpdateConfiguration
}

export default ConfigurationApi;