import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useSpring} from "react-spring";
import {useActivationWebsockets, usePassiveLogin} from "./ExtensionViewState";
import {useButcherActivations} from "../../CommonUtils/DataFetchers/ButcherActivations";
import {useDirectorData} from "../../CommonUtils/DataFetchers/DirectorData";
import WaitingContainer from "../../Wrappers/WaitingContainer";
import ButcherBrowser from "../../Components/ButcherBrowser/ButcherBrowser";
import ShowOnlyOnHoverDiv from "./ShowOnlyOnHoverDiv";
import ExtensionView from "./ExtensionView";
import MoshPitLogoNoTitle from "../../images/moshpitlogonotitle.png";

import {Tooltip} from "@material-ui/core";
import TwitchHelper from "../../CommonUtils/Twitch/TwitchHelper";

const mainOpacity = 0.6;
const useStyles = makeStyles((theme) => ({
    sideBar: {
        position: "absolute",
        top: "15px",
        margin: "16vh 0px",
    },
    sideBarButcherList : {
        flexWrap: "wrap",
        maxHeight: "70vh",
        margin: "8px 0px",
        alignContent: "start",
        [theme.breakpoints.down("md")]:{
            maxHeight: "65vh",
        },
        [theme.breakpoints.down("sm")]:{
            maxHeight: "55vh"
        }
    },
    sidebarButcherThumbnail: {
        maxWidth: "48px",
        opacity: mainOpacity,
        // margin: "8px",
        '&:hover': {
            opacity: 1
        },
        margin: "0px 0px 8px 8px"
    },
    regularText: {
        display: "inline-block",
        borderRadius: "0px 12px 12px 0px",
        padding: "2px 12px 0px 12px",
        fontSize: "1em",
        opacity: 1,
        margin: "8px 0px",
    },
    lowOpacity: {
        opacity: mainOpacity
    },
    detailedViewContainer: {
        top: "100px",
        left: "10px",
        position: "fixed"
    },
    collapsedDetailedView:{
        opacity: mainOpacity,
        width: "35%",
        margin: "16px",
    },
}))



export default function ExtensionContainer(){
    const classes = useStyles();
    let butcherActivationData = useButcherActivations();
    let { activationData, loadingTracker } = butcherActivationData;
    let directorData = useDirectorData();
    let { loadingTracker: stateLoadingTracker, fullDirectorData} = directorData;
    let { loadingTracker: loginWaitingTracker, login, passiveLoginState, currentUserData, modifyTokenAmount, hasTokens, hotReload} = usePassiveLogin();

    let {showNewRedemptionButton, cancelRedemptionNotification} = useActivationWebsockets(
        butcherActivationData,
        directorData,
        currentUserData);

    let userDataBundle = {currentUserData, loadingTracker: loginWaitingTracker, modifyTokenAmount, hotReload} // TODO: modifyTokenAMount function needed?

    // @ts-ignore
    const twitch = window.Twitch ? window.Twitch.ext : null;
    const requestTwitchIdShare = () => TwitchHelper.requestTwitchIdShare(twitch);
    useEffect(() => {
        if (currentUserData == null && twitch) {
            twitch.onAuthorized((auth: any) => {
                console.log("Running onAuthorized with payload", auth);
                login(auth.token, twitch)
            })
        }
    }, [currentUserData]);

    // token reminder logic
    let tokenReminderControls = {
        hasTokens: hasTokens,
        dismissTokenReminder: cancelRedemptionNotification,
        tokenReminderDismissButtonActive: () => showNewRedemptionButton && hasTokens()
    }

    // hovering on player controls
    let [playerHovering, setPlayerHovering] = useState(false);
    let [holdHover, setHoldHover] = useState(false);
    let showDetailsView = holdHover || playerHovering || tokenReminderControls.tokenReminderDismissButtonActive();
    let {left: hoverPositionAnimation} = useSpring({left: showDetailsView ? "0px" : "-400px" , config: {tension: 600, clamp: true}})
    // collapsed view controls
    let [detailsViewCollapsed, setDetailsViewCollapsed] = useState(true);
    let [showButcherBrowser, setShowButcherBrowser] = useState(false);
    let [currentPage, setCurrentPage] = useState<number>(1);

    return (
        <React.Fragment>
            <div style={{position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh"}}
                onMouseEnter={() => setPlayerHovering(true)}
                onMouseLeave={() => setPlayerHovering(false)}
            >
            <WaitingContainer nonDisruptive waitingTrackers={[loadingTracker, stateLoadingTracker]}>
                {showDetailsView
                    ?
                    <div className={classes.detailedViewContainer}>
                        {showButcherBrowser ?
                            <div style={{width: "95vw"}}>
                            <ButcherBrowser
                                embedded
                                paginated
                                requestTwitchIdShare={requestTwitchIdShare}
                                onDoneBrowsing={() => setShowButcherBrowser(false)}
                                directorData={directorData}
                                userData={userDataBundle}
                                setHoldHover={setHoldHover}
                                isMobile={false}
                                passiveLoginState={passiveLoginState}
                                currentPageNumber={currentPage}
                                setCurrentPageNumber={setCurrentPage}
                            />
                            </div>
                            :
                            <ShowOnlyOnHoverDiv left={hoverPositionAnimation}>
                                {detailsViewCollapsed && !tokenReminderControls.tokenReminderDismissButtonActive()
                                    ?
                                    <Tooltip title={"Show voice acting on demand extension, yeah!"}><img alt="minimized extension icon" className={classes.collapsedDetailedView} src={MoshPitLogoNoTitle}
                                                                           onClick={() => setDetailsViewCollapsed(false)}/></Tooltip>
                                    :
                                    <ExtensionView directorData={fullDirectorData}
                                                   butcherActivationData={activationData}
                                                   {...tokenReminderControls}
                                                   collapse={() => setDetailsViewCollapsed(true)}
                                                   startBrowsing = {() => setShowButcherBrowser(true)}
                                    />
                                }
                            </ShowOnlyOnHoverDiv>
                        }
                    </div>
                    :
                    <div></div>
                }
            </WaitingContainer>
            </div>
        </React.Fragment>
    )
}