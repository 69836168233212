export interface Audio {
    play: () => void
    volume: number,
}

export function playSound(audioElement: HTMLAudioElement | undefined, volume: number){
    if (audioElement){
        console.log("Audio element", audioElement);
        audioElement.load();
        audioElement.volume = volume;
        audioElement.play().then(() => console.log("Finished playing"))
    }
}