import makeStyles from "@material-ui/core/styles/makeStyles";
import DirectorStatusControlsSpecific from "./DirectorStatusControlsSpecific";
import DirectorState from "../../Constants/DirectorState";

const useStyles = makeStyles((theme) => ({
    pauseButton: {
        backgroundColor: "#fffe72",
        "&:hover": {
            backgroundColor: "#e0b60e"
        }
    },
    resumeButton: {
        backgroundColor: "#082de5",
        "&:hover": {
            backgroundColor: "#063072"
        }
    },
    startBreakButton: {
        backgroundColor: "#007972",
        "&:hover": {
            backgroundColor: "#014a46"
        },
        fontSize: "0.5em"
    },
    onABreakButton: {
        backgroundColor: "#797979",
        "&:hover": {
            backgroundColor: "#797979"
        }
    },
    cancelButton: {
        backgroundColor: "#b30101",
        "&:hover": {
            backgroundColor: "#660000"
        }
    },
    readyToStartButton: {
        backgroundColor: "#7cb0ff",
        "&:hover": {
            backgroundColor: "#660000"
        }
    }
}))

export interface DirectorStatusStyles {
    pauseButton: object,
    resumeButton: object,
    startBreakButton:object,
    onABreakButton: object,
    readyToStartButton: object,
    cancelButton: object,
}

export interface DirectorStatusButtonConfig {
    text: string | null,
    nextState: DirectorState | null,
    disabled: boolean,
    style: any,
    isAbortButton?: boolean
}
export interface DirectorStatusControlsConfig {
    title: {
        text: string,
    },
    bigButton: DirectorStatusButtonConfig,
    smallButton: DirectorStatusButtonConfig
}

const readyStateConfig = (styles: DirectorStatusStyles) => ({
    title: {
        text: "Ready, waiting for brawlers"
    },
    bigButton: {
        text: "START BREAK",
        nextState: DirectorState.OnABreak,
        disabled: false,
        style: styles.startBreakButton
    },
    smallButton: {
        text: null,
        nextState: null,
        disabled: true,
        style: null,
    }
})

const activeStateConfig = (styles: DirectorStatusStyles) => ({
    title: {
        text: "LIVE"
    },
    bigButton: {
        text: "PAUSE",
        nextState: DirectorState.Paused,
        disabled: false,
        style: styles.pauseButton
    },
    smallButton: {
        text: "END BRAWLERS",
        nextState: DirectorState.Ready,
        disabled: false,
        style: styles.cancelButton,
        isAbortButton: true
    }
})

const pausedStateConfig = (styles: DirectorStatusStyles) => ({
    title: {
        text: "PAUSED"
    },
    bigButton: {
        text: "RESUME",
        nextState: DirectorState.Active,
        disabled: false,
        style: styles.resumeButton
    },
    smallButton: {
        text: "END SESSION",
        nextState: DirectorState.Ready,
        disabled: false,
        style: styles.cancelButton,
        isAbortButton: true
    }
})

const onABreakStateConfig = (styles: DirectorStatusStyles) => ({
    title: {
        text: "On a break",
    },
    bigButton: {
        text: "CHILL",
        nextState: null,
        disabled: true,
        style: styles.onABreakButton
    },
    smallButton: {
        text: "GET BACK TO WORK",
        nextState: DirectorState.Ready,
        disabled: false,
        style: styles.cancelButton
    }
})

const awaitingStartConfirmation = (styles: DirectorStatusStyles) => ({
    title: {
        text: "Ready to start",
    },
    bigButton: {
        text: "START",
        nextState: DirectorState.Active,
        disabled: false,
        style: styles.readyToStartButton
    },
    smallButton: {
        text: "Cancel this batch",
        nextState: DirectorState.Ready,
        disabled: false,
        style: styles.cancelButton,
        isAbortButton: true,
    }
})

const defaultStateConfig = (styles: DirectorStatusStyles) => ({
    title: {
        text: "Uh",
    },
    bigButton: {
        text: "This isn't implemented",
        nextState: null,
        disabled: true,
        style: styles.onABreakButton
    },
    smallButton: {
        text: "OH NO",
        nextState: null,
        disabled: false,
        style: styles.cancelButton
    }
})

function GetConfig(state: DirectorState | null, styles: DirectorStatusStyles){
    switch (state){
        case DirectorState.Ready:
            return readyStateConfig(styles)
        case DirectorState.Active:
            return activeStateConfig(styles)
        case DirectorState.Paused:
            return pausedStateConfig(styles)
        case DirectorState.OnABreak:
            return onABreakStateConfig(styles)
        case DirectorState.AwaitingStartConfirmation:
            return awaitingStartConfirmation(styles)
        default:
            return defaultStateConfig(styles)
    }
}

export interface DirectorStatusControlsProps{
    directorState: DirectorState | null,
    changeState: (newState: DirectorState) => void,
    abort: () => void,
}

export default function DirectorStatusControls(props: DirectorStatusControlsProps){
    const classes: any = useStyles();
    const directorState = props.directorState;
    let config = GetConfig(directorState, classes);
    return(
        <div>
            <DirectorStatusControlsSpecific {...config} changeState={props.changeState} abort={props.abort}/>
        </div>
    )
}