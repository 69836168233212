import React, {useState} from "react";
import {Popover, PopoverOrigin} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    popover: {
        border: "2px solid " + theme.palette.primary.main,
        padding: "8px"
    }
}));

export default function usePopover(
            id: string,
            ContentsComponent: any,
            props: object = {},
            anchorOrigin?: PopoverOrigin,
            transformOrigin?: PopoverOrigin)
{
    const classes = useStyles();
    let [anchor, setAnchor] = useState<any>(null);
    let open = Boolean(anchor);
    const onClose = () => {
        setAnchor(null);
    }
    let popoverComponent = (<Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={anchorOrigin ?? {
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={transformOrigin ?? {
            vertical: 'bottom',
            horizontal: 'center',
        }}
        PaperProps={{className: classes.popover}}
    >
        <ContentsComponent {...props}/>
    </Popover>)

    return {
       setAnchor,
       popoverComponent
    }
}