import DirectorState from "../../Constants/DirectorState";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDirectorData} from "../../CommonUtils/DataFetchers/DirectorData";
import {useButcherActivations} from "../../CommonUtils/DataFetchers/ButcherActivations";
import {useActivationWebsockets} from "../ExtensionView/ExtensionViewState";
import MoshPitLogoFull from "../../images/moshpitlogosmolcropped.png";


const mainOpacity = 0.6;
const useStyles = makeStyles((theme) => ({
    sideBar: {
        position: "absolute",
        top: "0px",
        margin: "0vh 0px",
    },
    sideBarButcherList : {
        flexWrap: "wrap",
        maxHeight: "100vh",
        margin: "8px 0px",
        alignContent: "start",
    },
    sidebarButcherThumbnail: {
        maxWidth: "48px",
        opacity: 1,
        // margin: "8px",
        '&:hover': {
            opacity: 1
        },
        margin: "0px 0px 8px 8px"
    },
    sidebarButcherThumbnailBig: {
        width: "150px",
        height: "150px",
        opacity: 1,
        // margin: "8px",
        '&:hover': {
            opacity: 1
        },
        margin: "0px 0px 8px 8px"
    },
    regularText: {
        display: "inline-block",
        borderRadius: "0px 12px 12px 0px",
        padding: "2px 12px 0px 12px",
        fontSize: "1em",
        opacity: 1,
        margin: "8px 0px",
    },
    bigText: {
        display: "inline-block",
        borderRadius: "0px 12px 12px 0px",
        padding: "2px 12px 0px 12px",
        fontSize: "2em",
        opacity: 1,
        margin: "8px 0px",
    },
    lowOpacity: {
        opacity: 1
    },
    titleImage:{
        display: "block",
        width: "20%",
        margin: "8px",
    },
    titleImageBig:{
        display: "block",
        margin: "8px",
    },
}))

export interface ActiveButchersSidebarProps{
    showEnqueuedButchers: boolean,
    useBigVersion: boolean,
}

export function ActiveButchersSidebar(props: ActiveButchersSidebarProps){
    const classes = useStyles();
    let directorData = useDirectorData();
    let butcherActivationData = useButcherActivations();
    let { activationData } = butcherActivationData;
    useActivationWebsockets(
        butcherActivationData,
        directorData,
        null);

    return (
        <div className={classes.sideBar}>
            {directorData && (directorData.directorState === DirectorState.Active || directorData.directorState == DirectorState.Paused) &&
            (<img alt="The Vocal Butcher's Mosh Pit Logo"
                  className={props.useBigVersion ? classes.titleImageBig : classes.titleImage}
                  src={MoshPitLogoFull}/>)
            }
            {directorData && directorData.directorState === DirectorState.Paused &&
            (<Typography className={props.useBigVersion ? classes.bigText : classes.regularText}
                         style={{backgroundColor: "#e0b60e"}} variant={"body1"}>
                PAUSED!
            </Typography>)
            }
            {directorData && directorData.directorState === DirectorState.Active &&
            (<Typography className={props.useBigVersion ? classes.bigText : classes.regularText}
                         style={{backgroundColor: "#f00", color: "#fff"}} variant={"body1"}>
                BRAWLING!
            </Typography>)
            }
            <Box className={classes.sideBarButcherList} display={"flex"} flexDirection={"column"} flexWrap="wrap">
                {activationData && activationData.map(activation =>
                    (<Avatar alt={activation.butcher.name} src={activation.butcher.thumbnailRoute}
                             className={props.useBigVersion ? classes.sidebarButcherThumbnailBig : classes.sidebarButcherThumbnail}/>))}
            </Box>
        </div>
    )
}