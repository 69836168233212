import React from "react";
import WaitingBackdrop from "../Components/WaitingBackdrop";
import ErrorDialog from "../Components/ErrorDialog";
import {GetWaitingFlags} from "./WaitingContainerState";
import ErrorSnackBar from "./ErrorSnackbar";
import {WaitingTracker} from "../CommonUtils/LongOp";

// fetchers being an array of (propertyName, fetcher)
export interface WaitingContainerProps{
    children: any, // TODO: define type
    waitingTrackers: WaitingTracker[],
    overrideShowContent?: boolean,
    nonDisruptive?: boolean,
    showContentsOnError?: boolean,
}

export default function WaitingContainer(props : WaitingContainerProps)
{
    let {waiting, error, message, showContentWhileWaiting} = GetWaitingFlags(props.waitingTrackers);
    showContentWhileWaiting = showContentWhileWaiting || (props.overrideShowContent ?? false)

    return (<div>
        {error && (props.nonDisruptive
            ? <ErrorSnackBar open={true} message={error.message}/>
            : <ErrorDialog open={!!error} error={error}/>)}
        {(!error || props.showContentsOnError) && <div>
                {waiting && !props.nonDisruptive && <WaitingBackdrop loading message={message ?? "Hol up a minute"}/>}
                {(!waiting || showContentWhileWaiting) && props.children }
            </div>
        }
    </div>)
}