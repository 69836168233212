import React from "react"
import {animated} from "react-spring";

export interface ShowOnlyOnHoverDivProps{
    left: any, //string | number,
    children: any, //React.Component[] | React.Component
}
export default function ShowOnlyOnHoverDiv(props: ShowOnlyOnHoverDivProps){
    return(
        <animated.div key={"mainSidebarDiv"}  style={{position: "relative", left: props.left}}>
            {props.children}
        </animated.div>
        )
    }