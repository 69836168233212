import React from "react";
import "./popupStyle.css"
import TypewriterTextEffect from "./TypewriterTextEffect";
import AnimationCoordinator from "./animationCoordinator";
import CanvasUtils from "./canvasUtils";

export default class SkullPopup extends React.Component{
    constructor(props){
        super(props)
        this.canvasRef = React.createRef();
        this.state = {
            width: 200,
            height: 308,
            cornerSize: 20,
            borderSize: 5,
            text: this.props.text,
            description: this.props.description,
            skullPicture: this.props.backgroundImg
        }
        this.animationLength = 400;
        this.textMargin = 10;
        this.descriptionTextLineHeight = 16;
        this.highlightPosition = 0;
        this.showHighlight = true;

    }

    getTextSectionMaxWidth(){
        return Math.max(this.textMargin*2 + this.textWidth, 300);
    }

    animateOpening(timeDelta){
        if (this.state.width >= 200 + this.getTextSectionMaxWidth()){
            return false;
        }
        this.setState((state, props) =>({width: state.width + this.textWidth * this.easeOut(timeDelta/this.animationLength)}))
        return true;
    }


    componentDidMount(){

        this.animationCoordinator = new AnimationCoordinator();
        this.animationCoordinator.registerAnimation("menuOpening", (timestamp) => this.animateOpening(timestamp), false);
        this.canvas = this.canvasRef.current;
        this.ctx = this.canvas.getContext("2d");
        // this.skull = new Image();
        this.skull = this.state.skullPicture;
        this.setTextStyle();
        this.textWidth = this.ctx.measureText(this.state.text).width / 2; // 0.5 X scale to make the halo reach font effect
        this.typeWriterText = new TypewriterTextEffect(
            this.ctx,
            this.state.description,
            "#70b5ec",
            "1em Franklin Gothic",
            this.getTextSectionMaxWidth(),
            this.descriptionTextLineHeight);
        this.animationCoordinator.registerAnimation("description", (timestamp) => this.typeWriterText.tickAnimations(timestamp));
        this.animationCoordinator.registerAnimation("highlight", (timestamp) => this.animateHighlight(timestamp), true);
        this.animationCoordinator.registerOnEndChain("menuOpening", "description", 500);
        this.animationCoordinator.registerAnimation("acceptButton",() => {this.showDismissButton = true; return false}, false);
        this.animationCoordinator.registerOnEndChain("description", "acceptButton", 600);
        this.ctx.font = "1em Franklin Gothic";
        setTimeout(() => this.animationCoordinator.setAnimationActive("menuOpening", true), 600);
        this.frameId = window.requestAnimationFrame((timestamp) => this.draw());
    }

    draw(timestamp){
        this.animationCoordinator.tick(timestamp);
        this.clearCanvas();
        this.drawBorder(this.state.width, this.state.height, this.state.cornerSize, this.state.borderSize);
        this.drawPicture(4,4);
        this.drawText(this.state.text, 200, 100, 0.5);
        this.typeWriterText.draw({x: 200, y: 154});
        this.drawHighlight(timestamp);
        if (this.frameId == null){
            this.canvas = null;
            this.ctx = null;
            this.animationCoordinator = null;
            this.typeWriterText = null;
        }
        else{
            this.frameId = window.requestAnimationFrame((timestamp) => this.draw(timestamp))
        }
    }

    drawHighlight(timedelta){
        if (this.showHighlight){
            this.ctx.fillStyle="rgba(255,255,255,0.6)";
            this.ctx.fillRect(this.highlightPosition, 0, 10, 308);
        }
    }

    componentWillUnmount(){
        window.cancelAnimationFrame(this.frameId);
        this.frameId = null;
    }

    animateHighlight(timedelta){
        if (this.highlightPosition >= 190){
            this.showHighlight = false;
            return false;
        }
        this.highlightPosition += (timedelta/1000) * 900;
        return true;
    }

    easeOut(x) {
        return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);

    }

    drawPicture(x, y){
        this.ctx.drawImage(this.skull, x, y);
    }
    drawText(text, x, y, xScale = 1){
        this.setTextStyle();
        this.ctx.save();
        this.ctx.scale(xScale,1);
        CanvasUtils.drawTextCentered(this.ctx, text, {x,y}, this.getTextSectionMaxWidth(), 0.5);
        this.ctx.restore();
    }
    setTextStyle(){
        this.ctx.fillStyle="#d9effa";
        this.ctx.font = "4em Franklin Gothic";

    }
    clearCanvas(){
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
    drawBorder(width, height, cornerSize, borderSize){
        let path = new Path2D();
        path.moveTo(0, 0);
        path.lineTo(width - cornerSize, 0);
        path.lineTo(width, cornerSize);
        path.lineTo(width, height);
        path.lineTo(0, height);
        path.closePath();
        this.ctx.fillStyle = "rgba(0,0,0,0.8)"
        this.ctx.fill(path);
        this.ctx.strokeStyle = "#85a9c9";
        this.ctx.lineWidth  = borderSize;
        this.ctx.stroke(path);
        path = new Path2D();
        path.moveTo(200, cornerSize);
        path.lineTo(200, height - cornerSize)
        this.ctx.lineWidth = 1;
        this.ctx.stroke(path);
        if (this.showDismissButton){
            this.drawButton("It shall be done", {x: 200 + this.getTextSectionMaxWidth()/2, y: 250}, 10);
        }
    }
    drawButton(text, position, margin){
        let width = this.ctx.measureText(text).width;
        this.ctx.strokeStyle="#85a9c9";
        this.ctx.font = "1em Franklin Gothic";
        this.ctx.fillStyle = this.hoverAcceptButton ? "#203049" : "rgba(0,0,0,0)";
        let buttonGeometry = {x: position.x - (width/2) - margin, y : position.y, width: width + margin * 2, height: this.descriptionTextLineHeight + margin * 2};
        this.acceptButtonGeometry = buttonGeometry;
        this.ctx.fillRect(buttonGeometry.x, buttonGeometry.y, buttonGeometry.width, buttonGeometry.height);
        this.ctx.strokeRect(buttonGeometry.x, buttonGeometry.y, buttonGeometry.width, buttonGeometry.height);
        this.ctx.fillStyle="#85a9c9";
        this.ctx.fillText(text, position.x - width/2, position.y + 14 + margin);
    }
    onHover(event){
        const coords = CanvasUtils.getRelativeCoords(event, this.canvas);
        if (CanvasUtils.isInside(coords, this.acceptButtonGeometry)){
            this.hoverAcceptButton = true;
        }
        else{
            this.hoverAcceptButton = false;
        }
    }
    onClick(event) {
        const coords = CanvasUtils.getRelativeCoords(event, this.canvas);
        if (CanvasUtils.isInside(coords, this.acceptButtonGeometry)) {
            this.props.dismiss();
        }
    }


    render(){
        return(
            <div>
                <canvas
                    style={{zIndex: 3, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                    ref={this.canvasRef}
                    width={this.state.width} height={308}
                    onMouseMove={(event) => this.onHover(event)}
                    onMouseUp={(event) => this.onClick(event)}
                />
            </div>
        )
    }
}