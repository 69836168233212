import {animated, useSpring} from "react-spring";
import {useEffect, useState} from "react";

export interface AlertAnimationProps
{
    children: any,
    imageDuration: number,
    videoElementRef?: any,
    onComplete: () => void,
    isVideo: boolean,
    playing: boolean,
}

enum AnimationStep{
    FadeIn, Stay,FadeOut, Ended
}

const fadeDuration: number = 300;
export default function AlertAnimation (props: AlertAnimationProps){
    let [animationStep, setAnimationStep] = useState<AnimationStep>(AnimationStep.FadeIn);
    let [animationStarted, setAnimationStarted] = useState<boolean>(false);
    useEffect(() => {
        if (!props.playing){
            // reset the animation without triggering it
            setAnimationStep(AnimationStep.FadeIn);
            setAnimationStarted(false);
        }

    }, [props.playing])

    useEffect(() => {
        if (props.playing && !animationStarted){
            if (animationStep == AnimationStep.FadeIn) {
                setTimeout(() => setAnimationStep(AnimationStep.Stay), fadeDuration);
                if (props.isVideo){
                    let video : HTMLVideoElement = props.videoElementRef.current;
                    video.onended = () => {
                        setAnimationStep(AnimationStep.FadeOut);
                        setTimeout(() => onFadeEnd(), fadeDuration);
                    };
                }
                else{
                    setTimeout(() => setAnimationStep(AnimationStep.FadeOut), Math.max(0, props.imageDuration - fadeDuration));
                    setTimeout(() => onFadeEnd(), props.imageDuration)
                }
            }
        }

    }, [animationStep, props.imageDuration, props.videoElementRef, props.playing])

    function onFadeEnd(){
        setAnimationStarted(false);
        props.onComplete();
    }

    let animation = useSpring(
        {
            opacity: (animationStep != AnimationStep.FadeIn && animationStep != AnimationStep.FadeOut) ? 1 : 0,
        })

    return (<div>
        <animated.div style={{...animation}}>
            {props.children}
        </animated.div>
    </div>)
}