import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
    general:{
        textAlign: "center",
    },
    title: {
        color: "#3950f6",
        fontSize: "4em"
    },
    question: {
        fontWeight: "bold",
        color: "#fff",
        maxWidth: "680px",
        margin: "2em auto 1em auto",
        // textAlign: "justify",
    },
    paragraph: {
        color: "#dedede",
        maxWidth: "680px",
        margin: "1em auto 1em auto",
        textAlign: "justify",
    },
    img: {
        maxWidth: "680px",
        margin: "1em auto 1em auto",
    }
}));
export default function ErrorHelpView()
{
    const classes = useStyles();
    return (
        <div className={classes.general}>
            <Typography className={classes.title} component={"h3"}> When everything goes wrong (aka FAQ) </Typography>
            <Typography className={classes.question} > Q: I cannot activate a butcher but I have tokens! </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                A: First make sure you have the right token. BRÜTAL butchers require BRÜTAL tokens.

            </Typography>
            <Typography className={classes.question} component={"h3"}> Q: I get an error when I try to activate a butcher! Something about an error with the login.</Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                A: That means the extension is not able to recognize you. Here's what you can try to fix it.
            </Typography>
                <Typography variant={"body1"} className={classes.paragraph}>
                First try clicking on the refresh button for the extension. That works sometimes.
                <img alt="Refresh button excample" className={classes.img} src={"/img/help/errorRefresh.png"}/>
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                If you are using the Brave browser, try disabling shields for twitch, or at least make sure it is not set to "All cookies blocked". That prevents the login.
                <img alt="Error in Brave browser" className={classes.img} src={"/img/help/errorBrave.png"}/>

            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                If that isn't it, try going to your twitch settings.
                On desktop, click your avatar, select Settings, then Connections, and there search for The Vocal Butcher's Brawler Gallery and click Disconnect.
                <img alt="Extension location in settings" className={classes.img} src={"/img/help/errorSettings.png"}/>
                This options is not available in the mobile Twitch app.
                After doing that, refresh the stream (if you're on mobile, you'll need to eithe close the app or swap for a moment to a different stream).
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                If that doesn't work either, then the extension is FUBAR for you, but you can still use chat commands:
                <ul>
                    <li>"!activate" or "!vba" to activate a butcher. For instance, "!vba plankton".</li>
                    <li>"!tokens" to view your token balance.</li>
                    <li>"!vbhelp" to view all possible commands.</li>
                </ul>

            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                Regardless of if you were able to fix the issue or not, we'd greatly appreciate if you could go to the "butcher-extension-discussion" channel on TheVocalButcher's discord and describe the problem, if possible with screenshots and info about what you are using (browser and version, if you are using desktop, iphone or android, etc). Making a custom extension work for all the different variations of browsers, devices and shenanigans is hard, and that helps a lot with finding issues.
            </Typography>

        </div>
    )

}