import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app

const websiteThemeOptions = {
    palette: {
        secondary: {
            main: "#303030",
            light: "#595959",
            dark: "#070707",
            contrastText: "#ffffff"
        },
        primary: {
            main: "#1c5296",
            light: "#577ec7",
            dark: "#002a67",
            contrastText: "#ffffff"
        },
        background: {
            default: "#010101",
            paper: "#171717"
        },
        text: {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.7)",
            hint:"rgba(255, 255, 255, 0.5)",
            icon: "rgba(255, 255, 255, 0.5)",
            divider: "rgba(255, 255, 255, 0.12)"
        }
    },
};

let extensionThemeOptions = {...websiteThemeOptions};
extensionThemeOptions.palette = {...websiteThemeOptions.palette};
extensionThemeOptions.palette.background = {...websiteThemeOptions.palette.background, default: "rgba(0,0,0,0)"};
export const websiteTheme = createMuiTheme(websiteThemeOptions);
export const extensionTheme = createMuiTheme(extensionThemeOptions);
