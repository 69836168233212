import React from "react"
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TokenRegular from "../../../images/SilverTokenSmall.png"
import TokenBrutal from "../../../images/BrutalTokenSmall.png"
import BitCheerIcon from "../../../images/bitcheer.png"
import StreamLabsIcon from "../../../images/streamLabsLogoIcon.png"

const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: "center",
    },
    mainDiv: {
        width: "30vw",
        margin: "0 auto",
        [theme.breakpoints.down("lg")]:{
            width: "60vw"
        },
        [theme.breakpoints.down("md")]:{
            width: "80vw"
        },
        [theme.breakpoints.down("sm")]:{
            width: "80vw"
        },
    },
    link: {
      color: theme.palette.primary.light
    },
    highlight:{
        color: theme.palette.primary.light
    },
    icon: {
        width: "32px",
        margin: "0 4px",
    },
    optionsDiv: {
        margin: "0 10% auto",
    }
}))

export interface ButcherTokenInstructionsProps {
    isBrutal: boolean,
}

export default function ButcherTokenInstructions(props: ButcherTokenInstructionsProps)
{
    const butcherAdjective = props.isBrutal ? "BRÜTAL " : "";
    let cheerAmount = props.isBrutal ? 1500 : 1000;
    let tipAmount = props.isBrutal ? 15 : 10;
    let tokenIcon = props.isBrutal ? TokenBrutal : TokenRegular;


    const classes = useStyles();
    return(
        <div className={classes.mainDiv}>
            <div>
                <Typography className={classes.text} component={"h6"}>You need a
                    <div className={classes.highlight}> {butcherAdjective}brawler token <img alt="" className={classes.icon} src={tokenIcon}/> </div>
                    to activate a {props.isBrutal && "BRÜTAL "}brawler.</Typography>
            </div>
            <Typography className={classes.text} component={"h6"}>To get more you can:</Typography>
            <div className={classes.optionsDiv}>
                <Typography component={"h6"}><img alt="" className={classes.icon} src={BitCheerIcon}/><span className={classes.highlight}>Cheer</span> {cheerAmount} bits in chat</Typography>
                <Typography component={"h6"}><img alt="" className={classes.icon} src={StreamLabsIcon}/><span className={classes.highlight}>Tipping</span> {tipAmount} bucks.</Typography>
                {!props.isBrutal && <Typography component={"h6"}>Or try to snatch the freebie with <span className={classes.highlight}>channel points</span> (two free per stream).</Typography>}
            </div>
        </div>
    )
}