import React, {useRef, useState} from "react";
import {playSound} from "../../CommonUtils/audio";
import SmashAnimation from "./SmashAnimation";
import {ButcherAlertMessage, ButcherAlertType, useButcherAlertWebsockets} from "../Admin/AdminPanel/AlertsLogic";
import AudioSources from "../../audio/AudioSources";

export interface ObsButcherAlertBoxProps {
}

export default function ButcherAlertBox(props: ObsButcherAlertBoxProps){
    let [isDisconnected, setIsDisconnected] = useState(false);
    let [currentMessage, setCurrentMessage] = useState<ButcherAlertMessage | null>(null);
    const soundsVolume = 0.8;
    let activationAudioRef = useRef();
    let proposalAudioRef = useRef();
    let firstActivationAudioRef = useRef();
    let butchersEndAudioRef = useRef();
    let pauseAudioRef = useRef();
    let freebieInboundRef = useRef();
    const startActivationAnimation = (message: ButcherAlertMessage) => {
        setCurrentMessage(message);
        setAnimating(true);
    }

    const playSoundAndCallAttention = (soundSource: any, alsoCallAttention: boolean) => {
        playSound(soundSource, soundsVolume);
    }

    function onAlert(message: ButcherAlertMessage) {
        console.log("Received butcherAlertMessage", message);
        switch (message.type){
            case ButcherAlertType.EndOfBreakWithEnqueuedButchers:
            case ButcherAlertType.EnqueuedFirstButcher:
                playSoundAndCallAttention(firstActivationAudioRef.current, true);
                startActivationAnimation(message);
                break;
            case ButcherAlertType.ActivationWhileSessionIsActive:
            case ButcherAlertType.EnqueuedButcherWhileAlreadyReadyToStart:
            case ButcherAlertType.EnqueuedButcherWhileOnBreak:
                playSoundAndCallAttention(activationAudioRef.current, true);
                startActivationAnimation(message);
                break;
            case ButcherAlertType.NewProposal:
                console.log("Detected proposal");
                playSoundAndCallAttention(proposalAudioRef.current, true);
                break;
            case ButcherAlertType.ButcherSessionEnded:
                playSoundAndCallAttention(butchersEndAudioRef.current, true);
                break;
            case ButcherAlertType.ButcherSessionPaused:
                playSoundAndCallAttention(pauseAudioRef.current, false);
                break;
            case ButcherAlertType.FreebieInbound:
                playSoundAndCallAttention(freebieInboundRef.current, false);
                break;
        }
    }

    useButcherAlertWebsockets(onAlert,
        () => setIsDisconnected(true));
    let [animating, setAnimating] = useState(false);
    return (<div>

        <SmashAnimation
            title={currentMessage?.data.butcherDisplayName ?? ""}
            tagline={currentMessage?.data.butcherTagLine ?? ""}
            imageUrl={currentMessage?.data.butcherPictureURL ?? ""}
            animating={animating}
            onAnimationEnd={() => setAnimating(false)}
        />

        {isDisconnected ? "ALERT BOX IS DISCONNECTED, REFRESH" : ""}
        {/*@ts-ignore ref, also i'm adding ../ so it does not append "admin/" to the route of the audio */}
        <audio ref={activationAudioRef} src={"../" + AudioSources.NewActivation}></audio>
        {/*@ts-ignore ref*/}
        <audio ref={proposalAudioRef} src={"../" + AudioSources.NewProposal}></audio>
        {/*@ts-ignore ref*/}
        <audio ref={firstActivationAudioRef} src={"../" + AudioSources.ButchersReady}></audio>
        {/*@ts-ignore ref*/}
        <audio ref={butchersEndAudioRef} src={"../" + AudioSources.BoxingBell}></audio>
        {/*@ts-ignore ref*/}
        <audio ref={pauseAudioRef} src={"../" + AudioSources.RecordScratch}></audio>
        {/*@ts-ignore ref*/}
        <audio ref={freebieInboundRef} src={"../" + AudioSources.PowerWeaponsInbound}></audio>
    </div>)
}