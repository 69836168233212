import React from "react"
import "./wheelMainMenuStyle.css"
import {
    Link,
} from "react-router-dom";
import NamePopUp from "./NamePopUp";
import WheelApis from "../../BackendLayer/WheelAPI";
import WheelchanInstructions from "./WheelchanInstructions";

export default class WheelchanAdmin extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            wheels: [],
            templates: [],
            loading: true,
            entityBeingRenamed: null,
            entityBeingRenamedName: null,
        };
    }

    componentDidMount(){
        this.reload();
    }

    reload(){
        WheelApis.getAllWheels().then((res) => this.setupState(res.data))
    }
    setupState(wheelsData){
        const wheels = [];
        const templates = [];
        for (const wheel of wheelsData){
            if (wheel.isTemplate){
                templates.push(wheel);
            }
            else{
                wheels.push(wheel);
            }
        }

        this.setState({wheels, templates, loading: false})
    }

    startRenameWheel(id, name){
        this.setState({entityBeingRenamed: id, entityBeingRenamedName: name});
    }
    cancelRename(){
        this.setState({entityBeingRenamed: null})
    }
    confirmRename(id, name){
        WheelApis.getWheel(id)
            .then((res) => {
                let wheel=res.data;
                wheel.name = name;
                WheelApis.saveWheel(wheel)
                    .then(() => {
                        this.setState({entityBeingRenamed: null});
                        this.reload();
                    })
            })
    }
    async createWheelFromTemplate(templateId){
        let wheel = (await WheelApis.getWheel(templateId)).data;
        wheel.name = wheel.name + " copy";
        wheel.isTemplate = false;
        await WheelApis.createWheel(wheel);
        this.reload();
    }

    async delete(id){
        await WheelApis.deleteWheel(id);
        this.reload();
    }

    render(){
        let menu = (
            <div>
                <div className="sectionHeader">Active wheels:</div>
                <table>
                {this.state.wheels.map(wheel =>
                    <tr>
                    <div className={"menuSection"}>
                        <th style={{width: "20vw", textAlign: "left"}}><span className="wheelName">{wheel.name || wheel.id}</span></th>
                        <th><span className="wheelButtons menuSection">
                            <Link to={"/wheelchan-instance/" + wheel.id}><span className = "button">Launch</span></Link>
                            <Link to={"/wheelchan-instance/" + wheel.id + "/skulls"}><span className = "button">Active skulls view</span></Link>
                            <span className="button" onClick={() => this.startRenameWheel(wheel.id, wheel.name)}>Rename</span>
                            <Link to={"/wheelchan-instance/" + wheel.id + "/edit"}><span className = "button">Edit</span></Link>
                            <span className = "button" onClick={() => this.delete(wheel.id)}>Delete</span>
                        </span>
                        </th>
                    </div>
                    </tr>
                    )
                }
                </table>
                <div className="sectionHeader">Templates: </div>
                <table>
                {this.state.templates.map(template =>
                    <tr >
                    <div className={"menuSection"}>
                        <th style={{width: "20vw", textAlign: "left"}}><span className="wheelName">{template.name || template.id}</span></th>
                        <th><span className="wheelButtons menuSection">
                            <span className = "button" onClick={() => this.createWheelFromTemplate(template.id)}>Create wheel from template</span>
                            <span className="button" onClick={() => this.startRenameWheel(template.id, template.name)}>Rename</span>
                            <Link to={"/wheelchan-instance/" + template.id + "/edit"}><span className = "button">Edit</span></Link>
                            <span className = "button" onClick={() => this.delete(template.id)}>Delete</span>
                    </span></th>
                    </div>
                    </tr>
                )
                }
                </table>
                <div className="sectionHeader">Wheel controls: </div>
                <WheelchanInstructions/>
            </div>
        );
        console.log("Menu!");
        console.log(menu);
        return(
            <div>
                {this.state.entityBeingRenamed &&
                    <NamePopUp
                        name={this.state.entityBeingRenamedName}
                        onConfirm={(name) => this.confirmRename(this.state.entityBeingRenamed, name)}
                        onCancel={() => this.cancelRename()}/>
                }
                {this.state.loading
                    ? "Loading"
                    : menu}
            </div>
        )
    }
}