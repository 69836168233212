import axios from 'axios'
import {backendConfig} from "./Config";

const baseURL = backendConfig.getBaseRoute();
const wheelURL = baseURL + "Wheel/";
const instance = axios.create(({
    withCredentials: true
}))

function getWheel(id){
    return instance.get(wheelURL + id).catch(error => console.log(error));
}

function getAllWheels(){
    return instance.get(wheelURL).catch(error => console.log(error));
}

function createWheel(wheel){
    return instance.post(wheelURL, wheel).catch(error => {console.log("Create wheel failed"); console.log(error)});
}
function saveWheel(wheel){
    return instance.put(wheelURL, wheel).catch(error => {console.log("Save wheel failed"); console.log(error)});
}
function deleteWheel(wheelId){
    return instance.delete(wheelURL + wheelId).catch(error => {console.log("Delete wheel failed"); console.log(error)});
}

function seedHaloWheels(){
    return instance.post(wheelURL + "seedHalo");
}

const WheelApis = {
    getWheel,
    getAllWheels,
    createWheel,
    saveWheel,
    deleteWheel,
    seedHaloWheels
}

export default WheelApis;