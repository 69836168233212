enum DirectorState {
    Ready,
    Active,
    Paused,
    OnABreak,
    AwaitingStartConfirmation,
    Offline
};

export default DirectorState;
