import React from "react"

export class WheelComponent extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.start = undefined;
        this.wheelAngle = 0;
        this.holding = false;
        this.clickAngle = null;
        this.angleRecord = null; //{angle: null, timestamp: null}
        this.speed = 0; // rps
        this.drawBorders = true;
        this.stopperDims = {x: 40, y: 20}
        this.stopperOverlap = 0.5;
        this.wheelAngleRangeForStopper = this.calculateAngleRangeForStopper();
        this.stopperAngle = this.wheelAngleRangeForStopper.min;
        this.stopperSnapbackSpeed = 0.8; //rps
        this.currentlyPointedOptionId = null;
        this.active = false;
        this.stopperIsOnContact = false;
        this.showText = this.props.showText === undefined ? true : this.props.showText;
        this.timeSinceLastTickSound = 0;
        this.minTimeBetweenTickSounds = 50;
        this.intro = {
            active: true,
            reversed: false,
            animating: false,
            startTime: null,
            duration: 10000,
            progress1: 0,
            progress2: 0
        }
    }

    componentDidMount() {
        this.canvas = this.canvasRef.current;
        this.ctx = this.canvas.getContext("2d");
        this.canvas.addEventListener("mousedown", (event) => this.mouseDown(event), false);
        this.canvas.addEventListener("mouseup", (event) => this.mouseUp(event), false);
        this.canvas.addEventListener("mousemove", (event) => this.mouseMove(event), false);
        document.addEventListener("keydown", (event) => this.handleKeyPress(event));
        window.requestAnimationFrame((timestamp) => this.draw(this.canvas, timestamp));
    }

    render() {
        return (
            <div>
                <canvas ref={this.canvasRef} width={this.props.canvasWidth} height={this.props.canvasHeight} id="canvas"></canvas>
            </div>
        )
    }

    handleKeyPress(event){
        if (event.code === "KeyP"){
            this.props.playStopMusic();
        }
        else if (event.code === "KeyR"){
            this.props.rewindMusic();
        }
        else if (event.code === "KeyI"){
            this.startIntro();
        }
        else if (event.code === "KeyK"){
            this.skipIntro();
        }
        else if (event.code === "KeyS"){
            this.launch(this.props.launchSpeed);
        }
        else if (event.code === "KeyV"){
            this.props.changeVolume();
        }
        else if (event.code === "KeyB"){
            this.startReverseIntro();
        }
    }

    //region Actions
    launch(speed){
        this.active = true;
        this.speed = speed * (Math.random() /2 +0.5);
    }
    recordAngle(angle) {
        const timestamp = performance.now();
        if (!this.angleRecord || (timestamp - this.angleRecord.timestamp) > 150) {
            this.angleRecord = {angle: angle, timestamp: timestamp}
        }
    }
    notifyWinner(){
        if (this.props.options.length <= 1){
            return; // no winner with one contestant or less!
        }
        const winner = this.props.options.find(opt => opt.optionId === this.currentlyPointedOptionId);
        this.props.onWinnerSelect({id: winner.optionId, text: winner.text, description: winner.description, backgroundImg: winner.background.img});
        this.active = false;
    }

    // event handling
    getPolarCoordsFromMouseEvent(event){
        const actualCoords = this.getRelativeCoords(event);
        return this.getPolarCoords(actualCoords, this.getWheelCenter());
    }
    mouseDown(event) {
        if (!this.props.allowManipulation || this.intro.active){
            return;
        }
        const polar = this.getPolarCoordsFromMouseEvent(event);

        if (polar.radius > this.props.wheelRadius) {
            return;
        }
        this.holding = true;
        this.speed = 0;
        this.clickAngle = polar.angle;
        this.recordAngle(polar.angle);
    }
    mouseMove(event) {
        if (!this.props.allowManipulation || this.intro.active){
            return;
        }
        if (this.holding) {
            const polar = this.getPolarCoordsFromMouseEvent(event);
            this.wheelAngle = this.enforcePositiveAngle(this.wheelAngle - (this.clickAngle - polar.angle));
            this.clickAngle = polar.angle;
            this.recordAngle(polar.angle);
        }
    }
    mouseUp(event) {
        if (!this.props.allowManipulation || this.intro.active){
            return;
        }
        //this.dumpAngles(this.wheelAngle);

        if (!this.holding) {
            return;
        }
        this.holding = false;
        if (this.props.spinStartMode === "click"){
            this.launch(this.props.launchSpeed);
        }
        else if (this.props.spinStartMode === "dragAndRelease"){
            if (this.recordAngle) {
                const polar = this.getPolarCoordsFromMouseEvent(event);
                const angleDelta = this.angleRecord.angle - polar.angle;
                const timeDelta = performance.now() - this.angleRecord.timestamp;
                this.angleRecord = null;
                if (Math.abs(angleDelta) > this.props.minAngleDeltaForSpinning) {
                    const angleChangePerSecond = angleDelta / (timeDelta / 1000);
                    const speedInRPS = angleChangePerSecond / (Math.PI * 2);
                    if (speedInRPS > 0) { //clockwise speeds are negative, and we only want those
                        return;
                    }
                    this.launch(-1 * speedInRPS);
                }
            }
        }
    }
//endregion
    //region Drawing functions
    tickIntro(canvas, timestamp){
        if (!this.intro.startTime){
            this.intro.startTime = timestamp;
        }
        const timeDelta = timestamp - this.intro.startTime;
        if (this.intro.step === 1) {
            let progress = Math.min(1, Math.pow(timeDelta / (this.intro.duration / (this.intro.reversed ? 4 : 1)),4));

            this.intro.progress1 = this.intro.reversed ? 1 - progress : progress;

            if (this.intro.progress1 === (this.intro.reversed ? 0 : 1)) {
                if (this.intro.reversed){
                    this.intro.animating = false;
                    this.intro.active = true;
                }
                else{
                    this.intro.startTime = timestamp;
                    this.intro.step = 2;
                }

            }
        }
        else if (this.intro.step === 2){
            let progress = Math.min(1, Math.pow(timeDelta / (this.intro.duration/5),4));
            this.intro.progress2 = this.intro.reversed ? 1 - progress : progress;
            if (this.intro.progress2 === (this.intro.reversed ? 0 : 1)){
                if (this.intro.reversed){
                    this.intro.startTime = timestamp;
                    this.intro.step = 1;
                }
                else{
                    this.intro.active = false;
                }
            }
        }
    }

    startIntro(){
        this.intro.active = true;
        this.intro.reversed = false;
        this.intro.animating = true;
        this.intro.step = 1;
        this.intro.progress1 = 0;
        this.intro.progress2 = 0;
        this.intro.startTime = null;
        console.log("Started intro");
    };

    skipIntro(){
        this.intro.active = false;
        this.intro.animating = false;
        this.intro.progress1 = 1;
        this.intro.progress2 = 1;
    }
    startReverseIntro(){
        this.startIntro();
        this.intro.reversed = true;
        this.intro.active = true;
        this.intro.animating = true;
        this.intro.step = 2;
        this.intro.progress1 = 1;
        this.intro.progress2 = 1;
        this.intro.startTime = null;
    }

    draw(canvas, timestamp) {
        if (this.start === undefined) {
            this.start = timestamp;
        }
        const timeDelta = timestamp - this.start;
        this.start = timestamp;
        this.clearCanvas(canvas);
        this.ctx.globalAlpha = this.intro.progress2;
        this.ctx.drawImage(this.props.background.img, 0, 0,canvas.width, canvas.height);
        this.ctx.fillStyle="rgba(0,0,0,0.8)";

        this.ctx.globalAlpha = 1;
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);
        this.ctx.globalAlpha = this.intro.progress2;

        if (!this.holding) {
            const angleChange = this.speed * (timeDelta / 1000) * Math.PI * 2
            this.wheelAngle = (this.wheelAngle + angleChange) % (Math.PI * 2);
            this.decaySpeed(timeDelta);
            if (this.active && this.speed === 0) {
                this.notifyWinner();
            }
        }

        this.drawWheel(timeDelta, this.props.options.filter(opt => !!!opt.deleted), this.getWheelCenter(), this.props.wheelRadius, this.wheelAngle);
        this.drawDebugText();
        if (this.intro.animating){
            this.tickIntro(canvas, timestamp);
        }
        this.ctx.globalAlpha = 1;
        window.requestAnimationFrame((timestamp) => this.draw(this.canvas, timestamp));
    }
    clearCanvas(canvas) {
        this.ctx.fillStyle = this.props.backgroundColor;
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    drawWheel(timeDelta, options, center, radius, spinAngle) {
        if (options.length <= 1){
            this.allowSpin = false;
        }
        if(options.length === 1){
            this.currentlyPointedOptionId = options[0].optionId;
        }
        const angles = this.calculateAngles(options, spinAngle);
        this.wheelAngleRangeForStopper = this.calculateAngleRangeForStopper();
        const wheelAngleInContactWithStopper = this.drawWheelWedges(options, center, radius, angles);
        this.drawWheelText(options, center, radius, angles);
        this.drawWheelStopper(wheelAngleInContactWithStopper, timeDelta);
    }
    drawWheelWedges(options, center, radius, angles){
        let wheelAngleOnContactWithStopper = this.setCurrentlySelectedWedge(options, angles);
        if (options.length === 1){
            this.drawWedge(center, radius, angles[0].s, angles[0].e, options[0].color, options[0].background, true)
        }
        else{
            for (let i = 0; i < options.length; i++) {
                const highlightWedge = this.currentlyPointedOptionId === options[i].optionId;
                this.drawWedge(center, radius, angles[i].s, angles[i].e, options[i].color, options[i].background, highlightWedge);
            }
        }

        return wheelAngleOnContactWithStopper;
    }

    setCurrentlySelectedWedge(options, angles){
        this.currentlyPointedOptionId = null;
        let wheelAngleOnContactWithStopper = this.wheelAngleRangeForStopper.min;
        if (options.length === 1){
            this.currentlyPointedOptionId = options[0].optionId;
        }
        else{
            for (let i = 0; i < options.length; i++) {
                const angleS = angles[i].s;
                const angleE = angles[i].e;
                if (angleS > wheelAngleOnContactWithStopper && angleS < this.wheelAngleRangeForStopper.max){
                    wheelAngleOnContactWithStopper = angleS;
                    this.currentlyPointedOptionId = options[i].optionId;
                }
                else{
                    if (wheelAngleOnContactWithStopper === this.wheelAngleRangeForStopper.min
                        && angleS < this.wheelAngleRangeForStopper.min
                        && angleE > this.wheelAngleRangeForStopper.min){ //stopper is in a neutral position
                        this.currentlyPointedOptionId = options[i].optionId;
                    }
                }
            }
        }
        return wheelAngleOnContactWithStopper;
    }
    drawWheelText(options, center, radius, angles){
        if (this.showText){
            for (let i = 0; i < options.length; i++) {
                // this.drawAlignmentLine(center, radius, angles[i].m);
                const reducedText = this.getBiggestFittingText(options[i].text, radius * 0.8, this.props.defaultTextSize);
                this.drawText(reducedText.text, center, angles[i].m, -radius * 0.9, reducedText.fontSize);
            }
        }
    }
    drawWheelStopper(wheelAngleOnContactWithStopper, timeDelta){
        const stopperAngle = this.getStopperAngle(wheelAngleOnContactWithStopper);
        const averageTimePerWedge = (1000)/(this.speed * this.props.options.length);
        const goingTooFast = Math.max(timeDelta, this.minTimeBetweenTickSounds) > averageTimePerWedge
        if (goingTooFast){
            this.timeSinceLastTickSound += timeDelta;
            if (this.timeSinceLastTickSound > this.minTimeBetweenTickSounds){
                this.props.playSound();
                this.timeSinceLastTickSound = 0;
            }
        }
        if (wheelAngleOnContactWithStopper !== this.wheelAngleRangeForStopper.min ){
            this.stopperAngle = stopperAngle; // update the stopper angle if there's a new one, otherwise just do snapback
            this.stopperIsOnContact = true;
        }
        else{
            if (this.stopperIsOnContact){
                this.stopperIsOnContact = false;
                if(!goingTooFast){
                    this.props.playSound();
                }
            }
        }
        this.drawStopper(timeDelta, stopperAngle);
    }
    drawWedge(center, radius, startingAngle, endingAngle, color, background = null, highlight = false){ // todo: add colors
        let path= new Path2D();

        path.moveTo(center.x, center.y);
        path.arc(center.x, center.y, radius, startingAngle, endingAngle);
        path.closePath();

        this.ctx.fillStyle = color;
        this.ctx.fill(path);
        // if (highlight){
        //     this.ctx.fillStyle="rgba(255,255,255,0.3";
        //     this.ctx.fill(path);
        // }
        if (background.img && background.img.complete) {
            this.drawImageBackground(path, center, this.calculateMidAngle(startingAngle, endingAngle), background);
        }
        if (this.drawBorders){
            this.ctx.strokeStyle = highlight ? "#d9effa": "#85a9c9";
            this.ctx.lineWidth = highlight ? 5 : 1;
            this.ctx.stroke(path);
        }
    }
    drawImageBackground(wedgePath, coords, angleInRadians, backgroundData){
        const img = backgroundData.img;
        this.ctx.save();
        this.ctx.clip(wedgePath);
        this.ctx.translate(coords.x, coords.y)
        this.ctx.rotate(angleInRadians + Math.PI/2 + backgroundData.angle);//this.enforcePositiveAngle(angleInRadians % Math.PI*2));
        const scaledSize = {width: img.width * backgroundData.scale, height: img.height * backgroundData.scale};
        this.ctx.globalAlpha = this.intro.progress1;
        this.ctx.drawImage(img, backgroundData.offsetX  -scaledSize.width /2 , backgroundData.offsetY - scaledSize.height /2, scaledSize.width, scaledSize.height);
        this.ctx.globalAlpha = this.intro.progress2;
        this.ctx.restore();
        this.setFontSize(this.props.defaultTextSize);
    }
    drawStopper(timeDelta, angle ){
        angle = angle % Math.PI;
        if (Math.abs(angle) < Math.abs(this.stopperAngle)){
            // animate snapback
            const angleReduction = this.stopperSnapbackSpeed * (timeDelta / 1000) * Math.PI * 2;
            angle = Math.min(this.stopperAngle + angleReduction, angle);
            this.stopperAngle = angle;
        }
        const leftSide = {x: this.props.wheelCenterX - this.props.wheelRadius, y: this.props.wheelCenterY};
        const stopperDims = this.stopperDims;
        const overlap = this.stopperOverlap;
        this.ctx.save();
        this.ctx.textBaseline = 'middle';
        this.ctx.translate(leftSide.x - stopperDims.x * (1-overlap), leftSide.y)
        this.ctx.rotate(angle);


        if (this.props.stopperMode === "POD"){
            const POD = this.props.assets.pod;
            if (POD && POD.complete){
                this.ctx.rotate(-Math.PI/2);
                const scale = stopperDims.x / POD.width;
                this.ctx.translate(-POD.height * scale / 2, 0)
                this.ctx.drawImage(POD, 0, 0, POD.width * scale, POD.height * scale);

            }
        }
        else{
            this.ctx.beginPath();
            this.ctx.moveTo(0, -stopperDims.y/2);
            this.ctx.lineTo(0, +stopperDims.y/2);
            this.ctx.lineTo(stopperDims.x, 0);
            this.ctx.closePath();
            this.ctx.fillStyle=this.props.stopperColor;
            this.ctx.fill();

            this.ctx.strokeStyle=this.props.stopperOutlineColor;
            this.ctx.stroke();
        }

        this.ctx.restore();
    }
    drawText(text, coords, angleInRadians, offsetX = 0, fontSize = this.props.defaultTextSize){
        this.ctx.save();
        this.ctx.textBaseline = 'middle';
        this.ctx.translate(coords.x, coords.y)
        this.ctx.rotate(angleInRadians + Math.PI);
        this.setFontSize(fontSize);
        this.ctx.fillStyle = this.props.textColor;
        this.ctx.fillText(text, offsetX, 0);
        this.ctx.strokeStyle = this.props.textOutlineColor;
        this.ctx.strokeText(text, offsetX, 0);
        this.ctx.restore();
        this.setFontSize(this.props.defaultTextSize);
    }

    // font size functions
    setFontSize(size){
        this.ctx.font = size + "px arial";
    }
    getBiggestFittingText(text, maxLength, topFontSize, minFontSize = this.props.minTextSize){
        this.setFontSize(topFontSize);
        let length = this.ctx.measureText(text).width;
        let fontSize = topFontSize;
        while (length > maxLength && fontSize >= minFontSize){
            fontSize = fontSize - 1;
            this.setFontSize(fontSize)
            length = this.ctx.measureText(text).width;
        }
        if (fontSize < minFontSize){
            text = text.substring(0, this.props.maxCharsOnTextCut) + "..."; // todo: change this to use actual width measurements
        }
        return {text: text, fontSize: fontSize}
    }
//endregion
    //region Calculations
    getPolarCoords(point, origin) {
        const cartX = point.x - origin.x;
        const cartY = point.y - origin.y;
        const radius = Math.sqrt(Math.pow(cartX, 2) + Math.pow(cartY, 2));
        let angle = Math.atan2(cartY, cartX);

        return {
            radius: radius,
            angle: this.enforcePositiveAngle(angle)
        }
    }
    calculateMidAngle(startingAngle, endingAngle){
        if (endingAngle < startingAngle){ // the wedge goes over the starting point
            return (startingAngle + (Math.PI * 2 + endingAngle))/2 - Math.PI * 2;
        }
        return (startingAngle + endingAngle) / 2;
    }
    calculateAngleRangeForStopper(){
        const leftSide = {x: this.props.wheelCenterX - this.props.wheelRadius, y: this.props.wheelCenterY};
        const stopperDims = this.stopperDims;
        const rotationPointX = leftSide.x - stopperDims.x * (1 - this.stopperOverlap);
        const distance = Math.sqrt(Math.pow(this.props.wheelCenterX - rotationPointX, 2) + Math.pow(0, 2));
        const xDistanceToWheelCenter = (Math.pow(this.props.wheelRadius,2) - Math.pow(stopperDims.x, 2) + Math.pow(distance, 2))/(2*distance);
        const maxAngle = Math.acos(xDistanceToWheelCenter / this.props.wheelRadius);
        //this.drawWedge(this.getWheelCenter(), this.props.wheelRadius, Math.PI, maxAngle + Math.PI, "rgba(200,200,200,0.5)");
        return {min: Math.PI, max: maxAngle + Math.PI}
    }
    enforcePositiveAngle(angle){
        if (angle < 0){
            angle = Math.PI * 2 + angle;
        }
        return angle ;
    }
    decaySpeed(timeDelta) {
        const decayPercentage = this.props.speedDecay * (timeDelta / 1000);
        this.speed = Math.max(this.speed - Math.max(this.props.minSpeedDecay, this.speed * (decayPercentage)), 0);
    }
    getRelativeCoords(event) {
        // this.canvas coords start in the upper left corner
        const rect = this.canvas.getBoundingClientRect();
        return {
            x: (event.clientX - rect.left),
            y: (event.clientY - rect.top)
        };
    }
    calculateAngles(options, spinAngle){
        let angles = [];

        const totalWeight = options.reduce((summed, current) => {return summed + current.weight || 0}, 0)
        let lastAngle = spinAngle % (Math.PI * 2);
        for (let i = 0; i < options.length; i++) {
            const angleS = lastAngle % (Math.PI * 2);
            const angleE = (lastAngle + (options[i].weight/totalWeight)*Math.PI*2) % (Math.PI * 2);
            const midAngle = this.calculateMidAngle(angleS, angleE);
            lastAngle = angleE;
            angles.push({s: angleS, m: midAngle, e: angleE});
        }
        if (options.length === 1){ //things get weird with just one slice
            angles[0].e = angles[0].e + Math.PI * 2 - 0.00001; //a full 360 angle would result on nothing drawn, hence we remove a imperceptible amount
            angles[0].m = (angles[0].s + Math.PI);// % Math.PI * 2;
        };
        return angles;
    }
    getStopperAngle(wheelAngle){
        const point = {
            x: this.props.wheelCenterX + Math.cos(wheelAngle) * this.props.wheelRadius,
            y: this.props.wheelCenterY + Math.sin(wheelAngle) * this.props.wheelRadius
        };

        const leftSide = {x: this.props.wheelCenterX - this.props.wheelRadius, y: this.props.wheelCenterY};
        const stopperDims = this.stopperDims;
        const rotationPoint = {x: leftSide.x - stopperDims.x * (1 - this.stopperOverlap), y: leftSide.y};
        const opposite = Math.sin(wheelAngle) * this.props.wheelRadius;
        const hypotenuse = Math.sqrt(Math.pow(point.x - rotationPoint.x, 2) + Math.pow(point.y - leftSide.y,2));
        const angle = Math.asin(opposite/hypotenuse);
        return angle;
    }
//endregion
    //region Model Helpers
    getWheelCenter() {
        return {x: this.props.wheelCenterX, y: this.props.wheelCenterY}
    }

    drawDebugText(){
        if (!this.debug){
            return;
        }
        this.drawText("Wheel angle: " + this.wheelAngle, {x: 10, y: 20}, Math.PI);
        let pointed = null;
        if (this.currentlyPointedOptionId !== null){
            pointed = this.props.options.find(opt => opt.optionId === this.currentlyPointedOptionId).text;
        }

        this.drawText("Pointed: " + pointed, {x: 10, y: 50}, Math.PI);
    }
    drawAlignmentLine(center, radius, angle){
        this.ctx.save();
        this.ctx.translate(center.x, center.y);
        this.ctx.rotate(angle);
        this.ctx.beginPath();
        this.ctx.moveTo(0,0);
        this.ctx.lineTo(radius, 0);
        this.ctx.closePath();
        this.ctx.strokeStyle = "#f00";
        this.ctx.stroke();
        this.ctx.restore();
    }
//endregion
}