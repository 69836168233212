import {useButcherDetails} from "./ButcherEditionViewState";
import WaitingContainer from "../../Wrappers/WaitingContainer";
import React from "react";
import ButcherEdition from "../../Components/ButcherEdition/ButcherEdition";
import ButcherAPI from "../../BackendLayer/ButcherAPI";
import useLongOp from "../../CommonUtils/LongOp";

export interface ButcherEditionViewProps {

}
export default function ButcherEditionView(props: any){
    let editionOp = useLongOp("Updating brawler");
    function saveChanges(butcherFormFields: any, newImageFiles: any){
        editionOp.runner(() => ButcherAPI.UpdateButcher({...butcherFormFields, ...newImageFiles}), "/");
    }

    let {loadingTracker, butcherDetails} = useButcherDetails(props.match.params.id);
    return(
        <WaitingContainer waitingTrackers={[loadingTracker, editionOp.waitingTracker]}>
            <ButcherEdition butcherDetails={butcherDetails} saveActionName={"Save"} onSave={saveChanges} isNewButcher={false}/>
        </WaitingContainer>
    )
}