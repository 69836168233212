import React from "react"
import WaitingContainer from "../../../Wrappers/WaitingContainer";
import DirectorStatusControls from "../../../Components/AdminPanel/DirectorStatusControls";
import ButcherAPI from "../../../BackendLayer/ButcherAPI";
import {useDirectorData} from "../../../CommonUtils/DataFetchers/DirectorData";
import ActiveButcherList from "../../../Components/AdminPanel/ActiveButcherList";
import {useButcherActivations} from "../../../CommonUtils/DataFetchers/ButcherActivations";
import {useActivationWebsockets} from "../../ExtensionView/ExtensionViewState";
import Timer from "../../ExtensionView/Timer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useLongOp from "../../../CommonUtils/LongOp";
import {useState} from "react";
import Button from "@material-ui/core/Button";
import NoSleep from "nosleep.js"
import DirectorState from "../../../Constants/DirectorState";
import ControlPanelAlertBox from "./ControlPanelAlertBox";

const useStyles = makeStyles((theme) => ({
    timerDiv: {
    },
    timer: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: "8px",
        },
        textAlign: "center",
        fontSize: "3em"
    },
    lockScreenSection: {
        width: "600px",
        margin: "auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    controlPanelTitle: {
        textAlign: "center",
        fontSize: "3em"
    },
    keepAwakeButton: {
        width: "100%",
        fontSize: "1.5em",
        height: "10em"
    },
    idleControlPanel: {
        textAlign: "center",
        backgroundColor: "black"
    },
    attentionCallingControlPanel: {
        textAlign: "center",
        backgroundColor: theme.palette.secondary.light
    }
}))

export default function AdminPanelView(){
    const classes = useStyles();
    const directorDataHandlers = useDirectorData();
    let { loadingTracker: stateLoadingTracker, directorState, fullDirectorData, setFullDirectorData} = directorDataHandlers;
    const butcherActivationsHandlers = useButcherActivations();
    let { activationData, loadingTracker: activationsLoadingTracker} = butcherActivationsHandlers;
    let [blinking, setBlinking] = useState(false);
    let [callingAttention, setCallingAttention] = useState(false);

    const callAttention = (interval: number, totalRepetitions: number, currentRepetitions: number = 0) => {
        if (currentRepetitions === 0){
            setBlinking(true);
        }
        if (currentRepetitions >= totalRepetitions){
            setBlinking(false);
            return;
        }

        currentRepetitions += 1;
        setCallingAttention(callingAttention => !callingAttention);
        setTimeout(() => callAttention(interval, totalRepetitions, currentRepetitions), interval);
    }



    useActivationWebsockets(
        butcherActivationsHandlers,
        directorDataHandlers,
        null);

    const changeStateOp = useLongOp(`Changing butchering state`);

    const changeState = (state: DirectorState) =>
        // @ts-ignore
        changeStateOp.runner(async () => {
            const newStateData = await ButcherAPI.SetDirectorState(state);
            setFullDirectorData(newStateData)
        });
    let [noSleep, setNoSleep] = useState(new NoSleep());
    let [sleepLock, setSleepLock] = useState<boolean | null>(null);
    const drinkCopiousAmountsOfCoffee = () => {
        if (!sleepLock){
            noSleep.enable();
            setSleepLock(true);
        }
    }
    return(
        <WaitingContainer waitingTrackers={[stateLoadingTracker, activationsLoadingTracker, changeStateOp.waitingTracker]}>
            {sleepLock === null
            ? <div className={classes.lockScreenSection}>
                    <div className={classes.controlPanelTitle}>Control panel</div>
                    <Button variant={"contained"} color={"primary"} onClick={drinkCopiousAmountsOfCoffee} className={classes.keepAwakeButton}>
                        Tap to keep screen awake
                    </Button>
                </div>
            : <div className={(blinking && callingAttention) ? classes.attentionCallingControlPanel : classes.idleControlPanel}>
                <ControlPanelAlertBox callAttention={callAttention}></ControlPanelAlertBox>
                <ActiveButcherList activationData={activationData} directorState={directorState}/>
                {(fullDirectorData?.isTimerActive ?? false) && <div className={classes.timerDiv}><Timer {...fullDirectorData} className={classes.timer}/></div>}
                <div>
                    <DirectorStatusControls directorState={directorState} changeState={changeState} abort={ButcherAPI.DeactivateAllAndResetState}/>
                </div>
            </div>}
        </WaitingContainer>
    )
}