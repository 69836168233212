import {useState} from "react";
import {FormattedError, GetErrorMessage} from "./Error";
import { useHistory } from "react-router-dom"
import {AxiosError, AxiosResponse} from "axios";

type OptionalCallback = null | (() => any);
type ParameterizedOptionalCallback<TParam> = (param: TParam) => any;

export default function useLongOp<TResult>(
        waitingMessage: string,
        succeedCallback: OptionalCallback = null,
        failCallback: OptionalCallback = null,
        startExecuting: boolean = false,
        showContentsWhileWaiting: boolean = false)
{
    let [executing, setExecuting] = useState<boolean>(startExecuting);
    let [error, setError] = useState<FormattedError | null>(null);
    const history = useHistory();
    const start = () => setExecuting(true);
    const fail = (error: AxiosError) => {if (failCallback) { failCallback()}; setError(GetErrorMessage(error, "Operation failed"))};
    const succeed = () => {if (succeedCallback) { succeedCallback()}; setExecuting(false)};
    const succeedAndRedirect = (route: string) => {setExecuting(false); history.push(route);};

    let runner = (task: () => Promise<AxiosResponse<TResult>>,
                  redirectRoute?: string | null,
                  onSuccess?: ParameterizedOptionalCallback<AxiosResponse<TResult>> | null,
                  onFail?: ParameterizedOptionalCallback<AxiosError> | null) => {
        start();
        const taskResult = task();
        if (taskResult) {
            taskResult
                .then((result) => {
                    onSuccess && onSuccess(result);
                    redirectRoute ? succeedAndRedirect(redirectRoute) : succeed()
                })
                .catch((error) => {
                    onFail && onFail(error);
                    fail(error)
                });
        }
        else{
            setExecuting(false);
        }
    }
    let waitingTracker = CreateWaitingTracker(executing, error, waitingMessage, showContentsWhileWaiting);
    return {waitingTracker, runner}
}

export interface WaitingTracker {
    executing: boolean,
    error: FormattedError | null,
    message: string,
    showContentWhileWaiting: boolean
}

export function CreateWaitingTracker(executingFlag: boolean, errorObject: FormattedError | null, executingMessage: string, showContentsWhileExecuting: boolean) : WaitingTracker
{
    return {
        executing: executingFlag,
        error: errorObject,
        message: executingMessage,
        showContentWhileWaiting: showContentsWhileExecuting
    }
}