import AlertAnimation from "./AlertAnimation";
import {Alert} from "./GeneralAlertBox";
import {useEffect, useRef, useState} from "react";

export interface MediaOrchestratorProps {
    currentAlert: Alert | null,
    imageUrl?: string,
    imageDuration?: number,
    audioUrl?: string,
    videoUrl?: string,
    onAlertEnd: () => void,
    messageComponent: any
}

const volume = 0.6;
export default function MediaOrchestrator(props: MediaOrchestratorProps){
    let [videoLoaded, setVideoLoaded] = useState<boolean>(false);
    let [imgLoaded, setImgLoaded] = useState<boolean>(false);
    let [audioLoaded, setAudioLoaded] = useState<boolean>(false);
    let [audioEnded, setAudioEnded] = useState<boolean>(false)
    let [visualEnded, setVisualEnded] = useState<boolean>(false);
    let [showImageOrVideo, setShowImageOrVideo] = useState<boolean>(false);
    let videoRef = useRef();
    let imageRef = useRef();
    let audioRef = useRef();
    // @ts-ignore
    let imageComponent: HTMLImageElement = <img alt="Alert image" ref={imageRef}/>;
    // @ts-ignore
    let videoComponent: HTMLVideoElement = <video ref={videoRef}></video>;
    // @ts-ignore
    let audioComponent: HTMLAudioElement = <audio ref={audioRef}></audio>

    useEffect(() => {
        if (props.currentAlert){
            // Mark as already loaded that which isn't gonna be uses.
            setImgLoaded((!!!props.imageUrl));
            setVideoLoaded(!!!props.videoUrl)
            setAudioLoaded(!!!props.audioUrl);
            setAudioEnded(!!!props.audioUrl)
            setVisualEnded(!!!props.imageUrl || !!!props.videoUrl);
            setShowImageOrVideo(false);

            if (props.imageUrl){
                let imageElement : HTMLImageElement | undefined= imageRef.current;
                imageElement!.src = props.imageUrl;
                imageElement!.onload = () => {
                    setImgLoaded(true);
                }
            }

            if (props.videoUrl){
                let videoElement: HTMLVideoElement | undefined = videoRef.current;
                videoElement!.src = props.videoUrl;
                videoElement!.oncanplaythrough = () => {setVideoLoaded(true)};
            }

            if (props.audioUrl){
                let audioElement: HTMLAudioElement | undefined = audioRef.current;
                audioElement!.src = props.audioUrl;
                audioElement!.oncanplaythrough = () => {setAudioLoaded(true)}
                audioElement!.onended = () => onAudioEnd();
            }

            setShowImageOrVideo(true);
        }
    }, [props.currentAlert])

    function allLoaded() {
        return videoLoaded && imgLoaded && audioLoaded;
    }

    useEffect(() => {
        // do the video play here and stuff
        if (allLoaded()){
            if (props.videoUrl){
                let videoElement: HTMLVideoElement | undefined = videoRef.current;
                videoElement!.volume = volume;
                videoElement!.play();
            }

            if (props.audioUrl){
                let audioElement: HTMLAudioElement | undefined = audioRef.current;
                audioElement!.volume = volume;
                audioElement!.play()
            }
        }
    }, [videoLoaded, imgLoaded, audioLoaded])

    function onVisualElementEnd(){
        setShowImageOrVideo(false);
        setVisualEnded(true);
        let imageElement : HTMLImageElement | undefined= imageRef.current;
        imageElement!.src = "";
        let videoElement: HTMLVideoElement | undefined = videoRef.current;
        videoElement!.src = "";

        if (allEnded()){
            props.onAlertEnd();
        }
    }

    function onAudioEnd(){
        setAudioEnded(true);
        let audioElement: HTMLAudioElement | undefined = audioRef.current;
        audioElement!.src = "";
        // fuck it, it is not updating the Ended state, so i'm just considering the alert done when the audio ends.
        props.onAlertEnd();
        // if (allEnded()){
        //     props.onAlertEnd();
        //     console.log("ALL ENDED, VIA AUDIO")
        // }
    }

    let allEnded = () => visualEnded && audioEnded;

    return (<div>
        {props.currentAlert && <div>
        {<div style={{display: (showImageOrVideo && allLoaded()) ? 'block' : 'none'}}>

            <AlertAnimation playing={showImageOrVideo} isVideo={!!props.videoUrl} videoElementRef={videoRef} onComplete={onVisualElementEnd} imageDuration={props.imageDuration ?? 3000}>
                <div style={{display: props.imageUrl ? 'block' : 'none'}}>
                    {imageComponent}
                </div>
                <div style={{display: props.videoUrl ? 'block' : 'none'}}>
                    {videoComponent}
                </div>
                {props.messageComponent}
            </AlertAnimation>
            <div style={{display: props.audioUrl ? 'block' : 'none'}}>
                {audioComponent}
            </div>
        </div>}
        </div>}
    </div>)
}