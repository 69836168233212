
export enum PricingTier {
    Base = 0,
    Brutal = 2
}

export function GetPricingText(pricing: PricingTier) {
    switch (pricing){
        case PricingTier.Base:
            return "Regular";
        case PricingTier.Brutal:
            return "BRÜTAL";
        default:
            return "UNKNOWN";
    }
}