import React from "react"
import "./activeSkullsView.css"
import WheelApis from "../../../BackendLayer/WheelAPI";

export default class ActiveSkullsViewComponent extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            activeSkulls: null
        }
    }

    async init(){
        let wheelData = (await WheelApis.getWheel(this.props.match.params.id)).data;
        let activeSkulls = wheelData.options.filter(o => o.deleted);
        this.setState({activeSkulls: activeSkulls});
    }

    componentDidMount(){
        let initPromise = this.init();
    }

    render(){
        const skullNames = this.state.activeSkulls
            ? this.state.activeSkulls.map(option =>
                <div className="skull" key={option.id}>
                    <div><img alt="Wheel option background" src={option.background.url}/></div>
                    {/*<h4 className={"skullTitle"}>{option.text}</h4>*/}
                </div>
            )
            : "Loading";
        return (
            <div>
                <div>
                    {skullNames}
                </div>
            </div>
        )
    }
}