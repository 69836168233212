import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import AuthAPI from "../BackendLayer/AuthAPI";

const useStyles = makeStyles((theme) => ({
    twitchButton: {
        color: "white",
        backgroundColor: "#9046ff"
    }
}))

export function LoginPage(){
    const classes = useStyles();
    return(
        <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justify="center"
            style={{minHeight: "12vh"}}
        >
            <Grid item xs={12}>
                <Typography variant={"h6"} component={"h6"}>You need to be logged in to do that.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Link href={AuthAPI.GetTwitchLoginLink()}><Button variant={"contained"} className={classes.twitchButton}>Login with Twitch</Button></Link>
            </Grid>
        </Grid>     
    )
}