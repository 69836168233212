import {UserInfo} from "../../../BackendLayer/AuthAPI";
import {Button, TableCell, TableRow} from "@material-ui/core";

export interface UserAccountRowProps extends UserInfo {
    openGiveTokenPopup: (userInfo: UserInfo, event: any) => void
}



export default function UserAccountRow(props: UserAccountRowProps){
    return (
        <TableRow key={props.twitchUserId}>
            <TableCell component="th" scope="row">{props.displayName}</TableCell>
            <TableCell component="th" scope="row">{props.twitchUserId}</TableCell>
            <TableCell component="th" scope="row">
                {props.butcherTokens}
            </TableCell>
            <TableCell component="th" scope="row">{props.brutalButcherTokens}</TableCell>
            <TableCell component="th" scope="row">
                <Button variant={"contained"} onClick={(event) => props.openGiveTokenPopup(props, event)}>
                    Give tokens
                </Button>
            </TableCell>
        </TableRow>
    )

}