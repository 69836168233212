import React from "react"
import DirectorState from "../../Constants/DirectorState";
import Box from "@material-ui/core/Box";

function GetTextAndColor(directorState: DirectorState){
    switch (directorState){
        case DirectorState.Ready:
            return {text: "Waiting for some brawlers to rise up, yeah!", color: "#1484bf"}
        case DirectorState.Active:
            return {text: "MOSH PIT IS LIVE, OH YEAH!", color: "#ff0000"}
        case DirectorState.Paused:
            return {text: "The mosh pit is PAUSED!? This is awkward, yeah!", color: "#ffd900"}
        case DirectorState.OnABreak:
            return {text: "Taking a breather, yeah", color: "#969696"}
        case DirectorState.AwaitingStartConfirmation:
            return {text: "Pit's about to open with these brawlers, yeah!", color: "#1484bf"}
        default:
            return {text: "FATAL ERROR OH NO!", color: "#969696"}
    }
}

export interface StatusCardProps{
    directorState: DirectorState | null,
    className: string
}

export default function StatusCard(props: StatusCardProps){
    let {text, color} = GetTextAndColor(props.directorState ?? DirectorState.Ready);
    return (
        // <Card className={props.className} elevation={3} square={true}>
        <div className={props.className} style={{color: color}}>
            <Box fontWeight="fontWeightBold" >{text}</Box>
        </div>
        // </Card>
    )
}