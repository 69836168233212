import {CreateWaitingTracker, WaitingTracker} from "../LongOp";
import {useFetchAsync} from "../Fetch";
import ButcherAPI, {DirectorDataPresentation} from "../../BackendLayer/ButcherAPI";
import DirectorState from "../../Constants/DirectorState";
import {subscriptionSetter} from "../../BackendLayer/WebsocketsWrapper";

export interface DirectorDataHandlers {
    loadingTracker: WaitingTracker,
    directorState: DirectorState | null,
    fullDirectorData: DirectorDataPresentation | null,
    setFullDirectorData: (newData: DirectorDataPresentation) => void
    changeDirectorState: (newState: DirectorState) => void,
    hotReload: () => void
}

const defaultState = null; // used to be DirectorState.Ready;
const stateFetcher = () => ButcherAPI.GetDirectorState();
const fetchStateError: string = "Fetch State Error";
export function useDirectorData() : DirectorDataHandlers
{
    let {loading, data: directorData, setData: setDirectorData, fetchError, hotReload} = useFetchAsync<DirectorDataPresentation, undefined>(defaultState, stateFetcher, fetchStateError);
    const loadingTracker = CreateWaitingTracker(loading, fetchError, "Loading director state", false);
    const changeDirectorState = (newState: DirectorState) => {
        if (directorData == null){
            console.error("Attempting to change director state, but got no director data");
            return;
        }

        directorData.state = newState;
        setDirectorData({...directorData, state: newState})
    }
    return {loadingTracker, directorState: directorData?.state ?? DirectorState.Ready, setFullDirectorData: setDirectorData, fullDirectorData: directorData, changeDirectorState, hotReload}
}

export function setDirectorStateSubscriptions(createOrResetSubscription: subscriptionSetter,
                                              setDirectorState: (newState: DirectorDataPresentation) => void,
                                              extraOnStateChange?: (newState: DirectorDataPresentation) => void){
    createOrResetSubscription("DirectorStateChange", "DirectorStateSync", (updatedDirectorData: DirectorDataPresentation) => {
        setDirectorState(updatedDirectorData)
        if (extraOnStateChange){
            extraOnStateChange(updatedDirectorData);
        }
    })
}