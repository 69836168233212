import useWebsockets from "../../../BackendLayer/WebsocketsWrapper";

export enum GeneralAlertType {
    Praise = 0,
    NormalButcherRedemption = 1,
    BrutalButcherRedemption = 2,
    MetalAlert = 3,
    TheMusical = 4,
    StreamSingout = 5,
    TheBox = 6,
    Pandemonium = 7,
}

export enum GeneralAlertCurrencyType {
    ChannelPoints = 0,
    Bits = 1,
    Tip = 2,
    StreamElementsPoints = 3,
    None = 4,
}

export interface GeneralAlertMessage {
    type: GeneralAlertType,
    redeemer: string,
    triggeredByCurrency: GeneralAlertCurrencyType
}

export function useGeneralAlertWebsockets(alertCallback: (message: GeneralAlertMessage) => void, onDisconnect: () => void){
    let {connection, createOrResetSubscription} = useWebsockets("HomykHub", onDisconnect);
    if (connection){
        createOrResetSubscription("GeneralAlert", "GeneralAlertBox", alertCallback)
    }
}