import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";

export interface ActivateButcherButtonProps {
    className: string,
    onClick?: (p: any) => void,
    message: string,
    alreadyTriggered: boolean,
}

export default function ActivateButcherButton(props: ActivateButcherButtonProps){
    return(
        <div>
            {props.alreadyTriggered
            ?
            (<Button variant={"contained"} disabled className={props.className}>
                <Typography component={"h3"} variant={"h3"}>{props.message}</Typography>
            </Button>)
            :
            (<Button variant={"contained"} className={props.className} onClick={props.onClick}>
                <Typography component={"h3"} variant={"h3"}>{props.message}</Typography>
            </Button>)
            }
        </div>)
}