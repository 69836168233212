import React from "react"
import {ImageLoadingComponent} from "./ImageLoadingComponent";
import {ColorPicker} from "./ColorPicker";


export class WedgeConfigurationComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            showImageLoadDialog: false,
            showColorDialog: false
        }
    }
    onChange(event, isNumber = false){
        let value = isNumber ? event.target.valueAsNumber : event.target.value;
        this.props.onChange(this.props.optionId, event.target.name, value);
    }

    onPopUpClose(){
        this.setState({showImageLoadDialog: false})
    }

    render(){
        return (
            <li className={"option"}>
                <div style={{"backgroundColor": this.props.color + "42"}}>
                    <label><input className="optionText" placeholder="Option name" type="text" name="text" value={this.props.text} onChange={(event) => this.onChange(event)}/></label>
                    <div className={"smallFields"}>
                            <label>Color:
                                <ColorPicker color={this.props.color} colorPropName="color" onChange={(propName, color)  => this.props.onChange(this.props.optionId, propName, color)}/>
                            </label>
                        <label>Weight:
                            <input className="optionWeight" placeholder="1" type="number" step="0.1" name="weight" value={this.props.weight} onChange={(event) => this.onChange(event, true)}/></label>
                        <input type={"button"} value={"remove"} onClick={() => this.props.onRemoval(this.props.optionId)}/>
                    </div>
                    <div className={"smallField"}>
                        <label>{this.state.showImageLoadDialog
                            ? <input type={"button"} value={"Cancel"} onClick={() => this.setState({showImageLoadDialog: false})}/>
                            : <input type={"button"} value={this.props.background.img ? "Modify image" : "Add image"} onClick={() => this.setState({showImageLoadDialog: true})}/>}
                        </label>
                        <label>
                            Active: <input type={"checkbox"} name={"deleted"}  checked={!this.props.deleted} onChange={(event)=> this.props.onChange(this.props.optionId, event.target.name, !!!this.props.deleted)}/>
                        </label>


                        {this.state.showImageLoadDialog ?
                            (<ImageLoadingComponent optionId={this.props.optionId} {...this.props.background}
                                                    onImageLoaded={this.props.onImageLoaded}
                                                    onChange={this.props.onBackgroundChange}
                                                    onClose={this.onPopUpClose.bind(this)}
                            />)
                            : "" }
                    </div>
                </div>
            </li>
        )
    }
}
