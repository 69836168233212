import React from "react"
import DirectorState from "../../Constants/DirectorState";

export interface ButcherListDescriptorProps{
    state: DirectorState,
    activationCount: number,
    className: string
}
export default function ButcherListDescriptor(props: ButcherListDescriptorProps){
    let text = null;
    switch (props.state){
        case DirectorState.Active:
            text = "Now voicing:";
            break;
        case DirectorState.Paused:
            text = "Voicing paused for:"
            break;
        case DirectorState.OnABreak:
            text = "Biding their time:"
            break;
        default:
            text = null;
    }

    return (
        <React.Fragment>

            {props.activationCount > 0 && text != null &&
            <div className={props.className} style={{textAlign: "center"}}>
                {text}
            </div>}
        </React.Fragment>
    )
}