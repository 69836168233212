import React from "react"
import "./configurationStyle.css"
import {WedgeConfigurationComponent} from "./wedgeConfigurationComponent";
import {ColorPicker} from "./ColorPicker";

export class WheelConfigurationComponent extends React.Component{
    constructor(props){
        super(props);
        this.onChange = this.onChange.bind(this);
        this.setProp = this.setProp.bind(this);
        this.onChangeNum = this.onChangeNum.bind(this);
        this.onAddOption = this.onAddOption.bind(this);
        this.onOptionRemoval = this.onOptionRemoval.bind(this);
        this.onChangeToggle = this.onChangeToggle.bind(this);
        this.state ={
            collapseOptions: false,
            collapseColors: true,
            collapsePopUp: true,
        }
    }



    onChangeNum(event){
        let value = event.target.valueAsNumber;
        this.setProp(event.target.name, value);
    }
    onChangeToggle(event){
        let value = event.target.checked;
        this.setProp(event.target.name, value);
    }

    onChange(event){
        let value = event.target.value;
        this.setProp(event.target.name, value);
    }

    setProp(name, value){
        this.props.setWheelProp(name, value);
    }
    onAddOption(){
        this.props.onAddWheelOption();
    }

    onOptionRemoval(id){
        this.props.onRemoveWheelOption(id);
    }

    render(){

        let wedgeConfRows = this.props.options.map(
            (options) => <WedgeConfigurationComponent key={options.id} {...options}
                                                      onChange={this.props.onWedgeChange}
                                                      onBackgroundChange={this.props.onBackgroundChange}
                                                      onRemoval={this.onOptionRemoval}
                                                      onImageLoaded={this.props.onImageLoaded}
            />);
        let audioSourceSelectors = [];
        for(let i = 1; i <= this.props.totalAudioSources; i++){
            const selector = (<option value={i} key={i}>{i}</option>);
            audioSourceSelectors.push(selector);
        }
        return(
            <div className={"configurationComponent"}>
                <input value="Close configuration" type={"button"} className={"closeConfigButton"}
                       onClick={this.props.onClose}/>
                <input value={"Save wheel"} type={"button"} className={"closeConfigButton"} onClick={() => this.props.onSaveWheel(false)}/>
                <input value={"Save wheel as new template"} type={"button"} className={"closeConfigButton"} onClick={() => this.props.onSaveWheel(true)}/>
                <div>
                <span className={"subHeader"}>Choices: </span>
                    <input value={this.state.collapseOptions ? "Show" : "Collapse"}
                           type={"button"} onClick={() => this.setState((state, props) => ({collapseOptions: !state.collapseOptions}))}/>
                </div>
                <div>
                    <ol>{this.state.collapseOptions ? "" : wedgeConfRows}</ol>
                    <input className="addOptionButton" type={"button"} name={"addOption"} value={"Add Option"} onClick={this.onAddOption}/>
                </div>
                <span className={"subHeader"}>Wheel center</span>
                <div>
                    <div><label className={"subOption"}>X: <input type="range" min={this.props.wheelRadius} max="1920" step="1" name={"wheelCenterX"} value={this.props.wheelCenterX} onChange={this.onChangeNum}/></label></div>
                    <div><label className={"subOption"}>Y: <input type="range" min={this.props.wheelRadius} max="1080" step="1"  name={"wheelCenterY"} value ={this.props.wheelCenterY} onChange={this.onChangeNum}/></label></div>
                </div>
                <div><label className={"subHeader"}>Wheel radius: <input type="range" min="10" max="800" step="1" name={"wheelRadius"} value={this.props.wheelRadius} onChange={this.onChangeNum}/></label></div>
                <div>
                    <label className={"subHeader"}>Launch spin speed: <input type={"range"} min="1" max="20"
                                                                             step={"0.1"} name={"launchSpeed"}
                                                                             value={this.props.launchSpeed} onChange={this.onChangeNum}/></label>
                </div>
                <div>
                    <label className={"subHeader"}>Slowing speed: <input type="range" name="speedDecay" min="0" max="1" step={"0.01"} value={this.props.speedDecay} onChange={this.onChangeNum}/></label>
                </div>

                <span className={"subHeader"}>Text: </span>
                <div>
                    <label className={"subOption"}>Max size: <input type="range" name="defaultTextSize" min="8" max="60" step={"1"} value={this.props.defaultTextSize} onChange={this.onChangeNum}/></label>
                </div>
                <div>
                    <label className={"subOption"}>Min size: <input type="range" name="minTextSize" min="10" max={this.props.defaultTextSize} step={"1"} value={this.props.minTextSize} onChange={this.onChangeNum}/></label>
                </div>
                <div>
                    <span className={"subHeader"}>Spin start mechanism:
                        <div>
                            <label className={"subOption"}><input type={"radio"} name={"spinStartMode"} value={"click"} checked={this.props.spinStartMode === "click"} onChange={this.onChange}/>Click</label>
                        </div>
                        <div>
                            <label className={"subOption"}><input type={"radio"} name={"spinStartMode"} value={"dragAndRelease"} checked={this.props.spinStartMode ==="dragAndRelease"} onChange={this.onChange}/>Drag and release</label>
                        </div>
                    </span>
                </div>
                <div>
                    <label className={"subHeader"}>Remove winner from the wheel: <input type={"checkbox"} name="removeOptionOnSelection" checked={this.props.removeOptionOnSelection} onChange={this.onChangeToggle}/></label>
                </div>
                <div>
                        <div>
                        <div>
                            <label className={"subHeader"}>Sound volume: <input type="range" name="soundVolume" min={0}
                                                                          max={1} step={0.02}
                                                                          value={this.props.soundVolume}
                                                                          onChange={this.onChangeNum}/></label>
                        </div>
                        <label className={"subHeader"}>Stopper sound:
                        <select className="configSelect" value={this.props.audioSourceIndex} onChange={(event) => this.props.setAudioSource(parseInt(event.target.value))}>
                            <option value={-1} key={-1}>None</option>
                            <option value={-2} key={-2}>Custom</option>
                            {audioSourceSelectors}
                        </select></label>
                        </div>

                        <div>
                        {this.props.audioSourceIndex === -2
                            ? <span>
                                <label className={"subOption"}>Wedge change sound: <input className="optionText" placeholder="Audio URL" type="text" name="wedgeChangeAudioSource"
                                                                               value={this.props.wedgeChangeAudioSource} onChange={this.onChange}/></label>
                                <input type={"button"} value={"Load"}
                                       onClick={() => this.props.setAudioSource(this.props.audioSourceIndex)}/>
                                <input type={"button"} value={"Test"}
                                              onClick={() => this.props.playSound("wedgeChange")}/>
                                </span>
                            : ""}
                        </div>
                        <div>
                             <span>
                                <label className={"subHeader"}>Winner fanfare sound: <input className="optionText" placeholder="Audio URL" type="text" name="winnerFanfareAudioSource"
                                                                                          value={this.props.winnerFanfareAudioSource} onChange={this.onChange}/></label>
                                <input type={"button"} value={"Load"}
                                       onClick={this.props.loadWinnerFanfare}/>
                                <input type={"button"} value={"Test"}
                                       onClick={() => this.props.playSound("winner")}/>
                            </span>

                        </div>

                </div>
                <div>
                    <span className={"subHeader"}>Stopper mode:
                        <div>
                            <label className={"subOption"}><input type={"radio"} name={"stopperMode"} value={"normal"}
                                                                  checked={this.props.stopperMode === "normal"}
                                                                  onChange={this.onChange}/>Normal</label>
                        </div>
                        <div>
                            <label className={"subOption"}><input type={"radio"} name={"stopperMode"}
                                                                  value={"POD"}
                                                                  checked={this.props.stopperMode === "POD"}
                                                                  onChange={this.onChange}/>POD</label>
                        </div>
                    </span>
                </div>
                <span className={"subHeader"}>Colors: </span>
                <input value={this.state.collapseColors ? "Show" : "Collapse"}
                       type={"button"} onClick={() => this.setState((state, props) => ({collapseColors: !state.collapseColors}))}/>
                {this.state.collapseColors ? "" :
                (<div>
                    <div>
                        <label className={"subHeader"}>Background color:
                            <ColorPicker color={this.props.backgroundColor} colorPropName={"backgroundColor"} onChange={this.setProp}/>
                        </label>
                    </div>
                    <div>
                        <label className={"subHeader"}>Text color:
                            <ColorPicker color={this.props.textColor} colorPropName={"textColor"} onChange={this.setProp}/>
                        </label>
                    </div>
                    <div>
                        <label className={"subHeader"}>Text outline color:
                            <ColorPicker color={this.props.textOutlineColor} colorPropName={"textOutlineColor"} onChange={this.setProp}/>
                        </label>
                    </div>
                    <div>
                        <label className={"subHeader"}>Stopper color:
                            <ColorPicker color={this.props.stopperColor} colorPropName={"stopperColor"} onChange={this.setProp}/>
                        </label>
                    </div>
                    <div>
                        <label className={"subHeader"}>Stopper outline color:
                            <ColorPicker color={this.props.stopperOutlineColor} colorPropName={"stopperOutlineColor"} onChange={this.setProp}/>
                        </label>
                    </div>
                </div>)}

                <div>
                    <span className={"subHeader"}>Winner popup</span>
                    <input value={this.state.collapsePopUp ? "Show" : "Collapse"}
                           type={"button"} onClick={() => this.setState((state, props) => ({collapsePopUp: !state.collapsePopUp}))}/>
                    {this.state.collapsePopUp ? "" :
                    (<div>
                        <div>
                            <label className={"subOptionSpaced"}>Text: <input className="optionText" placeholder="Winner text:" type="text" name="winnerText"
                                                                        value={this.props.winnerText} onChange={this.onChange}/></label>
                        </div>
                        <div>
                            <label className={"subOptionSpaced"}>Winner popup name text color:
                                <ColorPicker color={this.props.winnerColor} colorPropName={"winnerColor"} onChange={this.setProp}/>
                            </label>
                        </div>
                        <div>
                            <label className={"subOptionSpaced"}>Winner popup message text color:
                                <ColorPicker color={this.props.winnerTextColor} colorPropName={"winnerTextColor"} onChange={this.setProp}/>
                            </label>
                        </div>
                        <div>
                            <label className={"subOptionSpaced"}>Winner popup background color:
                                <ColorPicker color={this.props.winnerBackgroundColor} colorPropName={"winnerBackgroundColor"} onChange={this.setProp}/>
                            </label>
                        </div>
                        <div>
                            <label className={"subOptionSpaced"}>Winner popup border color:
                                <ColorPicker color={this.props.winnerBorderColor} colorPropName={"winnerBorderColor"} onChange={this.setProp}/>
                            </label>
                        </div>
                    </div>)}
                </div>
                <div>
                    <input type={"button"} value={"Import"} onClick={this.props.toggleImportModal}/><input type={"button"} value={"Export"} onClick={this.props.toggleExportModal}/>
                </div>

            </div>)
    }
}