const audioRoot: string = "assets/audio/";

const AudioSources = {
    DiscordEntry: audioRoot + "discordEnterChat.ogg",
    DiscordExit: audioRoot + "discordLeaveChat.ogg",
    NewActivation: audioRoot + "butcherActivationChallenger.ogg",
    NewProposal: audioRoot + "butcherProposalChief.ogg",
    ButchersReady: audioRoot + "butcherStart.ogg",
    BoxingBell: audioRoot + "boxingBell.mp3",
    RecordScratch: audioRoot + "recordScratch.ogg",
    PowerWeaponsInbound: audioRoot + "powerWeaponsInbound.ogg"
}

export default AudioSources;