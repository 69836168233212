import Backdrop from "@material-ui/core/Backdrop";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingMessage: {
        marginRight: theme.spacing(1)
    }
}));

export interface WaitingBackdropProps {
    loading: any,
    message: string
}

export default function WaitingBackdrop(props: WaitingBackdropProps){
    const classes = useStyles();
    return(
        <Backdrop open={props.loading} className={classes.backdrop}>
            <Typography className={classes.loadingMessage} variant={"h6"}>{props.message}</Typography>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}