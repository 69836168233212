import axios from 'axios'
import {backendConfig} from "./Config";
import {Debug} from "../Constants/DevelopmentConstants";
import {PricingTier} from "../Constants/PricingCategories";

const baseURL = backendConfig.getBaseRoute();
if (Debug) { console.log("baseURL: " + baseURL) }
const logsURL = baseURL + "Logs/";
const instance = axios.create(({
    withCredentials: true
}))

export interface HomykEventLogEntry {
    id: number;
    dateTime: string;
    eventType: HomykEventLogType;
    userDisplayName: string;
    userTwitchId: string;
    tokenTier: PricingTier;
    extraInfo: string;
    newTokenBalance: number | null;
}

export enum HomykEventLogType {
    TwitchCheerRedemption = 0,
    StreamLabsTipRedemption = 1,
    StreamElementsRedemption = 2,
    AccountCreationThroughExtension = 3,
    AccountCreationThroughWebsite = 4,
    OfficialButcherCreation = 5,
    ButcherProposal = 6,
    ButcherAcceptedForever = 7,
    ButcherAcceptedOnce = 8,
    ButcherRejected = 9,
    ButcherActivation = 10,
    ButcherDeactivation = 11
}

function GetLogs(from: string, to: string){
    return instance.post<HomykEventLogEntry[]>(`${logsURL}`, {from: from, to: to});
}

const LogsAPI = {
    GetLogs
}

export default LogsAPI;