import {Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
    general:{
        textAlign: "center",
    },
    title: {
        color: "#3950f6",
        fontSize: "4em"
    },
    question: {
        fontWeight: "bold",
        color: "#fff",
        maxWidth: "680px",
        margin: "2em auto 1em auto",
        // textAlign: "justify",
    },
    paragraph: {
        color: "#dedede",
        maxWidth: "680px",
        margin: "1em auto 1em auto",
        textAlign: "justify",
    },
    img: {
        maxWidth: "680px",
        margin: "1em auto 1em auto",
    }
}));
export default function HelpView()
{
    const classes = useStyles();
    return (
        <div className={classes.general}>
            <Typography className={classes.title} component={"h3"}> So, how does this thing work? </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                The Vocal Butcher does <b>vocal things and bullshit</b> for a living.
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                One of those things is improvising characters on stream, what he calls <i>"vocal butchers"</i>. They last 5 minutes.
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                You can <b>demand ("activate") a vocal butcher</b> by using a token to select one of the many characters in the gallery. The more demanding characters require special "brütal" tokens.
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                To <b>get tokens</b>, you can cheer in chat (1000 for a regular, 1500 for a brütal), tip (10 bucks for a regular, 15 for a brütal) or snatch one of the two per stream freebies using channel points.
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                Activated characters get queued, and TheVocalButcher will (usually immediately), start a voice improv session of 5 minutes with it. Characters activated during an active session will be active immediately and added to the session, but will not extend the timer.
            </Typography>
            <Typography variant={"body1"} className={classes.paragraph}>
                Any other question or issue you may have, feel free to ask in stream chat. Happy butcherin'!
            </Typography>
        </div>
    )

}