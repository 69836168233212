import React from "react"
import queryString from 'query-string'
import AuthAPI from "../../BackendLayer/AuthAPI";
import WaitingBackdrop from "../../Components/WaitingBackdrop";

export interface LoginRedirectViewProps {
    location: any, //Location<any>,
    apiIntegrationType?: "twitchApiIntegration" | "streamLabsApiIntegration" | string
}
export default function LoginRedirectView(props: LoginRedirectViewProps)
{
    let params: any = queryString.parse(props.location.search);
    let apiCall;
    let loadingMessage;
    switch (props.apiIntegrationType){
        default:
            console.log("Redirected to twitch login");
            loadingMessage = "Logging in, hold on a second";
            apiCall = AuthAPI.Login(params.code)
            .then((response) => window.location.href = "/")
    }
    apiCall.catch(error => console.log(error));
    return (<div>
        <WaitingBackdrop loading message={loadingMessage}/>
    </div>)
}