/**
 * Taken from https://codesandbox.io/s/react-easy-crop-demo-with-cropped-output-forked-s9pbr with some modifications
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

export default async function getCroppedImgSet(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    let data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 + (image.width - safeArea)/2 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    return canvas.toDataURL('image/jpeg');

    // const croppedImage = await createImage(fullCropUrl);
    // const {url: thumbnailUrl, blob: thumbnailBlob} = await createThumbnail(canvas, ctx, pixelCrop, croppedImage, ImageConfig.ThumbnailSize);
    // return {thumbnail: {url: thumbnailUrl, blob: thumbnailBlob}, fullCrop: {url: fullCropUrl, blob: fullCropBlob}};
}

export async function createResized(imageUrl, targetSize, resizeToFit = false){
    const image = await createImage(imageUrl);
    const canvas = document.createElement('canvas')
    canvas.width = targetSize.x;
    canvas.height = targetSize.y;
    const ctx = canvas.getContext('2d')
    let scale = 1;
    if (resizeToFit){
        scale = GetFitScale({x: image.width, y: image.height}, targetSize);
        if (scale > 1) {
            scale = 1;
        }
        canvas.width = Math.floor(image.width * scale);
        canvas.height = Math.floor(image.height * scale);
    }
    else{
        scale = GetFillScale({x: image.width, y: image.height}, targetSize);
        if (scale > 1){
            scale = 1;
            canvas.width = image.width;
            canvas.height = image.height;
        }
    }

    ctx.scale(scale, scale);
    ctx.drawImage(image, 0, 0);
    return extractCanvasData(canvas);
}

function GetFillScale(currentSize, targetSize){ // gets the minimum scale factor to fill the entire target size canvas
    return Math.max(targetSize.x / currentSize.x, targetSize.y / currentSize.y)
}

function GetFitScale(currentSize, targetSize){ // gets the scale factor to fit inside the target size bounds
    return Math.min(targetSize.x / currentSize.x, targetSize.y / currentSize.y)
}

const extractCanvasData = (canvas) =>
    new Promise(resolve => {
        canvas.toBlob((blob) => {
            blob.name="newButcherCrop.jpg";
            let url = window.URL.createObjectURL(blob);
            resolve({url, blob})
        }, 'image/jpeg')
    });

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        // image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}