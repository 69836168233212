export default class AnimationCoordinator {
    constructor(){
        this.animations = new Map();
        this.chains= new Map();
    }

    registerAnimation(key, tickFunction, activeNow){
        this.animations.set(key, {tickFunction, activeNow});
    }
    registerOnEndChain(endingAnimationKey, newAnimationKey, delay = 0){
        if (this.chains.has(endingAnimationKey)){
            this.chains.get(endingAnimationKey).push({newAnimationKey, delay});
        }
        else{
            this.chains.set(endingAnimationKey, [{newAnimationKey, delay}]);
        }
    }

    setAnimationActive(key, isActive){
        if (this.animations.has(key)){
            this.animations.get(key).activeNow = isActive;
        }
    }

    tick(timeStamp){
        const timeDelta = this.lastTick ? timeStamp - this.lastTick : 0;
        this.lastTick = timeStamp;
        const animationsToRemoveKeys = [];
        for(const [key, properties] of this.animations)
        {
            if (properties.activeNow){
                if (!properties.tickFunction(timeDelta))
                {
                    animationsToRemoveKeys.push(key);
                    if (this.chains.has(key)){
                        for(const chained of this.chains.get(key)){
                            if (this.animations.has(chained.newAnimationKey)){
                                if (chained.delay > 0){
                                    setTimeout(() => this.setAnimationActive(chained.newAnimationKey, true), chained.delay);
                                }
                                else{
                                    this.setAnimationActive(chained.newAnimationKey, true)
                                }
                            }
                        }
                    }
                }
            }
        }
        for (const key of animationsToRemoveKeys){
            this.animations.delete(key);
        }
    }

}