import React, {useState} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {Button} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            backgroundColor: theme.palette.secondary.main
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }),
);

function formatDate(date: Date): string
{
    return date.toISOString().split('T')[0];
}
interface LogsRangePickerProps{
    onSubmit: (from: string, to: string) => void,
}

export default function LogsRangePicker(props: LogsRangePickerProps) {
    const classes = useStyles();
    const defaultFromDate = new Date();
    defaultFromDate.setDate(defaultFromDate.getDate() - 1)
    let [fromDate, setFromDate] = useState<string>(formatDate(defaultFromDate))
    let [toDate, setToDate] = useState<string>(formatDate(new Date()));

    return (
        <div className={classes.container}>
            <TextField
                id="fromDate"
                label="From"
                type="date"
                defaultValue={fromDate}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => setFromDate(formatDate(new Date(event.target.value)))}
            />
            <TextField
                id="toDate"
                label="To"
                type="date"
                defaultValue={toDate}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event) => setToDate(formatDate(new Date(event.target.value)))}
            />
            <Button variant={"contained"} color={"primary"} onClick={() => props.onSubmit(fromDate, toDate)}>Get logs</Button>
        </div>
    );
}