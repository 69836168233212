import Button from "@material-ui/core/Button";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    button: {
        margin: "8px",
    },
}));

export default function PictureUpload(props){
    const classes = useStyles();
    function onSelectFile (e)  {
        if (e.target.files && e.target.files.length > 0){
            const reader = new FileReader();
            reader.addEventListener("load", () => {props.setCropSource(reader.result)});
            reader.readAsDataURL(e.target.files[0]);
            ;
        }
    }

    return(
        <Container align={"center"}>
            <div>
                <div className={classes.button}>
                    <input onChange={onSelectFile}
                           accept="image/*"
                           className={classes.input}
                           id="uploadButton"
                           type="file"
                    />
                    <label htmlFor="uploadButton">
                        <Button variant="contained" color="primary" component="span">
                            Upload picture
                        </Button>
                    </label>

                </div>
                <div><Button variant={"contained"} onClick={props.cancel}>Cancel</Button></div>
            </div>
            {/*Do not delete, this remains on hold until I solve the "tainted canvas" issue.*/}
            {/*When that's fixed, we can also load from url*/}
            {/*<div className={classes.button}> /!*TODO: allow people to edit the uploaded image*!/*/}
            {/*    <input*/}
            {/*           className={classes.input}*/}
            {/*           id="editButton"*/}
            {/*    />*/}
            {/*    <label htmlFor="editButton">*/}
            {/*        <Button variant="contained" color="primary" component="span">*/}
            {/*            Edit current picture*/}
            {/*        </Button>*/}
            {/*    </label>*/}
            {/*</div>*/}
        </Container>
    )
}