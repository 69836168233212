import * as signalR from "@microsoft/signalr";
import {useEffect, useState} from "react";
import {backendConfig} from "./Config";

export type subscriptionSetter = (eventName: string, actionName: string, onEventFunction: onEventFunction) => void
type onEventFunction = (params: any) => void;
type eventActionsMap = Map<string, onEventFunction>

interface EventStateCode {
    previous: number | null,
    current: number,
    lastUpdate: Date
}

export interface EventCodes {
    [category: string] : EventStateCode
}

interface NonSyncPayload {
    data: any,
    statusHash: EventCodes,
}

export interface GivOrTakOperationEvent{
    giverTwitchId?: string,
    recipientTwitchId: string,
}

function runEvent (eventActionsMap: eventActionsMap | undefined, params: NonSyncPayload)
{
    runActions(eventActionsMap, params.data);
}

function runActions(actionsMap: eventActionsMap | undefined, params: any){
    if (actionsMap){
        for (const action of actionsMap.values()){
            action(params);
        }
    }

}

export default function useWebsockets(hubName: string,
                                      onDisconnect: null | (() => void) = null){
    let [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    let [actionsMap, _] = useState(new Map<string, eventActionsMap>());
    useEffect(() => {
        const signalRConnection  = new signalR.HubConnectionBuilder()
            .withUrl(backendConfig.getBaseRoute() + hubName)
            .configureLogging((signalR.LogLevel.Information)) //TOD: change logging to a lesser value?
            .withAutomaticReconnect([0, 2000, 2000, 5000, 10000, 10000, 10000, 30000, 30000, 30000])
            .build();
        setConnection(signalRConnection);
        signalRConnection.start()
            .then(() =>
            {
                console.log(`Websocket connection to ${hubName} was successful.`)
            })
            .catch((error) => {console.error(`Websocket connection to ${hubName} failed.`); console.error(error)});
        signalRConnection.onreconnecting( (error) => console.warn("SignalR disconnected, attempting to reconnect. Error: ", error));
        signalRConnection.onreconnected( (connectionId) => console.warn("SignalR reconnected with connectionId " + connectionId));
        signalRConnection.onclose(error => {
            console.assert(signalRConnection.state === signalR.HubConnectionState.Disconnected);
            console.error(`Connection closed due to error "${error}". Try refreshing this page to restart the connection.`)
        });
    }, [hubName])

    let listen = (eventName: string, onEventFunction: onEventFunction) => connection?.on(eventName, onEventFunction);

    let createOrResetSubscription = (eventName: string, actionName: string, onEventFunction: onEventFunction) => {
        if (!actionName){
            actionName = "SingleAction";
        }

        if (!actionsMap.has(eventName)){
            const eventMap: eventActionsMap = new Map();
            eventMap.set(actionName, onEventFunction);
            actionsMap.set(eventName, eventMap);
        }
        else{
            const eventMap: eventActionsMap = actionsMap.get(eventName)!;
            eventMap.set(actionName, onEventFunction);
        }

        connection?.off(eventName);
        connection?.on(eventName, (params) => runEvent(actionsMap.get(eventName), params));
    }

    if (onDisconnect && connection){
        connection.onclose(onDisconnect);
    }
    return {connection, listen, createOrResetSubscription}
}

//HomykHub