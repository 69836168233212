import React, {MouseEventHandler} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import {Tooltip, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useFormField, useFormSelect} from "./ButcherEditionFormState";
import Fab from "@material-ui/core/Fab";
import EditIcon from '@material-ui/icons/Edit';
import {Link as RouterLink}  from "react-router-dom";
import ButcherAPI from "../../BackendLayer/ButcherAPI";
import {PricingTier} from "../../Constants/PricingCategories";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    butcherDataForm: {
        width: "90%",
    },
    nameInput: {
        textAlign: "left",
        fontSize: "2em",
        margin: "8px 0 24px 0",
        color: theme.palette.primary.light,
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.7em"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "1em"
        },
    },
    titleInput: {
        textAlign: "left",
        fontSize: "1.4em",
        margin: "8px 0 16px 0",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.4em"
        },
    },
    descriptionInput: {
        fontSize: "1em",
        margin: "8px 0 16px 0",
        color: theme.palette.text.secondary
    },
    pricingInput: {
        textAlign: "left"
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    detailsPopup: {
        border: '2px solid ' + theme.palette.primary.main
    },
    grid: {
        margin: "0",
    },
    imageCard: {
        margin: 0,
        padding: 0
    },
    media: {
        height: 0,
        paddingTop: "100%",
        [theme.breakpoints.down('sm')]: {
            paddingTop: "150%",
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: "200%",
        }
    },
    butcherTitle: {
        color: theme.palette.primary.light,
        [theme.breakpoints.down('xs')]: {
            display: "block",
            fontSize: theme.typography.h6.fontSize
        },
        [theme.breakpoints.down('sm')]: {
            display: "block",
            fontSize: theme.typography.h4.fontSize
        },
    },
    buttonBox: {
        textAlign: "left",
        margin: "0 0 24px 0"
    },
    smallButton: {
        marginLeft: "4px",
        marginRight: "4px",
        width: "5em"
    },
    detailsPanel:{
        padding: "8px 24px"
    },
    editImageButton: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    positionAnchor: {
        position: "relative",
    },
    rules: {
        margin: "8px",
        border: "solid 2px " + theme.palette.primary.dark,
        padding: "8px"
    }
}));

export interface ButcherWithoutPictures{
    id?: string,
    name: string,
    tagline: string,
    description: string,
    tags: string,
    origin: string,
    pricingCategory: PricingTier,
    previewEmbedCode: string,
}

export interface ButcherEditionFormProps extends ButcherWithoutPictures
{
    saveActionName: string,
    isNewButcher: boolean,
    onSave: (param: ButcherWithoutPictures) => void,
    startCropping: MouseEventHandler<HTMLButtonElement>,
    picture: string | undefined,
}

export default function ButcherEditionForm(props : any){
    const classes = useStyles();
    let {formControl: nameTextField, value: name} = useFormField("name", "Character/gimmick name",
        "The character's or gimmick's name", props.name, classes.nameInput);
    let {formControl: taglineTextField, value: tagline} = useFormField("tagline", "Tagline",
        "A short phrase or quip that represents the character", props.tagline, classes.titleInput);
    let {formControl: descriptionTextField, value: description}  = useFormField("description", "Description",
        "Optional extra details or lore", props.description, classes.descriptionInput, true);
    let {formControl: originTextField, value: origin} = useFormField("origin", "Origin",
        "Which show/game/movie/reality rift is the charater from? Or is it an original? Is it a modified version?", props.origin, classes.descriptionInput, true);
    let {formControl: tagsTextField, value: tags} = useFormField("tags", "Tags",
        "Additional info to ease search", props.tags, classes.descriptionInput, true);
    let {formControl: pricingSelectField, value: pricingCategory} = useFormSelect("pricingCategory", "Pricing category",
        [[PricingTier.Base, "Base"], [PricingTier.Brutal, "Brütal"]],
        props.pricingCategory ?? PricingTier.Base, classes.pricingInput)
    let {formControl: previewField, value: previewEmbedCode} = useFormField("preview", "Soundcloud preview embed code",
        "The \"embed\" code for a preview file hosted on soundcloud", ButcherAPI.Utils.CompletePreviewIframe(props.previewEmbedCode), classes.descriptionInput, false
    );
    function BuildButcher() : ButcherWithoutPictures
    {
        let butcher: ButcherWithoutPictures = {
            name, tagline, description, pricingCategory, origin, tags, previewEmbedCode
        }
        if (props.id){
            butcher.id = props.id;
        }
        return butcher;
    }

    function onSave(){
        props.onSave(BuildButcher());
    }

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid direction="row" container spacing={0} alignItems={"center"}>
                    <Grid item xs={5}>
                        <Card className={`${classes.paper} ${classes.positionAnchor}`}>
                            <Fab variant="extended" className={classes.editImageButton}
                                 onClick={props.startCropping}>
                                <EditIcon/>
                                {props.picture ? "Edit picture" : "Upload picture"}
                            </Fab>
                            <CardMedia image={props.picture ?? ButcherAPI.GetPlaceholderButcherImage()}
                                       className={classes.media}/>
                        </Card>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container direction={"column"} alignItems={"flex-start"}
                              className={classes.detailsPanel} spacing={4}>
                            <Grid item className={classes.butcherDataForm}>
                                <form noValidate autoComplete={"off"}>
                                    <div>{nameTextField}</div>
                                    <div>{taglineTextField}</div>
                                    <div>{descriptionTextField}</div>
                                    <div>{originTextField}</div>
                                    <div>{tagsTextField}</div>
                                    <div>{previewField}</div>
                                    <Tooltip
                                        title={"Choose Brütal if the brawler is very hard, laborious or requires extra setup, Normal otherwise. The streamer has the final say on it"}>
                                        <div>{pricingSelectField}</div>
                                    </Tooltip>
                                </form>
                            </Grid>
                            <Grid item>
                                <div className={classes.buttonBox}>
                                    <Button variant={"contained"} color={"primary"} className={classes.smallButton}
                                            onClick={() => onSave()}>
                                        <Typography variant={"button"}>
                                            {props.saveActionName}
                                        </Typography>
                                    </Button>
                                    {!props.isNewButcher &&
                                    <Button variant={"contained"} className={classes.smallButton}
                                            component={RouterLink} to={"/"}>
                                        <Typography variant={"button"}>
                                            Cancel
                                        </Typography>
                                    </Button>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}