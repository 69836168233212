import axios from "axios";
import {backendConfig} from "./Config";
import {PricingTier} from "../Constants/PricingCategories";
import ButcherStatus from "../Constants/ButcherStatus";
import ButcherApprovalStatus from "../Constants/ButcherApprovalStatus";
import DirectorState from "../Constants/DirectorState";

const baseURL = backendConfig.getBaseRoute();
const butcherURL = baseURL + "Butcher/";

const instance = axios.create(({
    withCredentials: true
}))

export interface ButcherPresentation {
    id: string;
    name: string;
    tagline: string;
    description: string;
    origin: string;
    tags: string;
    pricingCategory: PricingTier;
    thumbnailRoute: string;
    croppedPictureRoute: string;
    timesSummoned: number;
    status: ButcherStatus;
    approvalStatus: ButcherApprovalStatus;
    lastSummonedBy: string;
    lastSummonedAt: Date;
    createdAt: Date;
}
export interface ButcherProposal {
    butcher: ButcherDetails;
    createdAt: Date;
    creatorId: string;
    creatorName: string;
}

export interface ButcherDetails {
    id: string;
    name: string;
    tagline: string;
    description: string;
    origin: string;
    tags: string;
    pricingCategory: PricingTier;
    approvalStatus: ButcherApprovalStatus;
    timesSummoned: number;
    thumbnailRoute: string;
    croppedPictureRoute: string;
    fullSourceImagePictureRoute: string;
    previewEmbedCode: string,
}

export interface ButcherActivation {
    id: string;
    butcher: Butcher;
    activationTime: string;
    startTime: string | null;
    endTime: string | null;
    currentlyActive: boolean;
    userTwitchId: string;
    userDisplayName: string;
}

export interface Butcher {
    id: string | undefined;
    name: string;
    tagline: string;
    description: string;
    origin: string;
    tags: string;
    previewEmbedCode: string,
    pricingCategory: PricingTier;
    timesSummoned: number;
    thumbnailRoute: string;
    croppedPictureRoute: string;
    fullSourceImagePictureRoute: string;
    status: ButcherStatus;
    approvalStatus: ButcherApprovalStatus;
    lastSummonedBy: string;
    lastSummonedAt: Date;
    deleted: boolean;
    createdBy: string;
    createdAt: Date;
    lastModifiedBy: string;
    lastModifiedAt: Date | null;
    deletedBy: string;
    deletedAt: Date | null;
}

export interface ButcherCreateOrUpdateRequest {
    id?: string;
    name: string;
    tagline: string;
    description: string;
    origin: string;
    tags: string;
    previewEmbedCode: string,
    pricingCategory: PricingTier;
    picture: string;
    thumbnail: string;
    original: string;
}

export interface DirectorDataPresentation {
    state: DirectorState;
    isTimerActive: boolean;
    isTimerPaused: boolean;
    timerStartTime: Date;
    lastPauseTime: Date;
    timeSpentPaused: number;
    fullDuration: number;
}

function GetAllButchers(){
    // return new Promise((resolve, reject) => setTimeout(() => resolve(getMockButchers()), 200));
    return instance.get<ButcherPresentation[]>(butcherURL + "Activable").then(result => {
        result.data.forEach(butcher => CompleteImageUrls(butcher));
        return result;
    })
}

function GetButcherProposals(){
    return instance.get<ButcherProposal[]>(butcherURL + "Proposals").then(result => {
        result.data.forEach(proposal => CompleteImageUrls(proposal.butcher));
        return result;
    })
}

function GetAllCurrentButcherActivations(){
    return instance.get<ButcherActivation[]>(butcherURL + "Active").then(result => {
        result.data.forEach(activation => CompleteImageUrls(activation.butcher));
        return result;
    })
}
function GetPlaceholderButcherImage(){
    return baseURL + "img/placeHolders/butcherHighRes.png";
}

function GetPlaceHolderButcherThumbnail(){
    return baseURL + "img/placeHolder/butcherThumbnail.png";
}

function GetButcherDetails(butcherId: string){
    return instance.get<ButcherDetails>(butcherURL + butcherId).then(result => { result.data = CompleteImageUrls(result.data) as ButcherDetails; return result});
}

function CompleteImageUrls(butcher: ButcherDetails | Butcher | ButcherPresentation){
    butcher.thumbnailRoute = baseURL + butcher.thumbnailRoute;
    butcher.croppedPictureRoute = baseURL + butcher.croppedPictureRoute;
    return butcher;
}

function CreateNewButcher(butcher : ButcherCreateOrUpdateRequest){
    return PostButcher(butcher, false);
}

function CreateNewButcherWithoutCookies(butcher: ButcherCreateOrUpdateRequest, extensionIdToken: string){
    return PostButcherWithoutCookies(butcher, false, extensionIdToken);
}

function ProposeNewButcher(butcher: ButcherCreateOrUpdateRequest){
    return PostButcher(butcher, true);
}

function ProposeNewButcherWithoutCookies(butcher: ButcherCreateOrUpdateRequest, extensionIdToken: string){
    return PostButcherWithoutCookies(butcher, true, extensionIdToken);
}

function PostButcher(butcher: ButcherCreateOrUpdateRequest, isProposal: boolean){
    let formData = buildCreateOrUpdateButcherFormData(butcher);
    const url = butcherURL + (isProposal ? "Propose" : "");
    return instance.post<ButcherCreateOrUpdateRequest>(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function PostButcherWithoutCookies(butcher: ButcherCreateOrUpdateRequest, isProposal: boolean, extensionIdToken: string){
    let formData = buildCreateOrUpdateButcherFormData(butcher);
    const url = butcherURL + (isProposal ? "Propose" : "") + "WithoutCookies?extensionTokenId=" + extensionIdToken;
    return instance.post<ButcherCreateOrUpdateRequest>(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

function UpdateButcher(butcher: ButcherCreateOrUpdateRequest){
    let formData = buildCreateOrUpdateButcherFormData(butcher);
    return instance.put<ButcherCreateOrUpdateRequest>(butcherURL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

function DeleteButcher(butcherId: string){
    return instance.delete<void>(butcherURL + butcherId);
}

function ActivateButcher(id: string){
    return instance.put<ButcherPresentation>(butcherURL + "Activate/" + id);
}

function ActivateButcherWithoutCookies(id: string, extensionIdToken: string){
    return instance.put<ButcherPresentation>(butcherURL + "ActivateWithoutCookies/" + id + "?extensionTokenId=" + extensionIdToken);
}

function ActivateButcherOnBehalfOf(butcherId: string, userId: string){
    return instance.put<void>(butcherURL + "Activate/" + butcherId + "/OnBehalfOf/" + userId);
}

function DeactivateButcher(id: string) {
    return instance.put<void>(butcherURL + "Deactivate/" + id);
}

function DeactivateButcherWithoutCookies(id: string, extensionIdToken: string) {
    return instance.put<void>(butcherURL + "DeactivateWithoutCookies/" + id + "?extensionTokenId=" + extensionIdToken);
}

function GetDirectorData(){
    return instance.get<DirectorDataPresentation>(butcherURL + "Director/");
}

function SetDirectorState(state: DirectorState){
    return instance.put<DirectorDataPresentation>(butcherURL + "Director/State/" + state).then(result => result.data);
}

function ApproveButcherPermanently(id: string){
    return instance.put<void>(butcherURL + id + "/ApprovePermanently");
}

function ApproveButcherSingleUse(id: string){
    return instance.put<void>(butcherURL + id + "/ApproveSingleUse");
}

function RejectButcher(id: string){
    return instance.put<void>(butcherURL + id + "/Reject");
}

function DeactivateAllAndResetState(){
    return instance.put<void>(butcherURL + "DeactivateAllAndResetState");
}

function ChangeTokenAmount(twitchUserId: string, pricingTier: PricingTier, amount: number){
    const url = `${butcherURL}ChangeTokenAmount?twitchUserId=${twitchUserId}&type=${pricingTier}&amount=${amount}`
    return instance.put<void>(url);
}

function buildCreateOrUpdateButcherFormData(butcher: ButcherCreateOrUpdateRequest){
    let formData = new FormData();
    if (butcher.id){
        formData.append("id", butcher.id);
    }
    formData.append("name", butcher.name)
    formData.append("tagline", butcher.tagline);
    formData.append("origin", butcher.origin);
    formData.append("tags", butcher.tags);
    formData.append("description", butcher.description);
    formData.append("origin", butcher.origin);
    formData.append("tags", butcher.tags);
    formData.append("previewEmbedCode", butcher.previewEmbedCode);
    formData.append("pricingCategory", butcher.pricingCategory.toString());

    if (butcher.picture){
        formData.append("picture", butcher.picture)
        formData.append("thumbnail", butcher.thumbnail)
        formData.append("original", butcher.original)
    }
    return formData;
}

function CompletePreviewIframe(songCode: string){
    if (!songCode){
        return "";
    }
    else return "<iframe width=\"60\" height=\"120\" scrolling=\"no\" frameBorder=\"no\" allow=\"autoplay\" src=\"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1455670945&color=%23" + songCode + "&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=false\"></iframe>"
}

const Utils = {
    CompleteImageUrls,
    CompletePreviewIframe
}

const ButcherAPI = {
    GetAllButchers,
    GetButcherDetails,
    GetButcherProposals,
    GetAllCurrentButcherActivations,
    CreateNewButcher,
    CreateNewButcherWithoutCookies,
    ProposeNewButcher,
    ProposeNewButcherWithoutCookies,
    UpdateButcher,
    DeleteButcher,
    ActivateButcher,
    ActivateButcherWithoutCookies,
    ActivateButcherOnBehalfOf,
    DeactivateButcher,
    DeactivateButcherWithoutCookies,
    DeactivateAllAndResetState,
    GetPlaceholderButcherImage,
    GetPlaceHolderButcherThumbnail,
    CompleteImageUrls,
    GetDirectorState: GetDirectorData,
    SetDirectorState,
    ApproveButcherPermanently,
    ApproveButcherSingleUse,
    RejectButcher,
    ChangeTokenAmount,
    Utils
}

export default ButcherAPI;