import useWebsockets from "../../../BackendLayer/WebsocketsWrapper";

export enum ButcherAlertType {
    EndOfBreakWithEnqueuedButchers,
    EnqueuedFirstButcher,
    EnqueuedButcherWhileOnBreak,
    EnqueuedButcherWhileAlreadyReadyToStart,
    ButcherSessionStarted,
    ActivationWhileSessionIsActive,
    ButcherSessionPaused,
    ButcherSessionResumed,
    ButcherSessionEnded,
    NewProposal,
    FreebieInbound
}

export interface ButcherAlertData {
    butcherDisplayName: string;
    butcherTagLine: string;
    isBrutal: boolean;
    butcherPictureURL: string;
    activatorUserDisplayName: string;
}

export interface ButcherAlertMessage {
    id: string;
    type: ButcherAlertType;
    data: ButcherAlertData;
}

export function useButcherAlertWebsockets(alertCallback: (message: ButcherAlertMessage) => void, onDisconnect: () => void){
    let {connection, createOrResetSubscription} = useWebsockets("HomykHub", onDisconnect);
    if (connection){
        createOrResetSubscription("ButcherAlert", "ButcherAlertBox", alertCallback)
    }
}