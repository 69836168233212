import React from "react"
import Button from "@material-ui/core/Button";
import AudioSources from "./AudioSources";
// import {playSound} from "../CommonUtils/audio";

export default function AudioTest(){
    // @ts-ignore
    const audio: Audio = new Audio(AudioSources.NewProposal);
    console.log(audio);
    // @ts-ignore
    // const AudioContext = window.AudioContext || window.webkitAudioContext;
    // const audioCtx = new AudioContext();
    // const track = audioCtx.createMediaElementSource(audio);
    // audio.volume = 1;
    // const audioRef = useRef();
    return (
        <div>
            {/*@ts-ignore*/}
            {/*<audio ref={audioRef} src={"./discordEnterChat.ogg"}/>*/}
            {/*@ts-ignore*/}

            <Button onClick={() => audio.play().then(() => console.log("playing!")).catch((error: any) => console.log("Error on play", error))} variant={"contained"}>Test audio</Button>
            {/*<Button onClick={() => onNewButcherActivationAlert()} variant={"contained"}>Test audio alert</Button>*/}
        </div>
    )
}