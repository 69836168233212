import CanvasUtils from "./canvasUtils";

export default class TypewriterTextEffect
{
    constructor(ctx, text, color, font, totalWidth, lineHeight){
        this.ctx = ctx;
        this.fillStyle = color;
        this.font = font;
        this.fullText = text;
        this.lettersPerSecond = 35;
        this.totalTime = 0;
        this.totalWidth = totalWidth;
        this.lineHeight = lineHeight;
    }

    tickAnimations(timeDelta){
        if (this.fullText.length < (this.totalTime/1000) * this.lettersPerSecond){
            return false;
        }
        this.totalTime += timeDelta;
        return true;
    }
    draw(position){
        let lettersToDrawNow = Math.floor((this.totalTime/1000) * this.lettersPerSecond );
        this.ctx.fillStyle = this.fillStyle;
        this.ctx.font = this.font;
        CanvasUtils.drawMultiLineText(this.ctx, this.fullText, position, this.totalWidth, this.lineHeight, lettersToDrawNow);
    }
}