import {useUserData} from "./ButcherBrowseViewState";
import React, {useState} from "react";
import {useDirectorData} from "../../CommonUtils/DataFetchers/DirectorData";
import ButcherBrowser from "../../Components/ButcherBrowser/ButcherBrowser";

export interface ButcherBrowseViewProps {
    embedded?: boolean
}

export default function ButcherBrowseView(props: ButcherBrowseViewProps)
{
    let userData = useUserData();
    let directorData = useDirectorData();
    let [currentPage, setCurrentPage] = useState<number>(1);
    return (<ButcherBrowser
        embedded={props.embedded}
        onDoneBrowsing={() => null }
        userData={userData}
        directorData = {directorData}
        paginated = {false}
        isMobile={false}
        currentPageNumber={currentPage}
        setCurrentPageNumber={setCurrentPage}
    />)
}