import {useState} from "react";
import ButcherAPI from "../../BackendLayer/ButcherAPI";
import ButcherApi, {
    ButcherActivation,
    ButcherDetails,
    ButcherPresentation,
} from "../../BackendLayer/ButcherAPI";
import {useFetchAsync} from "../../CommonUtils/Fetch";
import ButcherLogic from "./ButcherBrowserLogic";
import {CreateWaitingTracker, WaitingTracker} from "../../CommonUtils/LongOp";
import useWebsockets, {GivOrTakOperationEvent, subscriptionSetter} from "../../BackendLayer/WebsocketsWrapper";
import ButcherStatus from "../../Constants/ButcherStatus";
import {DirectorDataHandlers, setDirectorStateSubscriptions} from "../../CommonUtils/DataFetchers/DirectorData";
import OrderOption from "../../Constants/OrderOptions";
import {StateSetter} from "../../CommonUtils/CommonTypes";
import {UserDataHandlers} from "../../Views/ButcherBrowseView/ButcherBrowseViewState";
import {UserInfo} from "../../BackendLayer/AuthAPI";
import {PricingTier} from "../../Constants/PricingCategories";

function modifyButcherState(butcherId: string | undefined,
                            changes: object,
                            butcherList: ButcherPresentation[] | null,
                            setButcherList: (newData: ButcherPresentation[]) => void){
    if (butcherList){
        const butcherIndex = butcherList.findIndex(butcher => butcher.id === butcherId);
        if (butcherIndex === -1){
            return;
        }

        butcherList[butcherIndex] = {...butcherList[butcherIndex], ...changes};
        setButcherList(butcherList.slice());
    }
}

export interface ButcherDataHandlers{
    loadingTracker: WaitingTracker,
    butchers: ButcherPresentation[] | null,
    setButchers: (newData: ButcherPresentation[]) => void
    searchFilter: string,
    setSearchFilter: StateSetter<string>,
    setSearchOrder: StateSetter<OrderOption>,
    modifyButcher: (butcherId: string | undefined, changes: object) => void,
    appendButcher: (newButcher: ButcherPresentation) => void,
    deleteButcher: (butcherId: string) => void,
    hotReload: () => void,
}

const nullData = null;
const butcherFetcher = () => ButcherAPI.GetAllButchers();
const fetchButcherErrorCode = "FetchButchersError"
export function useButcherData() : ButcherDataHandlers
{
    let {loading, data: butchers, setData: setButchers, fetchError, hotReload} = useFetchAsync(nullData, butcherFetcher, fetchButcherErrorCode);
    const loadingTracker = CreateWaitingTracker(loading, fetchError, "Loading butchers", false);
    let [searchFilter, setSearchFilter] = useState("");
    let [searchOrder, setSearchOrder] = useState(OrderOption.Default);
    const modifyButcher = (butcherId: string | undefined, changes: object) => {
        modifyButcherState(butcherId, changes, butchers, setButchers);
    }
    const appendButcher = (newButcher: ButcherPresentation) => {
        setButchers([...butchers ?? [], newButcher])
    }
    const deleteButcher = (butcherId: string) => {
        if (butchers){
            setButchers(butchers.filter(butcher => butcher.id !== butcherId));
        }
        else{
            console.error("Attempting to delete butcher with id " + butcherId + "but the butcher list is null.")
        }
    }

    butchers = ButcherLogic.filter(butchers, searchFilter);
    butchers = ButcherLogic.order(butchers, searchOrder);
    butchers = ButcherLogic.orderActiveFirst(butchers);
    return {loadingTracker,
        butchers,
        setButchers,
        searchFilter,
        setSearchFilter,
        setSearchOrder,
        modifyButcher,
        appendButcher,
        deleteButcher,
        hotReload
    };
}

export function useButcherWebsockets(butchersDataBundle: ButcherDataHandlers, userDataBundle: UserDataHandlers, directorStateDataBundle: DirectorDataHandlers){
    let {connection, createOrResetSubscription} = useWebsockets("HomykHub");
    if (connection){
        setButcherSubscriptions(createOrResetSubscription, butchersDataBundle.modifyButcher);
        setDirectorStateSubscriptions(createOrResetSubscription, directorStateDataBundle.setFullDirectorData)
        setUserDataSubscriptions(createOrResetSubscription, userDataBundle.currentUserData, userDataBundle.modifyTokenAmount)
        setMixedSubscriptions(createOrResetSubscription, butchersDataBundle.modifyButcher, userDataBundle.currentUserData, userDataBundle.modifyTokenAmount)
        setButcherCUDSubscriptions(createOrResetSubscription, butchersDataBundle.modifyButcher,
            butchersDataBundle.appendButcher, butchersDataBundle.deleteButcher);
    }
}

function setButcherSubscriptions(createOrResetSubscription: subscriptionSetter, modifyButcher: (butcherId: string, changes: object) => void){
    createOrResetSubscription("Deactivation", "ButcherListSync", (deactivatedButcherId: string) => modifyButcher(deactivatedButcherId, {status: ButcherStatus.Ready}));
}

function setButcherCUDSubscriptions(createOrResetSubscription: subscriptionSetter,
                                    modifyButcher: (butcherId: string, changes: object) => void,
                                    appendButcher:  (newButcher: ButcherPresentation) => void,
                                    deleteButcher:  (butcherId: string) => void)
{
    createOrResetSubscription("ButcherUpdate", "ButcherListSync", (updatedButcher: ButcherDetails) => {
        modifyButcher(updatedButcher.id, ButcherApi.Utils.CompleteImageUrls(updatedButcher))
    });
    createOrResetSubscription("NewButcher", "ButcherListSync", (newButcher: ButcherPresentation) => {
        appendButcher(ButcherApi.Utils.CompleteImageUrls(newButcher) as ButcherPresentation);
    })
    createOrResetSubscription("ButcherDeletion", "ButcherListSync", (butcherId) => {
        deleteButcher(butcherId);
    })
}

function setMixedSubscriptions(createOrResetSubscription: subscriptionSetter,
                               modifyButcher: (butcherId: string | undefined, changes: object) => void,
                               userData: UserInfo | null,
                               modifyTokenAmount: (type: PricingTier, amount: number) => void){
    const changeToken = (butcherActivation: ButcherActivation) => {
        if (!userData){
            console.error("Attempted to update token amount, but there's no userData");
            return;
        }

        if (userData.twitchUserId === butcherActivation.userTwitchId){
            switch (butcherActivation.butcher.pricingCategory) {
                case PricingTier.Base: // base
                    modifyTokenAmount(PricingTier.Base, -1);
                    break;
                case PricingTier.Brutal: // brutal
                    modifyTokenAmount(PricingTier.Brutal, -1);
                    break;
                default:
                    console.error("Unknown redemption tier: " + butcherActivation.butcher.pricingCategory)
            }
        }
    }

    createOrResetSubscription("NewActivation", "ButcherListSync", (butcherActivation: ButcherActivation) => {
        modifyButcher(butcherActivation.butcher.id, {status: ButcherStatus.Live})
        changeToken(butcherActivation)
    });

    createOrResetSubscription("Enqueued", "ButcherListSync", (butcherActivation: ButcherActivation) => {
        modifyButcher(butcherActivation.butcher.id, {status: ButcherStatus.Queued})
        changeToken(butcherActivation)
    });
}

function setUserDataSubscriptions(createOrResetSubscription: subscriptionSetter, userData: UserInfo | null, modifyTokenAmount: (type: PricingTier, amount: number) => void)
{
    createOrResetSubscription("Redemption", "ButcherListSync", (redemption) => {
        if (userData != null && userData.twitchUserId === redemption.twitchUserId){
            switch (redemption.pricingTier) {
                case PricingTier.Base: // base
                    modifyTokenAmount(PricingTier.Base, 1);
                    break;
                case PricingTier.Brutal: // brutal
                    modifyTokenAmount(PricingTier.Brutal, 1);
                    break;
                default:
                    console.error("Unknown redemption tier: " + redemption.tier)
            }
        }
    });
    createOrResetSubscription("GIVorTAK", "ButcherListSync", (event: GivOrTakOperationEvent) => {
        if (userData != null &&
            (userData.twitchUserId === event.giverTwitchId
            || userData.twitchUserId === event.recipientTwitchId))
        {
            window.location.reload();
        }
    });
}

