import React, {useState} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export function useFormField(fieldName: string, label: string, helpText: string, defaultValue: string, cssClass: string, multiline: boolean = false)
{
    let [value, setValue] = useState(defaultValue ?? "");
    const formElementId = fieldName + "_formControl";
    const formControl = (
        <FormControl fullWidth>
            <InputLabel htmlFor={formElementId}>{label}</InputLabel>
            <Tooltip title={helpText}>
                <Input className={cssClass} id={formElementId} value={value}
                       onChange={(event) => setValue(event.target.value)}
                       multiline={multiline}
                />
            </Tooltip>
        </FormControl>
    )
    return {formControl, value}
}

export function useFormSelect(fieldName: string, label: string, options: [any, string][], defaultValue: any | null, cssClass: string){
    const formElementId = fieldName + "_formControl";
    let [value, setValue] = useState(defaultValue);
    let selectOptions = options.map(item => <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>);
    const formControl = (
        <FormControl fullWidth>
            <InputLabel htmlFor={formElementId}>{label}</InputLabel>
            <Select className={cssClass} labelId={formElementId} value={value} onChange={(event: any) => setValue(event.target.value)}>
                {selectOptions}
            </Select>
        </FormControl>
    )
    return {formControl, value}
}