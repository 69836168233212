import React from "react"
import Grid from "@material-ui/core/Grid";
import {Input, MenuItem, Select} from "@material-ui/core";
import CheckUserExistence from "./CheckUserExistence";
import AccessTiers from "../../Constants/AccessTiers";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {UserPermissionConfigurationClientSide} from "../../BackendLayer/ConfigurationAPI";

function useSimpleFormField(name: any, value: any, setValue: any, className: any){
    const component = <Input inputProps={{align: "center"}} id={`input_${name}`} value={value} className={className} onChange={event => setValue(event.target.value)}/>
    return {
        component,
        value
    }
}

function usePermissionSelectField(name: any, value: any, setValue: any, className: any){
    if (value === AccessTiers.Public){
        value = AccessTiers.Viewer;
    }
    const permissionValues = [[AccessTiers.Viewer, "Viewer"], [AccessTiers.Mod, "Mod"], [AccessTiers.Broadcaster, "Broadcaster"],  [AccessTiers.Developer, "Developer"], [AccessTiers.Banned, "Banned"]];
    const items = permissionValues.map(item => <MenuItem key={"accesTierItem_" + item[1]} value={item[0]}>{item[1]}</MenuItem>);
    const component = <Select className={className} value={value} onChange={(event) => setValue(event.target.value)}>
        {items}
    </Select>
    return {
        component,
        value
    }
}

export interface UserPermissionConfigRowProps extends UserPermissionConfigurationClientSide{
    alreadyInDatabase: boolean,
    labelClassName: string,
    changeProperties: (newProps: object) => void,
    existenceVerified: boolean,
    removeUserRow: (id: string) => void
}

export default function UserPermissionConfigRow(props: any){

    const input = useSimpleFormField("userName_" + props.id, props.userName, (value: any) => props.changeProperties({userName: value, userTwitchId: null, verified: false}), props.labelClassName);
    const accessTierSelector = usePermissionSelectField("accessTier", props.accessTier ?? AccessTiers.Viewer, (value: any) => props.changeProperties({accessTier: value}), props.labelClassName);
    return(
        <Grid container justify={"center"}>
            <Grid item xs={4}>
                {props.alreadyInDatabase ?
                    <Typography variant={"body2"}>{props.userName}</Typography>
                    : input.component
                }
            </Grid>
            <Grid item xs ={4}>
                {accessTierSelector.component}
            </Grid>
            <Grid item xs={3}>
                {<CheckUserExistence {...props} userName={input.value}
                    // existenceVerified={props.existenceVerified}/>
                />}
            </Grid>
            <Grid item xs={1}>
                <Button variant={"outlined"} ><RemoveCircleOutlineIcon onClick={() => props.removeUserRow(props.id)}/></Button>
            </Grid>

        </Grid>
    )
}