import React, {useState} from "react"
import Button from "@material-ui/core/Button";
import AuthAPI from "../../BackendLayer/AuthAPI";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const CheckState={
    NotChecked: 0,
    InProgress: 1,
    Success: 2,
    NotFound: 3,
    UnexpectedError: 4,
    EmptyUserName: 5
}

const useStyles = makeStyles((theme) => ({
    twitchId: {
        fontSize:  "1.4em",
    },
    errorButton: {
        backgroundColor: "red"
    }
}));

export interface CheckUserExistenceProps {
    userName: string,
    verified: boolean,
    changeProperties: (newProps: object) => any,
    userTwitchId: string | null,

}

export default function CheckUserExistence(props: CheckUserExistenceProps){
    const classes = useStyles();
    let [userName, setUsername] = useState(props.userName);

    let [checkState, setCheckState] = useState(props.verified ? 2 : 0);
    if (userName !== props.userName){
        setUsername(props.userName);
        setCheckState(CheckState.NotChecked);
    }

    const checkUser = () => {
        if (!userName || userName===""){
            setCheckState(CheckState.EmptyUserName)
            return;
        }
        setCheckState(CheckState.InProgress);
        AuthAPI.GetTwitchUserId(props.userName)
            .then((result) => {
                if (result.data){
                    props.changeProperties({userTwitchId: "" + result.data, verified: true});
                    setCheckState(CheckState.Success);
                }
                else{
                    setCheckState(CheckState.NotFound)
                    props.changeProperties({verified: false});
                }
            })
            .catch((error) =>
            {
                setCheckState(CheckState.UnexpectedError)
                props.changeProperties({verified: false});
            })
    }

    return (<React.Fragment>
        {checkState === CheckState.NotChecked &&
        <Button color = "primary" variant={"outlined"} onClick={checkUser}>Check user</Button>
        }
        {checkState === CheckState.InProgress &&
        <Button color = "primary" variant={"outlined"}>Checking...</Button>
        }
        {checkState === CheckState.Success &&
        <Typography className={classes.twitchId} variant={"body2"}>{props.userTwitchId}</Typography>
        }
        {checkState === CheckState.NotFound &&
        <Button className={classes.errorButton} variant={"outlined"}>No user found!</Button>}
        {checkState === CheckState.UnexpectedError &&
        <Button className={classes.errorButton} variant={"outlined"}>Something went wrong</Button>
        }
        {checkState === CheckState.EmptyUserName &&
        <Button className={classes.errorButton} variant={"outlined"} onClick={checkUser}>Write the name you doofus</Button>
        }
    </React.Fragment>)
}