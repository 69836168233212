
// Probably should rethink this name
import {AxiosError} from "axios";

type HomykError<T> = string | AxiosError<T> | null

export interface FormattedError {
    message: string,
    responseStatusCode: number | null,
    errorCode?: string,
}
export function GetErrorMessage(receivedError: HomykError<any>, errorCode: string | null) : FormattedError{
    let errorMessage: string
    let statusCode: number | null = null;
    if (receivedError) {
        if (typeof receivedError === "string") // the error is a plain string, not an Error object
        {
            errorMessage = receivedError;
        }
        else {
            if (receivedError.response){
                statusCode = receivedError.response.status
            }

            if (!receivedError.message) // empty encapsulated error
            {
                errorMessage = errorCode ?? "NonSpecific_Error";
            }
            else { // non empty encapsulated error
                errorMessage = receivedError.message;
            }
        }
    }
    else { //no error object received
        errorMessage = errorCode ?? "NonSpecific_Fuckery";
    }

    const formattedError: FormattedError = {message: errorMessage, responseStatusCode: statusCode}

    return formattedError;
}