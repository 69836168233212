import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import React, {useState} from "react";
import {fade, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stopSearchingIcon: {
        margin: "0 4px",
        height: '100%',
        // display: 'flex',
        position: 'absolute',
        zIndex: 34,
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    }
}));

export interface ButcherBrowserSearchProps{
    onChange: (event: any) => void,
    onSearchingToggle: (newValue: boolean) => void,
    searchFilter: string,
    isMobile: boolean | undefined,
}
export default function ButcherBrowserSearch(props: ButcherBrowserSearchProps){
    const classes = useStyles();
    let [searching, setSearching] = useState<boolean>();
    function changeSearchingState(value: boolean){
        setSearching(value);
        props.onSearchingToggle(value);
    }
    return(<div>
        <div className={classes.search}>
            {props.isMobile && searching
                ? <CloseIcon className={classes.stopSearchingIcon} onClick={() => changeSearchingState(false)}/>
                : <div className={classes.searchIcon}>
                    <SearchIcon onClick={() => changeSearchingState(true)}/>
                  </div>
                }
            {(!props.isMobile || searching) &&
                <InputBase
                    placeholder="Search…"
                    value={props.searchFilter}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    onChange={(event) => props.onChange(event)}
                />}

        </div>
    </div>)
}