import React from "react"

export class ImportPopUpComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            importText: "",
            error: null
        }
    }

    importConfig(){
        if(this.state.importText === ""){
            this.setState({error: "Uh... I think you forgot to paste it"});
            return;
        }
        try{
            let config = JSON.parse(this.state.importText);
            this.props.onImport(config);
            this.props.onClose(config);
        }
        catch(e){
            this.setState({error: "Can't parse the import info, something is wrong with it"});
        }
    }


    render(){
        return(
            <div className="popUpComponent">
                <p className={"popUpPrelude"}>Copy here the text you got when exporting:</p>
                <p className={"error"}>{this.state.error !== null ? this.state.error : ""}</p>
                <div>
                    <textarea className="importPopUpTextArea" name={"importText"} cols="100" rows={"20"}
                              value={this.state.importText} onChange={(event) => this.setState({importText: event.target.value})}/>
                </div>
                <input type={"button"} value="Import!" onClick={this.importConfig.bind(this)}/>
                <input type={"button"} value={"Actually, nevermind"} onClick={() => this.props.onClose()}/>
            </div>
        )
    }
}