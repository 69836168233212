import React from "react"
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import {ButcherDetails} from "../../BackendLayer/ButcherAPI";

const avatarHeight: any = "60"
const smallAvatarHeight: any = "40"
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "hidden",
    },
    avatar: {
        width: avatarHeight + "px",
        height: avatarHeight + "px",
        margin: "auto",
        [theme.breakpoints.down("md")]:{
            width: smallAvatarHeight + "px",
            height: smallAvatarHeight + "px",
        },
    },
    compactAvatar: {
        width: avatarHeight/2 + "px",
        height: avatarHeight/2 + "px",
        [theme.breakpoints.down("md")]:{
            width: smallAvatarHeight/2 + "px",
            height: smallAvatarHeight/2 + "px",
        },
    },
    textGridSection: {
      position: "relative",
    },
    textContainer: {
        position: "absolute",
        top: "50%",
        transform: "translate(0%, -50%)",
    },
    butcherName: {
        color: theme.palette.primary.light,
        fontWeight: "bold",
        fontSize: "1.1em",
        [theme.breakpoints.down("md")]:{
            fontSize: "0.9em"
        },
    },
    butcherTagLine: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down("md")]:{
            fontSize: "0.8em"
        },
    },
    gridRow:{

    }
}))

export interface DetailedButcherRowProps {
    compact : boolean,
    data: ButcherDetails
}

export default function DetailedButcherRow(props: any)
{
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item lg={props.compact ? 2 : 3} xs={props.compact ? 1 : 2} className={classes.gridRow}>
                <Avatar alt={"this would be the brawler's name"}
                    src={props.data.thumbnailRoute} className={props.compact ? classes.compactAvatar : classes.avatar}/>
                </Grid>
                <Grid item xs={props.compact ?  11 : 10} lg={props.compact ? 10 : 9} className={classes.textGridSection}>
                    <div className={classes.textContainer}>
                        <Box  className={classes.butcherName}>{props.data.name}</Box>
                        {!props.compact && <Box className={classes.butcherTagLine}>{props.data.tagline}</Box>}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}