import React, {useRef, useState} from "react"
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import WaitingContainer from "../../../Wrappers/WaitingContainer";
import useWebsockets from "../../../BackendLayer/WebsocketsWrapper";
import HalovemberTimer from "./HalovemberTimer";
import AudioSources from "../../../audio/AudioSources";
import {playSound} from "../../../CommonUtils/audio";


const useStyles = makeStyles((theme) => ({
    timerRow: {
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "white",
        textAlign: "center"
    },
    alertName: {
      color: "#d5d5ff"
    },
    timer: {
        color: "white",
    }
}));

export enum CharityIncentiveType {
    BunnyHop,
    CrabRave,
    Moonwalk,
    FootFetish,
    ArmorLock,
    Pacifist,
    MoshPit,
    Rambo,
    DropIt,
    GrenadeOut,
    BailOut,
    SwitchUp,
    BackToBasics,
    FlashBang,
    TheWheel,
    TheHammer,
    FistOfRukt,
    MementoMori,
    DizzyGoggles
}

export interface CharityIncentiveAlert {
    donorDisplayName: string,
    formattedAmount: string,
    incentiveType: CharityIncentiveType,
    incentiveDisplayName: string,
    receivedAt: Date,
    duration: number,
    startImmediately: boolean,
}

function useIncentiveAlerts(changeAlert: (alert: CharityIncentiveAlert) => void){
    let {connection, createOrResetSubscription} = useWebsockets("HomykHub");
    if (connection){
        createOrResetSubscription(
            "DonationIncentiveAlert",
            "HalovemberTimers",
            (incentiveAlert: CharityIncentiveAlert) => {console.log("Received alert", incentiveAlert); changeAlert(incentiveAlert)});
    }
}

export interface HalovemberTimerData {
    alertName: string,
    alertDisplayName: string,
    duration: number,
    startTime: Date,
}

function AddTimerFromAlert (newAlert: CharityIncentiveAlert, setTimers: any){
    console.log("New alert added", newAlert);
    if (! newAlert) { return; }

    if (!newAlert.startImmediately) { return; }
    if (newAlert.duration === 0)
    {
        console.log("Alert received has no duration, no timer shown");
        return;
    }

    const newTimer : HalovemberTimerData = {
        alertName: newAlert.incentiveDisplayName,
        alertDisplayName: newAlert.incentiveDisplayName + " (from " + newAlert.donorDisplayName + ")",
        duration: newAlert.duration,
        startTime: newAlert.receivedAt
    };

    setTimers((oldTimers: HalovemberTimerData[]) =>
        ([...oldTimers
            .filter(timer => timer.alertName !== newTimer.alertName), newTimer])
            .sort((a, b) => b.duration - a.duration)
    );
}
function RemoveTimer (alertName: string, setTimers: any){
    setTimers((timers : HalovemberTimerData[]) => timers.filter((t: HalovemberTimerData) => t.alertName !== alertName))
}

export default function OverlayTimersView(props: any) {
    const classes = useStyles();
    let [timers, setTimers] = useState<HalovemberTimerData[]>([]);
    let boxingBellAudioRef = useRef();

    function timerEnd(alertName: string, setTimers: any){
        if (alertName.toLowerCase().includes("flash")){
            playSound(boxingBellAudioRef.current, 0.8);
        }

        RemoveTimer(alertName, setTimers);
    }

    useIncentiveAlerts((alert: CharityIncentiveAlert) => AddTimerFromAlert(alert, setTimers));

    return (
        <WaitingContainer waitingTrackers={[]} nonDisruptive>
            {
                timers.map(timer =>
                    <div key={timer.alertName} className={classes.timerRow}>
                        <Typography className={classes.alertName} variant={"button"}>{timer.alertDisplayName}</Typography>
                        <HalovemberTimer className={classes.timer}  duration ={timer.duration * 1000} timerStartTime={timer.startTime}
                               onEnd = {() => timerEnd(timer.alertName, setTimers)}
                        />
                    </div>
                )
            }
            {/*@ts-ignore ref*/}
            <audio ref={boxingBellAudioRef} src={"../../" + AudioSources.BoxingBell}></audio>
        </WaitingContainer>
    )
}