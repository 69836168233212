import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import React from "react";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "8px",
    },
    preview: {
        height: "50vh",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "80vw",
            height: "auto"
        },
        maxHeight: "65vh"
    },
    paper: {
        display: "inline-block",
        margin: "16px",
        padding: "8px"
    },
}));
export default function CropResult(props){
    const classes = useStyles();

    return (
        <Box align={"center"} >
            <Card className={classes.paper}>
            <div >
                <img src={props.croppedImageSrc} alt="Crop preview" className={classes.preview}/>
            </div>
            <div>
                <Button className={classes.button} variant="contained" color={"primary"} onClick={props.acceptCrop}>Looks good</Button>
                <Button className={classes.button} variant="contained" onClick={props.cancelCrop}>Go back</Button>
            </div>
            </Card>
        </Box>
    )
}