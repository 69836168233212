import WaitingContainer from "../../Wrappers/WaitingContainer";
import React from "react";
import {useUserData} from "../../Views/ButcherBrowseView/ButcherBrowseViewState";
import PermissionUtils from "../../CommonUtils/PermissionUtils";
import {UserInfo} from "../../BackendLayer/AuthAPI";
import HaloSeedView from "../HaloSeedView";

function GetWorthinessMessage(currentUserData: UserInfo | null) : string
{
    if (!currentUserData){
        return "Only the worthy shall summon the wheel. Who art thou, unlogged one?"
    }
    if (!PermissionUtils.IsBroadcaster((currentUserData))){
        return "Only the worthy shall summon the wheel. Thou are not."
    }

    return "The hero reads a most unsettling error message.";
}

export default function WorthinessVerifier(props: any)
{
    let userDataHandlers = useUserData();

    return (
        <WaitingContainer waitingTrackers={[userDataHandlers.loadingTracker]}>
            {PermissionUtils.IsBroadcaster(userDataHandlers.currentUserData)
                ? props.children
                : GetWorthinessMessage(userDataHandlers.currentUserData)}
            {(props.showHaloSeed && PermissionUtils.IsDeveloper(userDataHandlers.currentUserData)) && <HaloSeedView/>}
        </WaitingContainer>
    )
}