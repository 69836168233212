import {GeneralAlertMessage, useGeneralAlertWebsockets} from "./GeneralAlertLogic";
import {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "../fonts/metalFont.css"
import MediaOrchestrator from "./MediaOrchestrator";
import CreateAlert from "./CreateAlert";
import GenerateAlertText from "./GenerateAlertText";

export interface ObsAlertBoxProps{
}

export interface ObsGeneralAlertBoxProps {
}

const useStyles = makeStyles((theme) => ({
    animatedText: {
        position: "absolute",
        top: "15px",
        margin: "16vh 0px",
    },
    textWithGradient: {
        webkitBackgroundClip: "text",
        webkitTextFillColor: "transparent",
    }
}))

export interface Alert{
    image?: string,
    sound?: string,
    video?: string,
    message: string,
    duration?: number,
}

export default function GeneralAlertBox(props: ObsGeneralAlertBoxProps){
    let [currentAlert, setCurrentAlert] = useState<Alert | null>(null);
    let [alertQueue, setAlertQueue] = useState<Alert[]>([]);
    let [isDisconnected, setIsDisconnected] = useState(false);

    function playAlert() {

    }

    function endAlert(){
        setCurrentAlert(null);
        if (alertQueue.length > 0)
        {
            setCurrentAlert(alertQueue[0]);
            alertQueue.shift();
        }
    }

    function onAlert(message: GeneralAlertMessage)
    {
        let alert = CreateAlert(message);

        if (alert == null){
            return;
        }

        if (!currentAlert)
        {
            setCurrentAlert(alert);
        }
        else {
            alertQueue.push(alert);
        }

        playAlert();
    }

    useGeneralAlertWebsockets(onAlert,() => setIsDisconnected(true));
    let message = GenerateAlertText(currentAlert?.message);
    return (<div style={{textAlign: "center"}}>
        {currentAlert &&
            <MediaOrchestrator currentAlert={currentAlert} imageUrl={currentAlert?.image}
                               videoUrl={currentAlert?.video}
                               audioUrl={currentAlert?.sound}
                               imageDuration={currentAlert?.duration}
                               messageComponent={message}
                               onAlertEnd={() => endAlert()}
            />
        }
    </div>)
}