const ThumbnailSize = {
    x: 200,
    y: 200
}

const CropMaxSize = {
    x: 512,
    y: 512
}

const OriginalMaxSize = { // more than 4k is WAY overkill.
    x: 3840,
    y: 2160
}

export const ImageConfig = {
    ThumbnailSize,
    CropMaxSize,
    OriginalMaxSize
}