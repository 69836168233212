import React from "react"
import {SketchPicker} from "react-color";

export class ColorPicker extends React.Component{
    constructor(props){
        super(props)
        this.state={
            showColorDialog: false,
        }
    }

    toggleColorDialog(){
        this.setState((state, props) => ({showColorDialog: !state.showColorDialog}));
    }

    render(){
        return (
            <span>
                <span className="optionColorColoredSquare" style={{background: this.props.color}} onClick={this.toggleColorDialog.bind(this)}></span>
            {this.state.showColorDialog
                ? (<SketchPicker className="inlinePopUp" color={this.props.color}
                                 onChange={(color) => this.props.onChange(this.props.colorPropName, color.hex)}/>)
                : ""}
            </span>
            )
    }
}