import React, {useState} from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Paper, Tooltip} from "@material-ui/core";
import StatusCard from "./StatusCard";
import Timer from "./Timer";
import DetailedButcherRow from "./DetailedButcherRow";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import ButcherDetailsComponent from "../../Components/ButcherDetailsComponent";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import ButcherListDescriptor from "./ButcherListDescriptor";
import HomyksChop from "../../images/smolchoppy.png"
import HomyksChopReversed from "../../images/smolchoppyFlipped.png"
import {ButcherActivation, DirectorDataPresentation} from "../../BackendLayer/ButcherAPI";
import DirectorState from "../../Constants/DirectorState";
import MoshPitLogoFull from "../../images/moshpitlogosmolcropped.png";
import {Refresh} from "@material-ui/icons";
const panelWidth = 300;
const mildOverload = 3;
const wayTooManyActivations = 5;
const useStyles = makeStyles((theme) => ({
    hudPanel : {
        width: panelWidth + "px",
        //height: "360px",
        maxHeight: "85%",
        overflowY: "hidden",
        position: "relative",
        align: "center",
    },
    statusCard: {
        //backgroundColor: theme.palette.primary.main,
        textAlign: "center",
        fontSize: "1.5em",
        [theme.breakpoints.down("md")]:{
            fontSize: "1.8em",
        },
        [theme.breakpoints.down("sm")]:{
            fontSize: "1.3em"
        },
        margin: "8px",
    },
    collapseButton: {
      position: "absolute",
      top: 0,
    },
    backgroundImage: {
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: -1,
    },
    timerHole: {
//        width: "160px",
        position: "relative",
        display: "block",
        left: "50%",
        top: "0",
        transform: "translateX(-50%)",
        //backgroundColor: theme.palette.secondary.dark,
        textAlign: "center",
        color: theme.palette.primary.light,
        //padding: "4px",
    },
    timer: {
        fontSize: "2em",
        [theme.breakpoints.down("md")]:{
            fontSize: "1.5em",
        },
        [theme.breakpoints.down("sm")]:{
            fontSize: "1em"
        }
    },
    butcherListDescriptor: {
        fontSize: "1em",
        [theme.breakpoints.down("md")]:{
            fontSize: "0.8em",
        },
        [theme.breakpoints.down("sm")]:{
            fontSize: "0.7em"
        }
    },
    titleDiv: {
        width: "284px",
        [theme.breakpoints.down("md")]:{
            width: "200px"
        },
        margin: "auto",
    },
    titleImage: {
        display: "block",
        margin: "auto auto",
        width: "40%",

    },
    butcherRow: {
        padding: "0 0 16px 8px",
        [theme.breakpoints.down("md")]:{
            padding: "0 0 8px 8px",
        },
        [theme.breakpoints.down("sm")]:{
            padding: "0 0 4px 8px",
        },
    },
    butcherRowUltraCompact: {
        padding: "0"
    },
    browseButton: {
        display: "block",
        width: "70%",
        [theme.breakpoints.down("sm")]:{
            fontSize: "0.8em",
            padding: 0,
            width: "60%",
        },
        margin: "0 auto 8px auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    },
    dismissReminderButton: {
        width: "55%",
        [theme.breakpoints.down("sm")]:{
            fontSize: "0.6em",
            padding: 0,
            width: "40%",
        },
        margin: "0 auto 8px auto",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: "0.8em"
    },
    buttonContainer: {
        margin: "8px 0 8px 0",
        textAlign: "center",
        [theme.breakpoints.down("sm")]:{
            margin: "4px 0 4px 0",

        }
    },
    hoverPopover: {
        pointerEvents: 'none',
    },
    hoverPopoverPaper: {
        width: "50vw",
        // border: "2px solid " + theme.palette.primary.main,
        // boxShadow: "0 0 10px 10px " + theme.palette.primary.main,
        [theme.breakpoints.down("md")]:{
            width: "80vw"
        },
        [theme.breakpoints.down("sm")]:{
            width: "90vw"
        },
        [theme.breakpoints.down("xs")]:{
            width: "100vw"
        }

    },
    youHaveTokensReminder: {
        [theme.breakpoints.down("sm")]:{
            fontSize: "0.8em"
        }
    },
    youHaveTokensChop:{
        width: "16px",
        height: "16px",
        [theme.breakpoints.down("sm")]:{
            width: "10px",
            height: "10px",
        }
    },
    closeIcon:{
        color: "#aaa",
    },
    refreshIcon:{
        color: "#888",
        position: "relative",
        left: "240px"
    },
    refreshIconMobile:{
        color: "#888",
        position: "relative",
        left: "0"
    }
}))

export interface ExtensionViewProps{
    isMobile?: boolean,
    butcherActivationData: ButcherActivation[] | null,
    collapse: () => void,
    directorData: DirectorDataPresentation | null,
    hasTokens: () => boolean,
    tokenReminderDismissButtonActive: () => boolean,
    startBrowsing: () => void
}

export default function ExtensionView(props: any)
{
    const classes = useStyles();
    function needUltraCompactMode(){
        return (props.butcherActivationData?.length ?? 0) > wayTooManyActivations;
    }
    const [hoveredButcher, setHoveredButcher] = useState<any>(null);
    const [detailsAnchorEl, setDetailsAnchorEl] = useState<any>(null);
    function butcherHover(event: any, butcher: any){//ButcherDetails | undefined){
        setHoveredButcher(butcher);
        setDetailsAnchorEl(event.currentTarget);

    }
    function butcherEndHover(){
        setHoveredButcher(null);
        setDetailsAnchorEl(null);
    }

    const directorState = props.directorData?.state ?? DirectorState.Ready;
    const activationsListLength = props.butcherActivationData?.length ?? 0;

    return (
        <div>
            <Paper elevation={6} className={classes.hudPanel} style={props.isMobile ? {width: "100%"} : {}}>
                {!props.isMobile ?
                    <div className={classes.collapseButton}>
                    <Tooltip title={"Minimize"}><IconButton size="small" onClick={props.collapse} className={classes.closeIcon}><CloseIcon/></IconButton></Tooltip>
                        <Tooltip title={"Refresh the extension"}><IconButton size="small" onClick={() => window.location.reload()} className={classes.refreshIcon}><Refresh/></IconButton></Tooltip>
                </div>
                :<div className={classes.collapseButton}>
                        <Tooltip title={"Refresh the extension"}><IconButton size="small" onClick={() => window.location.reload()} className={classes.refreshIconMobile}><Refresh/></IconButton></Tooltip>
                </div>}
                {!needUltraCompactMode() &&
                    <div className={classes.titleDiv}>
                        <img alt="The Vocal Butcher's Mosh Pit Logo" className={classes.titleImage} src={MoshPitLogoFull}/>
                    </div>
                }
                <StatusCard className={classes.statusCard} directorState={directorState}/>
                <div style={{position: "relative"}}>
                    <div className={classes.timerHole}>
                        <Timer className={classes.timer} {...props.directorData} message={""}/>
                    </div>
                </div>
                {!needUltraCompactMode() &&
                    <ButcherListDescriptor className={classes.butcherListDescriptor} state={directorState} activationCount={props.butcherActivationData?.length ?? 0}/>
                }
                {props.butcherActivationData?.map((activation: ButcherActivation) => (
                    <div key={activation.id} className={activationsListLength > wayTooManyActivations ? classes.butcherRowUltraCompact : classes.butcherRow}
                         aria-owns={!!hoveredButcher ? 'butcher-details-popover' : undefined}
                         aria-haspopup="true"
                         onMouseOver={(event) => butcherHover(event, activation.butcher)}
                         onMouseLeave={(event) => butcherEndHover()}
                         >
                        <DetailedButcherRow data={activation.butcher}
                                            compact={activationsListLength > mildOverload}
                                            ultraCompact={needUltraCompactMode()}
                        />
                    </div>
                ))}
                <div className={classes.buttonContainer}>
                    {props.hasTokens() &&
                        <div className={classes.youHaveTokensReminder}>
                            <img className={classes.youHaveTokensChop} alt="" src={HomyksChopReversed}/>
                            <span> YOU HAVE COINS TO USE </span>
                            <img className={classes.youHaveTokensChop}  alt="" src={HomyksChop}/>
                        </div>
                    }
                    <Button className={classes.browseButton} variant={"contained"} onClick={props.startBrowsing}>
                        Bring a brawler
                    </Button>
                    {props.tokenReminderDismissButtonActive() &&
                        <div>
                            <Button className={classes.dismissReminderButton} variant={"contained"} size="small" color="secondary" onClick={props.dismissTokenReminder}>I'll use them later</Button>
                        </div>
                    }
                </div>
            </Paper>
            <Popover
                id={"butcher-details-popover"}
                open={!!hoveredButcher}
                anchorEl={detailsAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={butcherEndHover}
                disableRestoreFocus
                className={classes.hoverPopover}
                PaperProps={{className: classes.hoverPopoverPaper}}
            >
                <ButcherDetailsComponent noControls {...hoveredButcher}/>
            </Popover>
        </div>
    )
}