import React from "react"

export class WinnerPopUpComponent extends React.Component{
    render(){
        return(
            <div className="popUpComponent" style={{zIndex: 3, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                "backgroundColor": this.props.winnerBackgroundColor, "borderColor": this.props.winnerBorderColor}}>
                <p className={"popUpPrelude"} style={{color: this.props.winnerTextColor}}>{this.props.winnerText}</p>
                <p className={"popUpWinner"} style={{color: this.props.winnerColor}}>{this.props.winner}</p>
                <input type={"button"} value={"Ok!"} onClick={this.props.dismissWinnerPopUp}/>
            </div>)
    }
}