import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Link from "@material-ui/core/Link";
import AuthApi, {UserInfo} from "../../BackendLayer/AuthAPI";
import PermissionUtils from "../../CommonUtils/PermissionUtils";

export interface UserMenuProps{
    anchorEl: any,
    userData: UserInfo | null,
    open: boolean,
    handleClose: () => void,
    embedded: boolean,
}
export default function UserMenu(props: UserMenuProps) {
    return(
        <Menu
            anchorEl={props.anchorEl}
            id="user-menu-appbar"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open= {props.open}
            onClose={() => props.handleClose()}
        >
            {!props.embedded && PermissionUtils.IsMod(props.userData) &&
            <Link color={"inherit"} href={"/admin/logs"}><MenuItem>Logs</MenuItem></Link>
            }
            {!props.embedded && PermissionUtils.IsBroadcaster(props.userData) &&
                <Link color="inherit" href={"/admin/controlpanel"}><MenuItem >Control panel</MenuItem></Link>
            }
            {!props.embedded && PermissionUtils.IsMod(props.userData) &&
            <Link color="inherit" href={"/admin/userAccounts"}><MenuItem >Manage user accounts</MenuItem></Link>
            }
            {!props.embedded && PermissionUtils.IsMod(props.userData) &&
            <Link color="inherit" href={"/create"}><MenuItem >Create a new brawler</MenuItem></Link>
            }
            {!props.embedded && PermissionUtils.IsBroadcaster(props.userData) &&
            <Link color="inherit" href={"/config"}><MenuItem >Configuration</MenuItem></Link>
            }

            {!props.embedded && PermissionUtils.IsBroadcaster(props.userData) &&
            <Link color="inherit" href={"/wheelchan-admin"}><MenuItem >Wheel Chan</MenuItem></Link>
            }

            {!props.embedded && PermissionUtils.IsBroadcaster(props.userData) &&
            <Link color="inherit" href={"/halovemberTimersTriggerer"}><MenuItem >Halovember Timers Manual Triggering</MenuItem></Link>
            }
            {!props.embedded && PermissionUtils.IsBroadcaster(props.userData) &&
            <Link color="inherit" href={"/obsComponentsIndex"}><MenuItem >OBS Components</MenuItem></Link>
            }

            <MenuItem onClick={() => AuthApi.Logout().then(() => window.location.reload())}>Logout</MenuItem>
        </Menu>)
}
