import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import ButcherStatus from "../Constants/ButcherStatus";
import {PricingTier} from "../Constants/PricingCategories";
import {ButcherPresentation} from "../BackendLayer/ButcherAPI";

export interface ButcherCardProps extends ButcherPresentation
{
    cardClass: string,
    width: number | null,
}

export default function ButcherCard(props: ButcherCardProps){
    const useStyles = makeStyles((theme) => ({
        title: {
            margin: '0 0px',
            padding: '0 0px'
        },
        titleText:{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: "1em",
            [theme.breakpoints.down("md")]: {
                fontSize: "0.7em"
            }

        },
        media: {
            height: 0,
            paddingTop: '100%', // 16:9,
            position: "relative"
        },
        pricingTag: {
            margin: "8px",
            position: "absolute",
            right: 0,
            bottom: 0,
        },
        liveTag: {
            backgroundColor: theme.palette.error.main,
            padding: "0 2px",
            position: "absolute",
            left: 0,
            top: 0,
        },
        queuedTag: {
            backgroundColor: theme.palette.secondary.main,
            padding: "0 2px",
            position: "absolute",
            left: 0,
            top: 0,
        }
    }));
    const classes = useStyles();
    return(
        <div>
            <Card className={props.cardClass} style={props.width ? {width: props.width} : {}}>
                <CardContent className={classes.title}>
                    <Typography variant={"button"} component="h5" align="center" color={"textPrimary"} className={classes.titleText}>
                        {props.name}
                    </Typography>
                </CardContent>
                <CardMedia component='div' image={props.thumbnailRoute} className={classes.media}>
                    {props.pricingCategory === PricingTier.Brutal &&
                    <Tooltip title={"Brütal brawlers are specially hard to perform, require extra props or are just very hard on TheVocalButcher's voice, so they're priced higher"}>
                        <div className={classes.pricingTag}><Chip size="small" label={"Brütal"} color={"primary"}/></div>
                    </Tooltip>}
                    {props.status === ButcherStatus.Live &&
                        <div className={classes.liveTag}><Typography component={"h6"}>LIVE</Typography></div>
                    }
                    {props.status === ButcherStatus.Queued &&
                        <div className={classes.queuedTag}><Typography component={"h6"}>Queued</Typography></div>
                    }
                </CardMedia>

            </Card>
        </div>
    )
}