import Dialog from "@material-ui/core/Dialog";
import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {LoginPage} from "../Main/loginPage";
import {ForbiddenPage} from "../Main/forbiddenPage";
import {FormattedError} from "../CommonUtils/Error";

function forceReload(){
    window.location.reload();
}

function showGenericError(error: FormattedError){
    return !(error.responseStatusCode === 401 || error.responseStatusCode === 403);
}

export interface ErrorDialogProps {
    error: FormattedError,
    open: boolean,
}

export default function ErrorDialog(props: ErrorDialogProps){
    return(
        <Dialog open={props.open}>
           <DialogTitle>Error</DialogTitle>
            <DialogContent>
                {props.error.responseStatusCode === 401 && <LoginPage/>}
                {props.error.responseStatusCode === 403 && <ForbiddenPage/>}
                {showGenericError(props.error) &&
                <React.Fragment>
                    <DialogContentText>
                        Something went wrong. Try reloading. If it persists, give the admin this code: {props.error.errorCode}
                    </DialogContentText>
                    <DialogActions>
                    <Button onClick={() => forceReload()}>
                    Reload and hope for the best
                    </Button>
                    </DialogActions>
                </React.Fragment>
                }
           </DialogContent>
        </Dialog>
    )
}