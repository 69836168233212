import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import ButcherDetailsComponent from "../ButcherDetailsComponent";
import {useTrail, animated} from "react-spring";
import DirectorState from "../../Constants/DirectorState";
import {Butcher, ButcherActivation} from "../../BackendLayer/ButcherAPI";

const mainOpacity = 1;
const useStyles = makeStyles((theme) => ({
    sideBar: {
        margin: "auto",
    },
    sideBarButcherList : {
        flexWrap: "wrap",
        maxHeight: "80vh",
        margin: "8px 0px",
    },
    sidebarButcherThumbnail: {
        opacity: mainOpacity,
        '&:hover': {
            opacity: 1
        },
        width: theme.spacing(8),
        height: theme.spacing(8)
    },
    butcherBannerActive: {
        backgroundColor: "#ff0000"
    },
    butcherBannerQueued: {
        backgroundColor: "#666666"
    },
    hoverPopover: {
        pointerEvents: 'none',
    },
    hoverPopoverPaper: {
        width: "50vw",
        // border: "2px solid " + theme.palette.primary.main,
        [theme.breakpoints.down("md")]:{
            width: "80vw"
        },
        [theme.breakpoints.down("sm")]:{
            width: "100vw"
        }
    },
    timer: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: "8px",
        }
    }
}))

export interface ActiveButcherListProps {
    activationData: ButcherActivation[] | null,
    directorState: DirectorState | null
}
const AnimatedGrid = animated(Grid);
export default function ActiveButcherList(props: ActiveButcherListProps){
    const classes = useStyles();
    const [hoveredButcher, setHoveredButcher] = useState<Butcher | null>(null);
    const [detailsAnchorEl, setDetailsAnchorEl] = useState<any>(null);
    function butcherHover(event: any, butcher: Butcher){
        setHoveredButcher(butcher);
        setDetailsAnchorEl(event.currentTarget);
    }
    const activationData = props.activationData;
    const directorState = props.directorState;
    function butcherEndHover(){
        setHoveredButcher(null);
        setDetailsAnchorEl(null);
    }
    let trail = useTrail((activationData?.length ?? 0), {left: "0px", config: {tension: 300}});
    let butcherBanner: any = "";
    if (directorState === DirectorState.Active || directorState === DirectorState.Paused){
        butcherBanner = (<Typography align="center" className={classes.butcherBannerActive}>Live butchers</Typography>);
    }
    else if (directorState === DirectorState.OnABreak){
        butcherBanner = (<Typography align="center" className={classes.butcherBannerQueued}>Queued butchers, biding their time</Typography>)
    }

    return (
        <React.Fragment>
            <div className={classes.sideBar}>
                {/*TODO: styling the button*/}

                {activationData && activationData?.length > 0 &&
                <div>
                    {butcherBanner}
                    <Grid container direction={"row"} className={classes.sideBarButcherList} spacing={1} justifyContent={"center"}>
                        {trail.map((animationProps, index) => {
                            const butcherData = activationData[index].butcher;
                            return (
                                <AnimatedGrid key={butcherData.id} item style={{position: "relative", ...animationProps}}>
                                    <Avatar alt={butcherData.name} src={butcherData.thumbnailRoute}
                                            className={classes.sidebarButcherThumbnail}
                                            aria-owns={!!hoveredButcher ? 'butcher-details-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseOver={(event) => butcherHover(event, butcherData)}
                                            onMouseLeave={(event) => butcherEndHover()}
                                    />
                                </AnimatedGrid>)
                        })
                        }
                    </Grid>
                </div>
                }
            </div>
            <Popover
                id={"butcher-details-popover"}
                open={!!hoveredButcher}
                anchorEl={detailsAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={butcherEndHover}
                disableRestoreFocus
                className={classes.hoverPopover}
                PaperProps={{className: classes.hoverPopoverPaper}}
            >
                <ButcherDetailsComponent noControls embedded={false} clearSelectedButcher = {() => null} {...hoveredButcher}/>
            </Popover>
        </React.Fragment>
    )
}