import {GeneralAlertMessage, GeneralAlertType} from "./GeneralAlertLogic";
import {Alert} from "./GeneralAlertBox";
import Assets from "../../../Constants/Assets";

export default function CreateAlert(alertMessage: GeneralAlertMessage) : Alert | null
{
    switch (alertMessage.type)
    {
        case GeneralAlertType.Praise:
            return {
                image: Assets.Alerts.Praise.img,
                sound: Assets.Alerts.Praise.audio,
                duration: 5000,
                message: `|%R%${alertMessage.redeemer}| sends his praises! |%T%Praise the metal!`
            }
        case GeneralAlertType.MetalAlert:
            return{
                image: Assets.Alerts.MetalAlert.img,
                sound: Assets.Alerts.MetalAlert.audio,
                duration: 14000,
                message: `|%R%${alertMessage.redeemer}| informs of breaking news! It's time for the |%T%Metal Alert!`,
            }
        case GeneralAlertType.TheMusical:
            return{
                image: Assets.Alerts.Musical.img,
                sound: Assets.Alerts.Musical.audio,
                duration: 6000,
                message: `|%R%${alertMessage.redeemer}| demands a song! Start |%T%The Musical!`,
            }
        case GeneralAlertType.StreamSingout:
            return{
                image: Assets.Alerts.Singout.img,
                sound: Assets.Alerts.Singout.audio,
                duration: 15000,
                message: `|%R%${alertMessage.redeemer}| demands to be honored with an epic ballad. Time for a |%T%Singout!`,
            }
        case GeneralAlertType.NormalButcherRedemption:
            const message = alertMessage.redeemer.toLowerCase() === "spencerawest"
            ? `The free |%T%brawler token| gets snatched by |%R%Spench|! The hoard grows...`
            : `|%R%${alertMessage.redeemer}| gets a free |%T%brawler token|.`

            return{
                video: Assets.Alerts.Butcher.video,
                message: message
            }
    }

    return null;
}