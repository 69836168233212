import WaitingContainer from "../Wrappers/WaitingContainer";
import Button from "@material-ui/core/Button";
import ButcherAPI from "../BackendLayer/ButcherAPI";
import {useFetchAsync} from "../CommonUtils/Fetch";
import {CreateWaitingTracker} from "../CommonUtils/LongOp";
import {useState} from "react";
import useWebsockets from "../BackendLayer/WebsocketsWrapper";

const nullData = null;
const butcherFetcher = () => ButcherAPI.GetAllButchers();
const fetchButcherErrorCode = "FetchButchersError"
export function useButcherData() {
    let [initialState, setInitialState] = useState(nullData);
    let {loading, data: butchers, setData: setButchers, fetchError, hotReload} = useFetchAsync(initialState, butcherFetcher, fetchButcherErrorCode);
    const loadingTracker = CreateWaitingTracker(loading, fetchError, "Loading butchers", false);
    return {loadingTracker,
        butchers,
        setButchers,
        hotReload
    };
}


export default function ReloadOnDemandPOC(props){
    let {loadingTracker, butchers, hotReload} = useButcherData();
    const triggerHotReload = () => {
        hotReload();
    }

    useWebsockets("HomykHub");
    useWebsockets("HomykHub");
    useWebsockets("HomykHub");
    useWebsockets("HomykHub");

    return (
        <div>
            <WaitingContainer waitingTrackers={[loadingTracker]}>
                {butchers && <div>UserData: {butchers.length}</div>}
                <Button variant={"contained"} onClick={triggerHotReload}>Hot reload</Button>
            </WaitingContainer>
        </div>
    )
}