import {UserInfo} from "../BackendLayer/AuthAPI";

type PermissionString = "Viewer" | "Mod" | "Developer" | "Broadcaster";
const Viewer: PermissionString = "Viewer";
const Mod: PermissionString = "Mod";
const Broadcaster: PermissionString = "Broadcaster";
const Developer: PermissionString = "Developer";

type PermissionCheckFunc = (userData: UserInfo | null | undefined) => boolean;
function hasPermission (userData: UserInfo | null | undefined, permissionString: PermissionString) {
    if (!userData || !userData.roles) {
        return false;
    }

    return userData.roles.indexOf(permissionString) !== -1;
}


const IsViewer: PermissionCheckFunc = (userData) => hasPermission(userData, Viewer);
const IsMod: PermissionCheckFunc = (userData) => hasPermission(userData, Mod);
const IsBroadcaster: PermissionCheckFunc = (userData) => hasPermission(userData, Broadcaster);
const IsDeveloper: PermissionCheckFunc = (userData) => hasPermission(userData, Developer);

const PermissionUtils = {
    IsViewer,
    IsMod,
    IsBroadcaster,
    IsDeveloper
};

export default PermissionUtils;