import {useState} from "react";
import useWebsockets, {GivOrTakOperationEvent} from "../../BackendLayer/WebsocketsWrapper";
import AuthAPI, {LoginThroughExtensionResult, UserInfo} from "../../BackendLayer/AuthAPI";
import {DirectorDataHandlers, setDirectorStateSubscriptions} from "../../CommonUtils/DataFetchers/DirectorData";
import {ActivationsDataHandlers, setActivationsSubscriptions} from "../../CommonUtils/DataFetchers/ButcherActivations";
import useLongOp from "../../CommonUtils/LongOp";
import {PricingTier} from "../../Constants/PricingCategories";
import {UserDataHandlers} from "../ButcherBrowseView/ButcherBrowseViewState";
import {ButcherActivation, DirectorDataPresentation} from "../../BackendLayer/ButcherAPI";

export interface ProcessedRedemption {
    twitchUserId: string;
    twitchUserDisplayName: string;
    pricingTier: PricingTier;
    message: string;
}

const NoUserIdResponse = "NO_USER_ID";
export function useActivationWebsockets(activationsDataBundle: ActivationsDataHandlers,
                                        directorStateDataBundle: DirectorDataHandlers,
                                        userData: UserInfo | null,
                                        extraOnActivate?: (newActivation: ButcherActivation) => void,
                                        extraOnEnqueuing?: (newEnqueued: ButcherActivation)  => void,
                                        extraOnDeactivate?: (id: string) => void,
                                        extraOnStateChange?: (newStateData: DirectorDataPresentation) => void)
{
    let {connection, createOrResetSubscription} = useWebsockets("HomykHub");
    let [newRedemption, setNewRedemption] = useState(false);
    let [showNewRedemptionButton, setShowNewRedemptionButton] = useState(false);
    if (connection) {
        setDirectorStateSubscriptions(createOrResetSubscription, directorStateDataBundle.setFullDirectorData, extraOnStateChange)
        setActivationsSubscriptions(
            createOrResetSubscription,
            activationsDataBundle.activationData,
            activationsDataBundle.addActivation,
            activationsDataBundle.removeActivation,
            extraOnActivate,
            extraOnEnqueuing,
            extraOnDeactivate);
        createOrResetSubscription("Redemption", "NewRedemptionReminder", (redemption: ProcessedRedemption) => {
            if (userData != null && userData.twitchUserId === redemption.twitchUserId){
                setNewRedemption(true);
                setShowNewRedemptionButton(true);
                setTimeout(() => setShowNewRedemptionButton(false), 10000);
            }
        });
        createOrResetSubscription("GIVorTAK", "NewRedemptionReminder", (event: GivOrTakOperationEvent) => {
            if (userData != null &&
                (userData.twitchUserId === event.giverTwitchId
                    || userData.twitchUserId === event.recipientTwitchId))
            {
                window.location.reload();
            }
        });
    }
    const cancelRedemptionNotification= () => {
        setNewRedemption(false);
        setShowNewRedemptionButton(false)
    }
    return { newRedemption, showNewRedemptionButton, cancelRedemptionNotification};
}

export enum PassiveLoginState {
    LoginNotYetAttempted,
    NeedIdShare,
    LoginReturnedNull,
    LoginSuccessful,
    AlreadyLogedIn,
    NotLoggedInTwitch,
    UnexpectedScenario
}

// passive login as in the user does not need to manually log in
export function usePassiveLogin(): UserDataPassiveLoginHandler
{
    let {waitingTracker: loadingTracker, runner} = useLongOp<LoginThroughExtensionResult>("Loggin in", null, null, true);
    let [userData, setUserData] = useState<UserInfo | null>(null);
    let [loginState, setLoginState] = useState<PassiveLoginState>(PassiveLoginState.LoginNotYetAttempted);
    let [lastUsedToken, setLastUsedToken] = useState<string | null>(null);

    const login = (newIdToken: string, forceRequest: boolean = false) => {
        setLastUsedToken(newIdToken);
        if (!userData || forceRequest) {
            runner(() => AuthAPI.LoginThroughExtensionIdToken(newIdToken),
                null,
                (result) => {
                    if (result?.data === NoUserIdResponse) {
                        console.log("Can't log in, id share not yet granted");
                        setLoginState(PassiveLoginState.NeedIdShare);
                    } else {
                        if (!result?.data){
                            console.error("Can't log in, login request returned null");
                            setLoginState(PassiveLoginState.LoginReturnedNull);
                        }
                        else{
                            const userData = result?.data;
                            userData.extensionTokenId = newIdToken;
                            setUserData(userData);
                            console.log("Passive login complete");
                            setLoginState(PassiveLoginState.LoginSuccessful);
                        }
                    }
                })
        }
        else{
            console.log("Already logged in!");
            setLoginState(PassiveLoginState.AlreadyLogedIn);
        }

        setLoginState(PassiveLoginState.UnexpectedScenario);
    };

    // duplicated from ButcherBrowseViewSTate.js/useUserData
    function modifyTokenAmount(type: PricingTier, amount: number){
        if (!userData){
            return;
        }
        if (type === PricingTier.Base){
            setUserData({...userData, butcherTokens: userData.butcherTokens + amount} as UserInfo)
        }
        else if (type === PricingTier.Brutal){
            setUserData({...userData, brutalButcherTokens: userData.brutalButcherTokens + amount} as UserInfo)
        }
        else{
            console.error("ModifyTokenAmount called for unknown type" + type);
        }
    }

    function hasTokens(){
        return userData ? (userData.butcherTokens > 0 || userData.brutalButcherTokens > 0) : false;
    }

    const hotReloadWorkaround = () => {
        console.warn("Using hot reload on passive login");
        if (lastUsedToken !== null){
            console.warn("Last used token is stored. Hot reloading user data via Login")
            login(lastUsedToken, true);
        }
        else{
            console.warn("No used token is stored. Refreshing the extension")
            window.location.reload();} // just reload so the login flow starts again correctly
        }

    return {loadingTracker, login, passiveLoginState: loginState, currentUserData: userData, modifyTokenAmount, hasTokens, hotReload: hotReloadWorkaround};
}

export interface UserDataPassiveLoginHandler extends UserDataHandlers
{
    login: (newIdToken: string, twitchExtensionHelper: any) => void,
    passiveLoginState: PassiveLoginState,
    hasTokens: () => boolean
}