import {
    Dialog,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useFetchAsync} from "../../../CommonUtils/Fetch";
import {CreateWaitingTracker} from "../../../CommonUtils/LongOp";
import AuthAPI, {UserInfo} from "../../../BackendLayer/AuthAPI";
import WaitingContainer from "../../../Wrappers/WaitingContainer";
import UserAccountRow from "./UserAccountRow";
import {useState} from "react";
import GiveTokensForm from "./GiveTokensForm";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    },
});

const emptyInfo: UserInfo[] = []
const accountsFetcher = () => AuthAPI.GetAllActiveUserAccounts();
const fetchAccountsError = "AccountsInfoFetchError";
function compareStrings(a: string, b: string) : number
{
    let aUpperCase = a.toUpperCase();
    let bUpperCase = b.toUpperCase();
    if (aUpperCase < bUpperCase){
        return -1;
    }
    if (aUpperCase > bUpperCase){
        return 1;
    }
    return 0;
}
function useAccounts(){
    let {loading, data, fetchError} = useFetchAsync<UserInfo[], void>(emptyInfo, accountsFetcher, fetchAccountsError);
    const loadingTracker = CreateWaitingTracker(loading, fetchError, "Loading accounts", false);
    return {loadingTracker, accounts: data};
}

export interface GiveTokensPopoverInfo{
    selectedUser: UserInfo | null,
    open: boolean,
    anchor: any
}
export function UserAccountsList(){
    const classes = useStyles();
    let {loadingTracker, accounts} = useAccounts();
    const sortedAccounts = accounts?.sort((a, b) => compareStrings(a.displayName,b.displayName))
    let [popoverInfo, setPopoverInfo] = useState<GiveTokensPopoverInfo>({selectedUser: null, open: false, anchor: null})
    const openGiveTokenPopup = (userInfo: UserInfo, event: any) => {
        setPopoverInfo({
            selectedUser: userInfo,
            open: true,
            anchor: event.target
        })
    }

    console.log("Accounts", accounts);
    return (
        <WaitingContainer waitingTrackers={[loadingTracker]}>
            <Typography variant={"h5"}> Viewing active user accounts:</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table}/>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            User name
                        </TableCell>
                        <TableCell>Twitch Id</TableCell>
                        <TableCell>Tokens</TableCell>
                        <TableCell>BRÜTAL tokens</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedAccounts && sortedAccounts.map((userInfo: UserInfo) => (<UserAccountRow openGiveTokenPopup={openGiveTokenPopup} {...userInfo}/>))}
                </TableBody>
            </TableContainer>
            <Dialog
                id={"giveTokensPopover"}
                open={popoverInfo.open}>
                <div><GiveTokensForm {...popoverInfo.selectedUser!}/></div>
            </Dialog>
        </WaitingContainer>)
}