import {useEffect, useState} from "react";
import {FormattedError, GetErrorMessage} from "./Error";
import {AxiosResponse} from "axios";

type FetchState<T> = {data: T | null, loading: boolean}
export type Fetcher<TResponse> = () => Promise<AxiosResponse<TResponse>>
export type ParameterizedFetcher<TResponse, TParam> =  (params: TParam | null) => Promise<AxiosResponse<TResponse>>

interface FetchHandlers<T> {
    loading: boolean,
    data: T | null,
    setData: (newData: T) => void,
    fetchError: FormattedError | null,
    hotReload: () => void
}
export function useFetchAsync<TResponse, TParam>(
        initialState: TResponse | null,
        fetcher: Fetcher<TResponse> | ParameterizedFetcher<TResponse, TParam>,
        errorCode: string | null = null,
        fetcherParams: TParam  | null = null) : FetchHandlers<TResponse>
{
    let [fetched, setFetched] = useState<FetchState<TResponse>>({data: initialState, loading: true});
    let [error, setError] = useState<FormattedError | null>(null);
    let [hotReloadTrigger, setHotReloadTrigger] = useState<boolean>(false); // state that is changed to whatever value to trigger a reload
    
    useEffect(() => {
        const fetchData = () => {
            fetcher(fetcherParams)
                .then((result) => {
                    setFetched({data: result.data, loading: false})
                })
                .catch((error) => setError(GetErrorMessage(error, errorCode)));
        };
        fetchData();
    }, [initialState, fetcher, errorCode, fetcherParams, hotReloadTrigger]);

    const setData = (newData: TResponse) => setFetched({loading: fetched.loading, data: newData})
    const hotReload = () => setHotReloadTrigger(previous => !previous);

    return {loading: fetched.loading, data: fetched.data, setData, fetchError: error, hotReload}
}