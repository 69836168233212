import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
    messageContainer: {
        width: "50%",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        textAlign: "center"
    },
}));

export interface CreationCompleteMessageProps {
    createDirectly: boolean,
    onDismiss: () => void,
}

export default function CreationCompleteMessage(props: CreationCompleteMessageProps){
    const classes=useStyles();
    return (<div className={classes.messageContainer}>
        {props.createDirectly
            ? <div>Vocal butcher created successfully</div>
            : <div>Vocal butcher proposed! The streamer needs to approve it before it appears on the list.</div>
        }
        <Button variant={"contained"} color={"primary"} onClick={props.onDismiss}>Back to the browser</Button>

    </div>)
}