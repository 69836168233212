import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {Link as RouterLink} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import ButcherAPI, {Butcher} from "../BackendLayer/ButcherAPI";
import useLongOp from "../CommonUtils/LongOp";
import WaitingContainer from "../Wrappers/WaitingContainer";
import ActivateButcherButton from "./ActivateButcherButton";
import ButcherStatus from "../Constants/ButcherStatus";
import usePopover from "../CommonUtils/usePopover";
import LoginActionRequiredMenu from "./ButcherBrowser/PopoverContents/LoginActionRequiredMenu";
import ButcherTokenInstructions from "./ButcherBrowser/PopoverContents/ButcherTokenInstructions";
import PermissionUtils from "../CommonUtils/PermissionUtils";
import ButcherDetailsData from "./ButcherDetailsData";
import {verifyEnoughTokens} from "./UserValidationUtils";
import ButcherApprovalStatus from "../Constants/ButcherApprovalStatus";
import {UserInfo} from "../BackendLayer/AuthAPI";
import {PricingTier} from "../Constants/PricingCategories";
import {ReactMouseEvent} from "../CommonUtils/CommonTypes";
import {PassiveLoginState} from "../Views/ExtensionView/ExtensionViewState";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    soundcloudContainer: {
        height: "45px",
        width: "45px",
        overflow: "hidden",
        color: theme.palette.text.secondary,
        borderRadius: "50%",
        border: "solid 2px",
        margin: "8px auto",
    },
    soundcloudContainerInner: {
        position: "relative",
        top: "-10px",
        left: "-10px",
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    detailsPopup: {
        border: '2px solid ' + theme.palette.primary.main,
    },
    grid: {
        margin: "0",
    },
    imageCard: {
        margin: 0,
        padding: 0
    },
    media: {
        height: 0,
        paddingTop: "100%",
        [theme.breakpoints.down('sm')]: {
            paddingTop: "150%",
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: "400%",
        }
    },
    smallButton: {
        marginLeft: "10px",
        marginRight: "10px"
    },
    deleteButton: {
        '&:hover': {
            backgroundColor: theme.palette.error.main
        }
    },
    confirmDeletionButton: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.light
        }
    },
    detailsPanel:{
        padding: "8px 8px"
    },
    dialogTitle:{
        textAlign: "center"
    },
    activateButton: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        }
    },
    liveButton: {
        backgroundColor: theme.palette.error.dark,
        '&:hover': {
            backgroundColor: theme.palette.error.light
        }
    },
    summonedStatsSection: {
        margin: "24px 0 0 0"
    }
}));

export interface ButcherDetailsCommonProps extends Butcher
{
    // TODO: making may properties optional, but not sure if they should be
    noControls: boolean,
    embedded: boolean,
    clearSelectedButcher: () => void,
}
export interface ButcherDetailsNotEmbeddedProps extends ButcherDetailsCommonProps{
    noControls: true,
}

export interface ButcherDetailsEmbeddedProps extends ButcherDetailsCommonProps
{
    noControls: false,
    onError: () => void,
    userData: UserInfo,
    modifyButcher: (butcherId: string, changes: object) => void,
    onDoneBrowsing: () => void,
    requestTwitchIdShare: () => void,
    passiveLoginState?: PassiveLoginState,
}

export default function ButcherDetailsComponent(props: any){//} ButcherDetailsNotEmbeddedProps | ButcherDetailsEmbeddedProps){
    const classes = useStyles();


    let deletionOp = useLongOp(`Banishing ${props.name} to the shadow realm`, null, !props.noControls ? props.onError : undefined);
    let activateOp = useLongOp<void | null>(`Making ${props.name} go LIVE`,null, !props.noControls ? props.onError : undefined);
    let deactivateOp = useLongOp(`Yanking ${props.name} away from the spotlight`, null, !props.noControls ? props.onError : undefined);
    let loginRequiredPopover = usePopover("loginRequiredPopover", LoginActionRequiredMenu, {embedded: props.embedded, passiveLoginState: props.passiveLoginState});
    let noTokensPopover = usePopover("noTokensPopover", ButcherTokenInstructions, {isBrutal: props.pricingCategory === PricingTier.Brutal});
    let [butcherStatus, setButcherStatus] = useState(props.status);
    let [activationStarted, setActivationStarted] = useState(false);
    function deleteButcher(id: string){
        deletionOp.runner(()  => ButcherAPI.DeleteButcher(id), "/");
        if (props.embedded === true) props.clearSelectedButcher()
    }

    function activateButcher(clickTarget: EventTarget){
        setActivationStarted(true);
        // @ts-ignore
        activateOp.runner(() => {
            if (!props.noControls){
                if(!props.userData){
                    loginRequiredPopover.setAnchor(clickTarget)
                    if (props.requestTwitchIdShare) { props.requestTwitchIdShare(); }
                    return Promise.resolve(null);
                }
                if(!verifyEnoughTokens(props.userData, props.pricingCategory)){
                    console.log("Current user has not enough tokens!")
                    noTokensPopover.setAnchor(clickTarget);
                    return Promise.resolve(null);
                }

                let apiCall = props.embedded
                    ? ButcherAPI.ActivateButcherWithoutCookies(props.id, props.userData.extensionTokenId)
                    : ButcherAPI.ActivateButcher(props.id);
                return apiCall
                    .then(
                        (result) => {
                            setButcherStatus(ButcherStatus.Live);
                            props.modifyButcher(props.id, {status: result.data.status});
                            props.onDoneBrowsing();
                        })
                    .catch(
                        (error) => {
                            if (error.response.status === 401) {
                                loginRequiredPopover.setAnchor(clickTarget)
                                if (props.requestTwitchIdShare) { props.requestTwitchIdShare(); }
                            } else {
                                throw error;
                            }
                        })
            }
        })}
    function deactivateButcher(){
        deactivateOp.runner(() => {
                return props.embedded
                    ? ButcherAPI.DeactivateButcherWithoutCookies(props.id, props.userData.extensionTokenId)
                    : ButcherAPI.DeactivateButcher(props.id)
            },
                null,
                () => {
                    setButcherStatus(ButcherStatus.Ready);
                    if (!props.noControls) { props.modifyButcher(props.id, {status: ButcherStatus.Ready}) }
                }
        )
    }

    function isLive(){
        return butcherStatus === ButcherStatus.Live || butcherStatus === ButcherStatus.Queued
    }

    const activateButton = (<React.Fragment>
        {loginRequiredPopover.popoverComponent}
        {noTokensPopover.popoverComponent}
        {<ActivateButcherButton message={"Activate"} className={classes.activateButton} alreadyTriggered={activationStarted}
                                     onClick={(event: ReactMouseEvent) => activateButcher(event.target)}/>
        }
    </React.Fragment>);
    // const nowLiveButton = (<ActivateButcherButton message={"NOW LIVE"} className={classes.liveButton}/>);
    const deactivateButton = (!props.noControls && PermissionUtils.IsMod(props.userData)) ?
        (<ActivateButcherButton message={"Deactivate"} className={classes.liveButton} onClick={deactivateButcher} alreadyTriggered={false}/>)
        : (<ActivateButcherButton message={"LIVE"} className={classes.liveButton} onClick={() => null} alreadyTriggered={false}/>)

    let [showConfirmDeletionDialog, setShowConfirmDeletionDialog] = useState(false);

    return (
        <WaitingContainer overrideShowContent showContentsOnError nonDisruptive={props.embedded} waitingTrackers={[deletionOp.waitingTracker, activateOp.waitingTracker, deactivateOp.waitingTracker]}>
        <Paper className={classes.paper}>
            <Grid direction="row" container spacing={0} alignItems={"center"}>
                <Grid item xs={3} md={5}>
                    <Card className={classes.paper}>
                        <CardMedia image={props.croppedPictureRoute} className={classes.media}/>
                    </Card>
                </Grid>
                <Grid item xs={9} md={7}>
                        <Grid container direction={"column"} justify={"flex-end"} alignItems={"center"} className={classes.detailsPanel}>
                            <ButcherDetailsData {...props}/>
                            {!props.noControls &&
                                <Grid item>
                                    <Box mt={4}>
                                        {isLive()
                                            ? (deactivateButton)
                                            : activateButton
                                        }
                                        {!props.embedded && PermissionUtils.IsMod(props.userData) &&
                                          <Box my={1}>
                                                <Button variant={"contained"} component={RouterLink}
                                                        to={"/edit/" + props.id} className={classes.smallButton}>
                                                    <Typography variant={"button"}>
                                                        Edit
                                                    </Typography>
                                                </Button>
                                                <Button variant={"contained"}
                                                        className={classes.smallButton + " " + classes.deleteButton}
                                                        onClick={() => setShowConfirmDeletionDialog(true)}>
                                                    <Typography variant={"button"}>
                                                        Delete
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        }
                                        {!props.embedded && props.previewEmbedCode &&
                                        <div>
                                            Preview
                                            <div className={classes.soundcloudContainer} >
                                                <div className={classes.soundcloudContainerInner}>
                                                <iframe width="60" height="120" scrolling="no" frameBorder="no"
                                                        allow="autoplay"
                                                        src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + props.previewEmbedCode + "&color=%231c5296&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=false"}>
                                                    </iframe>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </Box>
                                </Grid>
                            }
                            <div className={classes.summonedStatsSection}>
                                <Typography component={"h6"} variant={"button"}>
                                    Summoned {props.timesSummoned} time{props.timesSummoned !== 1 && "s"}
                                    {props.timesSummoned === 0 && ". That's a shame"}
                                </Typography>
                            {props.timesSummoned != null && props.timesSummoned > 0 &&
                                <Typography variant={"button"}>
                                    Last activated by {props.lastSummonedBy}
                                </Typography>
                            }
                            </div>
                        </Grid>
                </Grid>
        </Grid>
        {!props.embedded && showConfirmDeletionDialog &&
        <Dialog open={showConfirmDeletionDialog} aria-labelledby={"deletion-confirmation-title"} >
            <DialogTitle id={"deletion-confirmation-title"} className={classes.dialogTitle}>Delete {props.name}?</DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.dialogTitle}>
                    All stats will be lost
                </DialogContentText>
                <DialogActions>
                    <Button variant={"contained"} onClick={() => {setShowConfirmDeletionDialog(false); deleteButcher(props.id)}} className={classes.confirmDeletionButton}>Do it</Button>
                    <Button variant={"contained"} onClick={() => setShowConfirmDeletionDialog(false)} autoFocus>Cancel</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
        }
        </Paper>
        </WaitingContainer>
    )
}