import React from "react"
import {WheelComponent} from "./wheelComponent";
import {WheelConfigurationComponent} from "./configuration/configurationComponent";
import "./wheelStyle.css"
import "./sliderStyle.css"
import {ExportPopUpComponent} from "./exportPopUp";
import {ImportPopUpComponent} from "./importPopUp";
import SkullPopup from "./Popup/skullPopup";
import {WinnerPopUpComponent} from "./winnerPopupComponent";
import WheelApis from "../../BackendLayer/WheelAPI";
import POD from "../../images/wheelchan/pod.png"

export class WheelPageComponent extends React.Component {
    constructor(props) {
        super(props);
        let canvasWidth =  window.innerWidth-10;
        let canvasHeight =  window.innerHeight -10;
        this.state = {
            loading: true,
            loadingMusic: true,
            showConfig: false,
            winner: null,
            showExportModal: false,
            showImportModal: false,
            showWheel: false,
            allowWheelManipulation: true,
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            musicVolume: 0.55,
        }
        this.onApplyWheelChanges = this.onApplyWheelChanges.bind(this);
        this.openConfig = this.openConfig.bind(this);
        this.closeConfig = this.closeConfig.bind(this);
        this.dismissWinnerPopUp = this.dismissWinnerPopUp.bind(this);
        this.onWinnerSelect = this.onWinnerSelect.bind(this);
    }

    async init(){
        this.totalAudioSources = 7;
        let customizedData = (await WheelApis.getWheel(this.props.match.params.id)).data;
        if (!customizedData){
            console.log("Could not fetch the data");
            // todo: error message
        }
        if (customizedData.forceCenter){
            customizedData.wheelCenterX = this.state.canvasWidth/2;
            customizedData.wheelCenterY = this.state.canvasHeight/2;
        }
        // customizedData.options = generateHaloConfig(7);
        // customizedData.showText = false;
        // customizedData.popupType = "halo";
        this.initializeAssetsObject(customizedData);
        const initialAudioSource = 1;
        this.loadMusic();
        this.loadWinnerFanfare(customizedData);
        this.setAudioSource(initialAudioSource, customizedData);
        this.triggerAllImagesLoad(customizedData, true);
        this.loadFixedImages(customizedData);
        this.setState({loading: false, wheelProperties: customizedData});

    }

    componentDidMount(){
        let initPromise = this.init();
    }

    render(){
        if (this.state.loading || this.state.loadingMusic){
            return "Loading";
        }
        const configuration = this.props.editMode && (this.state.showConfig ?

            (<WheelConfigurationComponent {...this.state.wheelProperties} onClose={this.closeConfig} setWheelProp={this.setWheelProp.bind(this)}
                                          onAddWheelOption={this.addWheelOption.bind(this)} onRemoveWheelOption={this.removeWheelOption.bind(this)}
                                          onWedgeChange={this.onWedgeChange.bind(this)} onBackgroundChange={this.onBackgroundChange.bind(this)}
                                          toggleImportModal={() => this.setState((state, props) => ({showImportModal: !state.showImportModal}))}
                                          toggleExportModal={() => this.setState((state, props) => ({showExportModal: !state.showExportModal}))}
                                          onImageLoaded={this.updateImage.bind(this)}
                                          onSaveWheel={this.saveWheel.bind(this)}
                                          setAudioSource={this.setAudioSource.bind(this)}
                                          loadWinnerFanfare = {this.loadWinnerFanfare.bind(this)}
                                          playSound={this.playSound.bind(this)}
            />)
            : (<div><input className="openConfigButton" type={"button"} value="Modify the wheel" onClick={this.openConfig}/></div>));
        let winnerPopUp = "";
        if (this.state.winner !== null){
            if (this.state.wheelProperties.popupType === "halo"){
                winnerPopUp = (<SkullPopup
                    {...this.state.winner}
                    dismiss={this.dismissWinnerPopUp}
                />)
            }
            else{
                winnerPopUp = (<WinnerPopUpComponent {...this.state.wheelProperties} dismissWinnerPopUp={this.dismissWinnerPopUp} winner={this.state.winner.text}/>);
            }
        }

        return (
            <div style={{backgroundColor: this.state.wheelProperties.backgroundColor}}>
                {this.state.showImportModal ? <ImportPopUpComponent onImport={this.import.bind(this)} onClose={this.closeImportModal.bind(this)}/> : ""}
                {this.state.showExportModal ? <ExportPopUpComponent exportedText={this.getExportText()} onClose={this.closeExportModal.bind(this)}/> : ""}
                {winnerPopUp}
                {configuration}
                {this.state.isAModalOpen ? <div style={{position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.7)"}}></div> : ""}
                <WheelComponent onWinnerSelect={this.onWinnerSelect} playSound={this.playSound.bind(this)}
                                playStopMusic = {this.playStopMusic.bind(this)}
                                rewindMusic = {this.rewindMusic.bind(this)}
                                changeVolume = {this.changeMusicVolume.bind(this)}
                                {...this.state.wheelProperties} allowManipulation={this.state.allowWheelManipulation}
                                canvasWidth={this.state.canvasWidth} canvasHeight={this.state.canvasHeight}
                />

            </div>
        )
    }

    saveWheel(asNewTemplate)
    {
        let wheel = this.state.wheelProperties;
        if (asNewTemplate){
            wheel.isTemplate = true;
            WheelApis.createWheel(wheel).then(() => console.log("New template created successfully"));
        }
        else{
            WheelApis.saveWheel(wheel).then(() => console.log("Saved successfully"));
        }
    }
    initializeAssetsObject(data){
        data.assets = {pod: null}
    }

    openConfig() {
        this.setState({showConfig: true});
    }

    closeConfig(){
        this.setState({showConfig: false});
    }

    onApplyWheelChanges(updatedProperties){
        this.setState({wheelProperties: updatedProperties});
    }

    dismissWinnerPopUp(){
        if (this.state.wheelProperties.removeOptionOnSelection){
            this.removeLastSelectedOption();
        }
        this.setState({winner: null})
        this.setModalIsCurrentlyOpen(false);
    }

    removeLastSelectedOption(){
        this.setState((state, props) => {
            const config = {...this.state.wheelProperties};
            let optionToRemove = config.options.find((option) => option.optionId === this.state.winner.id);
            optionToRemove.deleted = true;
            config.options = config.options;
            WheelApis.saveWheel(config).then(() => console.log("Autosave complete"));
            return {
                winner: null,
                wheelProperties: config
            }
        })
    }
    onWinnerSelect(winner){
        this.setState({"winner": winner});
        this.setModalIsCurrentlyOpen(true);
        this.playSound("winner")
    }
    setWheelProp(name, value){
        this.setState(function(state, props){
            const config = {...state.wheelProperties}
            config[name] = value;
            return {wheelProperties:  config}
        })

    }
    addWheelOption(){
        this.setState(function(state, props){
            const config = {...state.wheelProperties};
            config.lastId = config.lastId + 1;
            const defaultNewOption = {id: config.lastId, text: "", color: "#999", weight: 1, background: {img: null, url: "", scale: 1, offset: {x: 0, y: 0}}};
            config.options = config.options.slice();
            config.options.push(defaultNewOption);
            return {wheelProperties: config}
        })
    }
    removeWheelOption(id){
        this.setState(function(state, props){
            const config = {...state.wheelProperties};
            config.options = config.options.filter(o => o.optionId !== id);
            return {wheelProperties: config}
        })
    }

    onWedgeChange(id, propName, data){
        this.setState(function(state, props){
            const config = state.wheelProperties;
            let position = config.options.findIndex(o => o.optionId === id);
            if (position === -1){
                throw new Error("Id does not match any element in the state")
            }

            config.options[position][propName] = data;
            return {wheelProperties: config};
        })
    }

    onBackgroundChange(wedgeId, propName, data){
        this.setState(function(state, props){
            const config = state.wheelProperties;
            let position = config.options.findIndex(o => o.optionId === wedgeId);
            if (position === -1){
                throw new Error("Id does not match any element in the state")
            }

            config.options[position].background[propName] = data;
            return {wheelProperties: config};
        })
    }

    getExportText(){
        const exp = JSON.stringify(this.state.wheelProperties, null,1);
        return exp;
    }
    setModalIsCurrentlyOpen(isItOpen){
        this.setState(
            {
                allowWheelManipulation: !isItOpen,
                isAModalOpen: isItOpen
            });
    }

    closeExportModal(){
        this.setState({showExportModal: false});
        //this.setModalIsCurrentlyOpen(false);
    }
    closeImportModal(config){
        if (config){
            this.setState({wheelProperties: config});
        }
        this.setState({showImportModal: false});
        //this.setModalIsCurrentlyOpen(true);
    }

    updateImage(optionId, img, url){
        this.setState(function(state, props){
            const config = state.wheelProperties;
            let position = config.options.findIndex(o => o.optionId === optionId);
            if (position === -1){
                throw new Error("Can't updtate image of option with id " + optionId + ": it does not exist");
            }
            config.options[position].background.url = url;
            config.options[position].background.img = img;
            config.options[position].background.offsetX = 0;
            config.options[position].background.offsetY = 0;
            config.options[position].background.angle = 0;
            config.options[position].background.scale = 1;
            return {wheelProperties: config};
        });
    }

    import(importedWheelConfig) {
        this.triggerAllImagesLoad(importedWheelConfig.options, true);
        this.loadFixedImages(importedWheelConfig)
        this.setState({wheelProperties: importedWheelConfig});
    }

    loadFixedImages(wheelConfig){
        const pod = new Image();
        pod.src = POD;
        wheelConfig.assets.pod = pod;
    }
    triggerAllImagesLoad(wheelProperties, replaceExistingImageObjects = false){
        let options = wheelProperties.options;
        for(const option of options){
            if (option.background){
                if ((replaceExistingImageObjects || !option.background.img)
                    && option.background.url){
                    option.background.img = new Image();
                    option.background.img.src = option.background.url;
                }
            }
        }
        if (wheelProperties.background){
            wheelProperties.background.img = new Image();
            wheelProperties.background.img.src = wheelProperties.background.src;
        }
    }

    initializeWedgeChangeSound(url){
        if (!url){
            return;
        }
        this.sounds = [];
        for(let i = 1; i <= this.totalAudioSources; i++){
            const audio = new Audio("/" + url)
            this.sounds.push(audio);
        }
        this.playingSoundIndex = 0;
    }

    loadWinnerFanfare(wheelProperties){
        const url = wheelProperties.winnerFanfareAudioSource;
        const audio = new Audio("/" + url);
        this.winnerFanfareAudio = audio;
    }

    loadMusic(){
        const audio = new Audio("/wheelchanAssets/audio/music/RokNardin_TheDevil.mp3");
        audio.oncanplaythrough = (event) => this.setState({loadingMusic: false});
        this.backgroundMusic = audio;
    }

    playSound(type = "wedgeChange"){

        if (type ==="wedgeChange"){
            if (this.state.wheelProperties.audioSourceIndex !== -1
                && this.state.wheelProperties.audioSourceIndex <= this.sounds.length)
            {
                const sound = this.sounds[this.playingSoundIndex];
                sound.volume = this.state.wheelProperties.soundVolume;
                sound.currentTime = 0;
                sound.play().catch(error => console.error(error));
                this.soundIndex = this.soundIndex + 1;
                if (this.playingSoundIndex === this.sounds.length){
                    this.soundIndex = 0;
                }
            }
        }
        else if (type === "winner"){
            this.winnerFanfareAudio.volume = this.state.wheelProperties.soundVolume;
            this.winnerFanfareAudio.play().catch(error => console.log(error));
        }
    }

    playStopMusic(){
        this.backgroundMusic.volume = this.state.musicVolume;
        if (this.backgroundMusic.paused){
            let promise = this.backgroundMusic.play();
        }
        else{
            this.backgroundMusic.pause();
        }
    }

    changeMusicVolume(){
        this.setState((state, props) => ({musicVolume: state.musicVolume >= 0.9 ? 0 : state.musicVolume + 0.1}));
        this.backgroundMusic.volume = this.state.musicVolume;
    }

    rewindMusic(){
        this.backgroundMusic.currentTime = 0;
    }

    setAudioSource(audioSourceIndex, wheelProperties){
        this.setWheelProp("audioSourceIndex", audioSourceIndex);
        let audioSourceUrl;
        if (!audioSourceIndex){
            console.error("No audio source index was passed");
        }
        if (audioSourceIndex === -1){
            return;
        }
        else if (audioSourceIndex === -2){
            audioSourceUrl = wheelProperties.wedgeChangeAudioSource;
        }
        else{
            audioSourceUrl = "wheelchanAssets/audio/t"+audioSourceIndex+".ogg";
        }
        this.initializeWedgeChangeSound(audioSourceUrl);
    }
}
