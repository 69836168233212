import React, {useState} from "react"
import Typography from "@material-ui/core/Typography";
import {InputAdornment, Snackbar, TextField} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import UserPermissionConfigRow from "./UserPermissionConfigRow";
import Button from "@material-ui/core/Button";
import AccessTiers from "../../Constants/AccessTiers";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {BroadcasterConfiguration, UserPermissionConfigurationClientSide} from "../../BackendLayer/ConfigurationAPI";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
        },
        textAlign: "center",
    },
    fullForm: {
        width: "60%",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    label: {
    },
    separator: {
        width: "100%",
        height: "1px",
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(2),
    },
    normalInput: {
        width: "8em",
        color: theme.palette.text.primary,
        backgroundColor: "#111111",
    },
    longInput: {
        width: "10em",
        color: theme.palette.text.primary,
        backgroundColor: "#111111"
    },
    userRow: {
        margin: "8px 0"
    },
    addUserIcon:{
        margin: "0px 8px"
    },
    saveButton: {
        margin: "16px"
    },
    savedSnackbar: {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.text.primary
    }
}));
export interface FormFieldHandlers{
    formControl: JSX.Element,
    value: string | number,
}
function useFormField(fieldName: string, label: string, defaultValue: string | number, isNumber: boolean, cssClass: string, multiline: null | boolean = false, isMoney: boolean, min = 0, max = 9999)
{
    let [value, setValue] = useState(defaultValue ?? "");
    let [error, setError] = useState(false)
    let [helperText, setHelperText] = useState<string | null>(null);
    const formElementId = fieldName + "_formControl";
    const adornment = isMoney ? (<InputAdornment position="start">$</InputAdornment>) : "";
    const validate = (value: string | number | null) => {
        if (isNumber && (typeof value === "number") && (value < min || value > max)) {
            setError(true);
            setHelperText(`Value range: ${min}-${max}`)
        }
        else{
            setError(false)
            setHelperText(null)
        }

    }

    const formControl = (
        <TextField
            className={cssClass}
            id={formElementId}
            label={label}
            type="number"
            value={value}
            InputLabelProps={{
                shrink: true,
            }}
            error={error}
            helperText={helperText}
            InputProps={{
                startAdornment: adornment,
                inputProps: isNumber ? {min: min, max: max} : {}
            }}
            variant={"filled"}
            onChange={(event) => {setValue(event.target.value); validate(event.target.value);}}
        />
    )
    return {formControl, value}
}

const msToMinutes= (ms: number) => {
    const totalSeconds = Math.round(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return {minutes, seconds}
}

export interface ConfigurationFormProps {
    configuration: BroadcasterConfiguration,
    save: (config: BroadcasterConfiguration) => void,
}
export default function ConfigurationForm(props: any)
{
    const classes = useStyles();

    const configuration = props.configuration;
    const [maxIndex, setMaxIndex] = useState(configuration.userPermissions.length);
    const butcherTimes = msToMinutes(configuration.butcherDurationInMs);
    const breakTimes = msToMinutes(configuration.breakDurationInMs);
    const normalCheerPrice = useFormField("NormalCheerPrice", "Bit cheer", configuration?.normalButcher.cheerPrice, true, classes.normalInput, null, false);
    const normalTipPrice = useFormField("NormalCheerPrice", "Tip (cents)", configuration?.normalButcher.tipPrice, true, classes.normalInput, null, true);
    const brutalCheerPrice = useFormField("brutalCheerPrice", "Bit cheer", configuration?.brutalButcher.cheerPrice, true, classes.normalInput, null, false);
    const brutalTipPrice = useFormField("brutalTipPrice", "Tip (cents)", configuration?.brutalButcher.tipPrice, true, classes.normalInput, null, true);
    const restMinutes = useFormField("restMinutes", "Minutes", breakTimes.minutes, true, classes.normalInput, null, false, 0);
    const restSeconds = useFormField("restSeconds", "Seconds", breakTimes.seconds, true, classes.normalInput, null, false, 0, 59);
    const butcherMinutes = useFormField("butcherMinutes", "Minutes", butcherTimes.minutes, true, classes.normalInput, null, false, 0);
    const butcherSeconds = useFormField("butcherSeconds", "Seconds", butcherTimes.seconds, true, classes.normalInput, null, false, 0, 59);
    const minutesAndSecondsToMs = (minutes: number, seconds: number) => (minutes * 60 + seconds) * 1000;
    let [saved, setSaved] = useState(false);

    const rebuildPermissionsData: () => BroadcasterConfiguration = () =>
    {
        return {
            normalButcher: {
                cheerPrice: normalCheerPrice.value as number,
                tipPrice: normalTipPrice.value as number
            },
            brutalButcher: {
                cheerPrice: brutalCheerPrice.value as number,
                tipPrice: brutalTipPrice.value as number
            },
            butcherDurationInMs: minutesAndSecondsToMs(butcherMinutes.value as number, butcherSeconds.value as number),
            breakDurationInMs: minutesAndSecondsToMs(restMinutes.value as number, restSeconds.value as number),
            userPermissions: permissionsState
        }
    }

    const anyUserNotVerified = () => {
        return permissionsState.findIndex((row: any) => row.verified === false) !== -1
    }

    for(let i = 0; i < configuration.userPermissions.length; i++){
        configuration.userPermissions[i].id = i.toString();
    }
    const [permissionsState, setPermissionsState] = useState(configuration.userPermissions);
    const updateProperties = (id: string, changes: object) => {
        const index = permissionsState.findIndex((row: any) => row.id === id);
        if (index === -1){
            return;
        }
        permissionsState[index] = {...permissionsState[index], ...changes}
        setPermissionsState(permissionsState.slice());
    }
    const removeUserRow = (id: string) => {
        setPermissionsState(permissionsState.filter((x: any) => x.id !== id))
    }
    const createPermissionConfigRow = (userPermissionData: UserPermissionConfigurationClientSide) => {
        return (<Grid key={userPermissionData.id} className={classes.userRow} item xs = {12}>
            <UserPermissionConfigRow
                changeProperties={(changes: any) => updateProperties(userPermissionData.id, changes)}
                removeUserRow={removeUserRow}
                {...userPermissionData}
                labelClassName={classes.longInput}/>
        </Grid>)
    }
    const userPermissionsSection = permissionsState.map(createPermissionConfigRow);
    const addPermissionRow = () => {
        const newIndex = maxIndex +1;
        setMaxIndex(newIndex);
        let blankProperties: UserPermissionConfigurationClientSide = {userName: "", userTwitchId: null, accessTier: AccessTiers.Viewer, verified: false, id: newIndex.toString(), alreadyInDatabase: false}
        permissionsState.push(blankProperties);
        setPermissionsState(permissionsState.slice());
    }

    let [error, setError] = useState(false);
    const submit = () => {
        if (anyUserNotVerified()){
            setError(true);
            return;
        }
        setError(false);
        const payload = rebuildPermissionsData();
        props.save(payload);
        setSaved(true);
        setTimeout(() => setSaved(false), 3000);
    }

    return (
        <div className={classes.fullForm}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={saved}
                autoHideDuration={3000}
                message="Saved!"
            />
            <Grid container >
                <Grid item xs={12}>
                    <Typography className={classes.label} variant={"h6"} component={"div"}>Prices:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label} variant={"body2"} component={"div"}>Normal butcher:</Typography>
                    {normalCheerPrice.formControl} {normalTipPrice.formControl}
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label} variant={"body2"} component={"div"}>BRÜTAL butcher:</Typography>
                    {brutalCheerPrice.formControl} {brutalTipPrice.formControl}
                </Grid>
                <div className={classes.separator}/>
                <Grid item xs={12}>
                    <Typography className={classes.label} variant={"h6"} component={"div"}>Timers:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label} variant={"body2"} component={"div"}>Break duration:</Typography>
                    {restMinutes.formControl} {restSeconds.formControl}
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.label} variant={"body2"} component={"div"}>Butcher duration:</Typography>
                    {butcherMinutes.formControl} {butcherSeconds.formControl}
                </Grid>
                <div className={classes.separator}/>
                <Grid item xs={12}>
                    <Typography className={classes.label} variant={"h6"} component={"div"}>Permissions:</Typography>
                </Grid>
                <Grid item xs = {4} className={classes.label}>
                    <Typography variant={"body2"}>User name</Typography>
                </Grid>
                <Grid item xs = {4} className={classes.label}>
                    <Typography variant={"body2"}>Permissions level</Typography>
                </Grid>
                <Grid item xs = {3} className={classes.label}>
                    <Typography variant={"body2"}>Twitch id</Typography>
                </Grid>
                <Grid item xs = {3}></Grid>
                {userPermissionsSection}
                <Grid item xs={4}>
                    <Button onClick={addPermissionRow}><AddCircleIcon className={classes.addUserIcon}/> Add user</Button>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs = {12}>
                    {error && anyUserNotVerified() && <Typography style={{color: "red"}}>Verify all users before saving</Typography>}
                </Grid>
                <Grid item xs = {12}>
                    <Button className={classes.saveButton} color="primary" variant={"contained"} onClick={submit}>Save changes</Button>
                </Grid>
            </Grid>
        </div>
    )
}