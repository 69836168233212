import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import HomyksChopIcon from "../images/smolchoppy.png";

export function ForbiddenPage(){
    return(
        <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justify="center"
            style={{minHeight: "12vh"}}
        >
            <Grid item xs={12}>
                <Typography variant={"h6"} component={"h6"}>You are not authorized to do that. <img alt="homyksChop" src={HomyksChopIcon}/></Typography>
            </Grid>
            <Grid item xs={12}>
                <Link href={"/"}><Button variant={"contained"} color={"primary"}>Get out</Button></Link>
            </Grid>
        </Grid>
    )
}