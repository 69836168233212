import React from "react"
import Typography from "@material-ui/core/Typography";
import {PassiveLoginState} from "../../../Views/ExtensionView/ExtensionViewState";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface ActionRequiresIdShareProps{
    embedded: boolean,
    passiveLoginState: PassiveLoginState,
}
const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: "300px",
        textAlign: "center",
    },
    helpLink: {
        color: "#ebefff"
    }
}))

export default function LoginActionRequiredMenu(props: ActionRequiresIdShareProps)
{
    const classes = useStyles();
    if (!props.embedded){
        return (<React.Fragment>
            <Typography variant="body1">You need to be logged in to do this</Typography>
        </React.Fragment>)
    }
    else {
        let message: any = "Woops, uninitialized message. Pistachio fucked up";
        let reloadComponent = (<div>
            <Button variant={"contained"} color={"primary"} onClick={() => window.location.reload()}>Try clicking here to reload the extension</Button>
        </div>)
        switch (props.passiveLoginState){
            case PassiveLoginState.LoginSuccessful:
            case PassiveLoginState.AlreadyLogedIn:
                message = "Login was successful. You should not be seeing this. If you do, blame Pistachio.";
                break;
            case PassiveLoginState.LoginNotYetAttempted:
                message = "Login was not yet done for some reason. It should have been done automatically."
                break;
            case PassiveLoginState.LoginReturnedNull:
                message = "Login was attempted but failed."
                break;
            case PassiveLoginState.NeedIdShare:
                message = (<div><div>You need to be logged in to Twitch and grant access to your identity to do this.</div><div>{"If you're logged in, a popup should have appeared on the right ==>"}</div><div>If you already granted access and still see this:</div></div>);
                break;
            case PassiveLoginState.UnexpectedScenario:
            default:
                message = "Something went wrong with the login. Maybe you need to grant identity access (a pop up should have appeared in that case) or something else. ";
                console.error("Unexpected passive login state!", props.passiveLoginState);
        }

        return (<div className={classes.container}>
            <Typography variant="body1">{message}</Typography>
            <Typography variant={"body1"}>If it keeps not working, try following the troubleshooting guide in </Typography>https://vocalbutchers.fun/errorhelp
            <Typography variant={"body1"}> Or</Typography>
            {reloadComponent}
        </div>)
    }
}