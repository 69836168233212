const folder = "assets/"
const alertImagesFolder = "assets/alerts/images/";
const alertAudioFolder = "assets/alerts/audio/";
const alertWebmFolder = "assets/alerts/video/";

const Assets = {
    NormalTokenIcon: folder + "bigtier1.png",
    BrutalTokenIcon: folder + "bigtier4.png",
    BitCheerIcon: folder + "bitcheer.gif",
    StreamLabsLogoIcon: folder + "streamLabsLogoIcon.png",
    StreamElementsLogoIcon: folder + "streamElementsLogoIcon.jpg",
    Alerts: {
        MetalAlert: {
            img: alertImagesFolder + "metalAlert.gif",
            audio: alertAudioFolder + "metalAlert.ogg",
            duration: 4000,
        },
        Musical: {
            img: alertImagesFolder + "musical.gif",
            audio: alertAudioFolder + "musical.ogg",
            duration: 4000,
        },
        Singout: {
            img: alertImagesFolder + "singout.gif",
            audio: alertAudioFolder + "singout.ogg",
            duration: 4000,
        },
        Praise: {
            img: alertImagesFolder + "praise.gif",
            audio: alertAudioFolder + "praise.mp3",
            duration: 4000,
        },
        Butcher:{
            video: alertWebmFolder + "newbutcher.webm"
        }
    }
}

export default Assets;
