const requestTwitchIdShare = (twitch: any)  => {
    if (!twitch){
        console.error("Can't request ID share, the twitch object is null or undefined");

        return;
    }

    if (twitch.viewer.isLinked){
        console.log("Twitch viewer already linked");

        return;
    }

    twitch.actions.requestIdShare();
}

const TwitchHelper = {
    requestTwitchIdShare
}
export default TwitchHelper;