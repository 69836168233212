import React from 'react';
import ButcherRouter from "./router";

function Main(){
    return(
        <div>
            <ButcherRouter/>
        </div>
    )
}

export default Main;