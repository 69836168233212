import ButcherAPI from "../../BackendLayer/ButcherAPI";
import {useFetchAsync} from "../../CommonUtils/Fetch";
import {CreateWaitingTracker} from "../../CommonUtils/LongOp";

const butcherDetailsFetcher = (id: string) => {console.log("Fetching!"); return ButcherAPI.GetButcherDetails(id);}
const fetchDetailsError = "FetchDetailsError";

export function useButcherDetails(butcherId: string){
    let {loading, data: butcherDetails, fetchError} = useFetchAsync<any, any>(null, butcherDetailsFetcher, fetchDetailsError, butcherId);
    const loadingTracker = CreateWaitingTracker(loading, fetchError, "Loading brawler details", false);
    return {loadingTracker, butcherDetails}
}