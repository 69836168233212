import {CharityIncentiveType} from "../../ObsComponents/Halovember/OverlayTimersView";
import {useState} from "react";
import {Button, FormControl, FormControlLabel, FormLabel, Input, Radio, RadioGroup} from "@material-ui/core";
import CharityAPI from "../../../BackendLayer/CharityAPI";

export default function HalovemberTimersTriggerer(){
    let [timerType, setTimerType] = useState<string>(CharityIncentiveType.FlashBang.toString());
    let [donorName, setDonorName] = useState<string>("");
    const handleTimerType = (event: any) => {
        setTimerType(event.target.value);
    };

    const [value, setValue] = useState('female');

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    function triggerTimer (type: string, donor: string) {
        if (!donor) {
            donor = "Someone very generous"
        }
        CharityAPI.ActivateHalovemberTimer(parseInt(type), donor);
    }

    return (<div>
        <FormControl component="fieldset">
            <FormLabel component="legend">Incentive type</FormLabel>
                <RadioGroup
                    aria-label="incentive-type"
                    name="radio-buttons-group"
                    defaultValue={CharityIncentiveType.FlashBang.toString()}
                    onChange={handleTimerType}
                >
                    <FormControlLabel value={CharityIncentiveType.Pacifist.toString()} control={<Radio />} label="Pacifist" />
                    <FormControlLabel value={CharityIncentiveType.MoshPit.toString()} control={<Radio />} label="Mosh pit" />
                    <FormControlLabel value={CharityIncentiveType.FlashBang.toString()} control={<Radio />} label="Flash Bang" />
                    <FormControlLabel value={CharityIncentiveType.SwitchUp.toString()} control={<Radio />} label="Switch up!" />
                    <FormControlLabel value={CharityIncentiveType.BackToBasics.toString()} control={<Radio />} label="Back to basics" />
                    <FormControlLabel value={CharityIncentiveType.DizzyGoggles.toString()} control={<Radio />} label="Dizzy Goggles" />
                </RadioGroup>
        </FormControl>
        <Input id={"donorName"} placeholder="donor name" value={donorName} onChange={event => setDonorName(event.target.value)}/>
        <Button color="primary" variant={"contained"} size={"medium"} onClick={(event) => triggerTimer(timerType, donorName)}>Start timer</Button>

    </div>)
}