import {Typography} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import Cropper from "react-easy-crop";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import getCroppedImgTempUrl, {createResized} from "./cropLogic";
import Container from "@material-ui/core/Container";
import CropResult from "./CropResult";
import Modal from "@material-ui/core/Modal";
import {ImageConfig} from "../../Configuration/ImageConfig";

const useStyles = makeStyles((theme) => ({
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        maxHeight: "65vh",
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    sliderLabel: {
        textAlign: "right",
        margin: "0 16px 8px 0"
    },
    button: {
        margin: "8px",
    },
    modalContainer: {
        display: "inline-block",
        margin: "16px",
        padding: "8px",
        outline: "none"
    },
    cropperHint: {
        color: theme.palette.text.secondary
    }
}));

export default function Crop(props){
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [cropTempUrl, setCropTempUrl] = useState(null);
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    const showCroppedImage = async () => {
        try{
            const cropUrl = await getCroppedImgTempUrl(props.src, croppedAreaPixels, rotation, {x: 200, y: 200});
            setCropTempUrl(cropUrl);
        }
        catch (e){
            console.error(e); //TODO: actually show an error dialog
        }
    }
    function resetCrop(){
        setCropTempUrl(null);
    }
    function createImageFileFromBlob(imageBlob){
        const fileUrl = window.URL.createObjectURL(imageBlob);
        const imageFile = new File([imageBlob], imageBlob.name);
        return {imageFile, fileUrl}
    }

    async function submitCrop(){
        const {blob: resizedCrop, url: resizedCropUrl} = await createResized(cropTempUrl, ImageConfig.CropMaxSize, false);
        const {imageFile: cropFile, fileUrl: cropUrl} = createImageFileFromBlob(resizedCrop);
        const {blob: thumbnail} = await createResized(resizedCropUrl, ImageConfig.ThumbnailSize, false);
        const {imageFile: thumbnailFile} = createImageFileFromBlob(thumbnail);
        const {blob: fullImage} = await createResized(props.src, ImageConfig.OriginalMaxSize, true);
        const {imageFile: fullImageFile} = createImageFileFromBlob(fullImage);
        props.saveCrop({picture: cropFile, thumbnail: thumbnailFile, original: fullImageFile}, cropUrl);
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            {cropTempUrl &&
            <Modal open={!!cropTempUrl} BackdropProps={{className : classes.modalContainer}}>
                <Container className={classes.modalContainer}>
                        <CropResult
                            croppedImageSrc={cropTempUrl}
                            acceptCrop={() => submitCrop()}
                            cancelCrop={() => resetCrop()}
                        />
                </Container>
            </Modal>}
            <div className={classes.cropContainer}>
                <Cropper
                    image={props.src}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={3 / 3}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <Container align={"center"}>
                <Typography className={classes.cropperHint} variant={"subtitle1"}>Drag and scroll</Typography>
                <div id={"controlBars"} style={window.innerHeight < 480 ? {display: "none"} : {}}>
                    <Grid container direction={"row"} justify={"center"}>
                        <Grid item xs={4} sm={3} md={2} lg={1}>
                            <div className={classes.sliderLabel}>
                            <Typography
                                variant="overline"
                            >
                                Zoom
                            </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <Slider
                                value={zoom}
                                min={0.1}
                                max={10}
                                step={0.1}
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction={"row"} justify={"center"}>
                        <Grid item xs={4} sm={3} md={2} lg={1}>
                            <div className={classes.sliderLabel}>
                                <Typography
                                    variant="overline"
                                    align={"center"}
                                >
                                    Rotation
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                onChange={(e, rotation) => setRotation(rotation)}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Button variant="contained" color={"primary"} onClick={() => showCroppedImage()} className={classes.button}>Preview</Button>
                <Button variant="contained" onClick={props.cancel} className={classes.button}>Cancel</Button>
            </Container>
        </React.Fragment>)
}