import {ThemeProvider} from "@material-ui/core/styles";
import {websiteTheme, extensionTheme} from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";

export interface ThemeWrapperProps {
    isExtension?: boolean,
    children: any,
}

export default function ThemeWrapper(props: ThemeWrapperProps){
    return(
        <ThemeProvider theme={props.isExtension ? extensionTheme : websiteTheme}>
            <CssBaseline/>
            {props.children}
        </ThemeProvider>
    );
}