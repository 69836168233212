import React, {useState} from "react"
import WaitingContainer from "../../../Wrappers/WaitingContainer";
import LogsAPI, {HomykEventLogEntry} from "../../../BackendLayer/LogsAPI";
import useLongOp from "../../../CommonUtils/LongOp";
import {LogEntryToJSX} from "./LogsFormatter";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LogsRangePicker from "./LogsRangePicker";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    date: {
        margin: "0 8px"
    },
    userName: {
        fontWeight: "bold",
        color: "#5bd911"
    },
    tokenType: {
        fontWeight: "bold",
        color: "#ff6464"
    },
    extraInfo: {
        fontWeight: "bold",
        color: "#a9b7ff"
    },
    action: {
        fontWeight: "bold",
        color: "#ece224"
    },
    payment: {
        fontWeight: "bold",
        color: "#127504",
    }
}));

function doesEntryHaveFilter(entry: HomykEventLogEntry, filter: string){
    filter = filter.toLowerCase();
    return (entry.extraInfo?.toLowerCase().includes(filter) ?? false)
    || (entry.userDisplayName?.toLowerCase().includes(filter) ?? false)
    || (entry.tokenTier?.toString().toLowerCase().includes(filter) ?? false);
}

export default function LogsView(){
    const classes = useStyles();
    let [logs, setLogs] = useState<HomykEventLogEntry[]>([]);
    let [filter, setFilter] = useState<string>("");
    const getLogsOp = useLongOp<HomykEventLogEntry[]>("Getting logs");
    function getLogs(from: string, to: string){
        // @ts-ignore
        getLogsOp.runner(() => {
            return LogsAPI.GetLogs(from, to)
                .then((result) => {setLogs(result.data)})
                .catch((error) => {console.error("Error while attempting to get logs: ", error)})
        });
    }

    const filteredEntries = filter ? logs?.filter(entry => doesEntryHaveFilter(entry, filter)) : logs;

    return (
        <div>
            <LogsRangePicker onSubmit={getLogs}/>
            <TextField
                id = "filter"
                type="text"
                placeholder={"filter"}
                value={filter}
                onChange = {(event) => setFilter(event.target.value)}
            />
            <WaitingContainer waitingTrackers={[getLogsOp.waitingTracker]}>
                {filteredEntries?.map((logEntry: HomykEventLogEntry) => <div key={logEntry.id}>{LogEntryToJSX(logEntry, classes)}</div>)}
            </WaitingContainer>
        </div>)
}