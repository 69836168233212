import React from "react"
//import ImageUtils from "../utils/ImageUtils";

export class ImageLoadingComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            url: this.props.url,
            loadingState: "notLoading",
            error: null,
            infoMessage: "",
            buttonMessage: "Load!"
        }
    }
    imageLoadSuccess(img){
        this.setState({buttonMessage: "Load!", infoMessage: "Loaded!"})
        this.props.onImageLoaded(this.props.optionId, img, this.state.url);
        this.props.onChange(this.props.optionId, "offsetY", -img.height/2);
    }
    onRemoveImage(){
        this.setState(
            {
                buttonMessage: "Load!",
                error: "",
                url: ""
            });
        this.props.onImageLoaded(this.props.optionId, null, "");
    }

    imageLoadError(){
        this.setState({
            error: "Something went wrong loading the image. Double check the url",
            buttonMessage: "Try again"
        })
    }

    loadImage(url) {
        this.setState({buttonMessage: "Loading!"});
        const img = new Image();
        img.src = url;
        return new Promise((resolve, reject) => {
            img.onload = () => {
                resolve(img);
            }
            img.onerror = () => reject();
        });
    }

    onChange(event, isNumber = false){
        let value = isNumber ? event.target.valueAsNumber : event.target.value;
        this.props.onChange(this.props.optionId, event.target.name, value);
    }

    onLoadImage(){
        this.setState(
            {
                buttonMessage: "Loading!",
                error: "",
                infoMessage: ""
            });
        return this.loadImage(this.state.url)
            .then((result) => this.imageLoadSuccess(result))
            .catch((error) => this.imageLoadError());
    }

    render(){
        return (
            <div className={"inlinePopUp"}>
                <div>
                    <label>URL: <input type={"text"} value={this.state.url} name={"imageUrl"}
                                       placeholder={"URL of the image to use"} onChange={(event) => this.setState({url: event.target.value})}/></label>
                </div>
                {this.state.error ? (<div className={"error"}>{this.state.error}</div>) : ""}
                <div>
                    <input type={"button"} value={this.state.buttonMessage} onClick={() => this.onLoadImage()}/><span className={"info"}>{this.state.infoMessage}</span>
                </div>
                <span className={"subHeader"}>Offset: </span>
                <div>
                    <div><label>X: <input type="range" min="-800" max="800"
                                                                  step="1" name={"offsetX"}
                                                                  value={this.props.offsetX}
                                                                  onChange={(event) => this.onChange(event, true)}/></label></div>
                    <div><label>Y: <input type="range" min="-800" max="800"
                                                                  step="1" name={"offsetY"}
                                                                  value={this.props.offsetY}
                                                                  onChange={(event) => this.onChange(event, true)}/></label></div>
                </div>
                <div>
                    <div><label className={"subHeader"}>Scale </label></div>
                    <div><input type="range" min="0" max="4" step="0.05"
                                                                         name={"scale"}
                                                                         value={this.props.scale}
                                                                         onChange={(event) => this.onChange(event, true)}/></div>
                </div>
                <div>
                    <div><label className={"subHeader"}>Angle </label></div>
                    <div><input type="range" min="0" max={Math.PI * 2} step="0.01"
                                name={"angle"}
                                value={this.props.angle}
                                onChange={(event) => this.onChange(event, true)}/></div>
                </div>
                <div>
                    <input type={"button"} value={"Remove image"} onClick={this.onRemoveImage.bind(this)}/>
                    <input type={"button"} value={"Close"} onClick={this.props.onClose}/>
                </div>
            </div>
        )
    }
}