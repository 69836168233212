import axios from 'axios'
import {backendConfig} from "./Config";
import {Debug} from "../Constants/DevelopmentConstants";

const baseURL = backendConfig.getBaseRoute();
if (Debug) { console.log("baseURL: " + baseURL) }
const authUrl = baseURL + "Auth/";
const instance = axios.create(({
    withCredentials: true
}))

type role = "Viewer" | "Mod" | "Broadcaster" | "Developer";

export interface UserInfo {
    displayName: string;
    avatarUrl: string;
    butcherTokens: number;
    brutalButcherTokens: number;
    roles: role[];
    twitchUserId: string;
    extensionTokenId?: string;
}

export interface TemporaryUserAccount {
    twitchUserId: string;
    login: string;
    butcherRedemptionTokens: number;
    advancedButcherRedemptionTokens: number;
}

export type ApiStatus =  "NeedsAuthorization" | "AuthorizedButNotListening" | "Listening";
export interface ApiIntegrationPresentation {
    apiSource: string;
    status: ApiStatus;
}

function GetCurrentUserInfo(){
    return instance.get<UserInfo | null>(authUrl + "CurrentUserInfo");
    // return new Promise((resolve, reject) => setTimeout(() => resolve(getMockUserData()), 200));
}

function Login(authorizationCode: string) {
    return instance.post<void>(authUrl + "login?authorizationCode=" + authorizationCode);
}

export type LoginThroughExtensionResult = UserInfo | "NO_USER_ID" | null;
function LoginThroughExtensionIdToken(idToken: string){
    return instance.post<LoginThroughExtensionResult>(authUrl + "extensionPassiveLogin?idToken=" + idToken)
}
function Logout(){
    return instance.post<void>(authUrl + "logout");
}

function GetTwitchUserId(userName: string){
    return instance.get<string>(authUrl + "userTwitchId/" + userName);
}

function GetTwitchOauthBaseLink(): string{
    return "https://id.twitch.tv/oauth2/authorize?" +
        "response_type=code" +
        "&client_id=" + backendConfig.twitchAppClientId +
        "&state=asdf1";
}
// TODO: move this to config
function GetTwitchLoginLink(): string {
    return GetTwitchOauthBaseLink()
        + "&scope=openid"
        + "&redirect_uri="+ buildRedirectUri() + "%2FloggingRedirect";
}

function buildRedirectUri() : string{
    let redirectUri = backendConfig.protocol + "%3A%2F%2F" + backendConfig.host;
    if (backendConfig.frontendPort){
        redirectUri += "%3A" + backendConfig.frontendPort;
    }
    return redirectUri;
}

function GetAllActiveUserAccounts(){
    return instance.get<UserInfo[]>(authUrl + "users");
}

const AuthAPI = {
    GetCurrentUserInfo,
    Login,
    LoginThroughExtensionIdToken,
    Logout,
    GetTwitchUserId,
    GetTwitchLoginLink,
    GetAllActiveUserAccounts,
}

export default AuthAPI;