import React, {useRef, useState} from "react";
import {ButcherAlertMessage, ButcherAlertType, useButcherAlertWebsockets} from "./AlertsLogic";

export interface ControlPanelAlertBox{
    callAttention: (interval: number, totalRepetitions: number, currentRepetitions: number) => void,
}



export default function ControlPanelAlertbox(props: ControlPanelAlertBox){
    let [isDisconnected, setIsDisconnected] = useState(false);

    const callAttention = () => {
        props.callAttention(500, 10, 0);
    }

    function onAlert(message: ButcherAlertMessage) {
        console.log("Received butcherAlertMessage", message);
        switch (message.type){
            case ButcherAlertType.EndOfBreakWithEnqueuedButchers:
            case ButcherAlertType.EnqueuedFirstButcher:
                callAttention();
                break;
            case ButcherAlertType.ActivationWhileSessionIsActive:
            case ButcherAlertType.EnqueuedButcherWhileAlreadyReadyToStart:
            case ButcherAlertType.EnqueuedButcherWhileOnBreak:
                callAttention();
                break;
            case ButcherAlertType.NewProposal:
                callAttention();
                break;
        }
    }

    useButcherAlertWebsockets(onAlert,
        () => setIsDisconnected(true));

    return (<div>
        {isDisconnected ? "ALERT BOX IS DISCONNECTED, REFRESH THE CONTROL PANEL" : "Alert box is connected"}
    </div>)
}