import Link from "@material-ui/core/Link";
import React from "react";
import Button from "@material-ui/core/Button";

export default function ObsComponentsIndex()
{
    return (
        <div style={{margin: "8px"}}>
            <div style={{margin: "8px"}}><Link color="inherit" href={"/generalAlertBox"}><Button color="primary" variant="contained">Alert Box for channel points</Button></Link></div>
            <div style={{margin: "8px"}}><Link color="inherit" href={"/butcherAlertBox"}><Button color="primary" variant="contained">Alert Box for Butchers</Button></Link></div>
            <div style={{margin: "8px"}}><Link color="inherit" href={"/butcherSidebar"}><Button variant="contained">Butcher Sidebar (small)</Button></Link></div>
            <div style={{margin: "8px"}}><Link color="inherit" href={"/butcherSidebarBig"}><Button variant="contained">Butcher Sidebar (big)</Button></Link></div>
            <div style={{margin: "8px"}}><Link color="inherit" href={"/halovemberTimers"}><Button variant="contained">Halovember Timers</Button></Link></div>
        </div>
    )
}