import {WaitingTracker} from "../CommonUtils/LongOp";

export function GetWaitingFlags(waitingTrackers: WaitingTracker[]){
    let waiting = false;
    let error = null;
    let message = null;
    let showContentWhileWaiting = true;
    if (waitingTrackers){
        for (const tracker of waitingTrackers)
        {
            // TODO: perhaps check first for errors in all, and then bother with loading flags
            if (tracker.executing || tracker.error){
                showContentWhileWaiting = showContentWhileWaiting && tracker.showContentWhileWaiting;
                if (tracker.error){
                    error = tracker.error;
                }
                if (tracker.executing){
                    waiting = true;
                    message = tracker.message ?? "Working on it"
                }
                break;
            }
        }
    }

    return {waiting, error, message, showContentWhileWaiting}
}