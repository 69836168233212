import makeStyles from "@material-ui/core/styles/makeStyles";
import "../fonts/metalFont.css"


const useStyles = makeStyles((theme) => ({
    mainText: {
        fontSize: "1.3em",
        color: "#000",
        textShadow: "2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff",
        fontFamily: "clearMetal7",
    },
    redeemer: {
        color: "#000c92"
    },
    alertName: {
        color: "#000c92"
    }
}))

export default function GenerateAlertText(text: string | undefined)
{
    const classes = useStyles();

    if (!text){
        return <span></span>
    }

    return TextToJSX(text, classes);
}

function TextToJSX(text: string, classes: any)
{
    let tokenizedText = text.split('|');

    return (
        <span className={classes.mainText}>
            {tokenizedText.map((section, index) => {
                if (section.startsWith("%R%")){
                    return <span key={index} className={classes.redeemer}>{section.substr(3)}</span>
                }
                if (section.startsWith("%T%")){
                    return <span key={index} className={classes.alertName}>{section.substr(3)}</span>
                }
                return <span key={index}>{section}</span>
            })}
        </span>)
}