import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React from "react"
import {DirectorStatusButtonConfig, DirectorStatusControlsConfig} from "./DirectorStatusControls";
import DirectorState from "../../Constants/DirectorState";

const useStyles = makeStyles((theme) => ({
    pauseButton: {
        fontSize: "6em"
    }
}))


export interface DirectorStatusControlsSpecificProps extends DirectorStatusControlsConfig{
    abort: () => void,
    changeState: (a: DirectorState) => void,
}

export default function DirectorStatusControlsSpecific(props: DirectorStatusControlsSpecificProps){
    const classes = useStyles();
    const handleButton = (buttonProps: DirectorStatusButtonConfig) => {
         if (buttonProps.isAbortButton){
             props.abort();
         }
         else if (buttonProps.nextState != null)
         {
             props.changeState(buttonProps.nextState)
         }
    }
    return(
    <React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"h5"}>{props.title.text}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button size="large" variant={"contained"} className={props.bigButton.style} disableRipple={props.bigButton.disabled}
                        onClick={() => handleButton(props.bigButton)}>
                    <Typography className={classes.pauseButton}>
                        {props.bigButton.text}
                    </Typography>
                </Button>
            </Grid>
            {!props.smallButton.disabled &&
            <Grid item xs={12}>
                <Button variant={"contained"} className={props.smallButton.style} onClick={() => handleButton(props.smallButton)}>
                    {props.smallButton.text}
                </Button>
            </Grid>}
        </Grid>
    </React.Fragment>
)
}