import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import OrderOption from "../../Constants/OrderOptions";

export interface ButcherSortMenuProps{
    handleClose: (param?: OrderOption) => void,
    anchorEl: HTMLAnchorElement,
    open: boolean
}

export default function ButcherSortMenu(props: ButcherSortMenuProps) {
    return(
    <Menu
        anchorEl={props.anchorEl}
        id="butcher-sort-menu-appbar"
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open= {props.open}
        onClose={() => props.handleClose()}
    >
        <MenuItem  onClick={() => props.handleClose(OrderOption.Name)}>Sort by Name</MenuItem>
        <MenuItem  onClick={() => props.handleClose(OrderOption.Popularity)}>Sort by Popularity</MenuItem>
        <MenuItem  onClick={() => props.handleClose(OrderOption.Pricing)}>Sort by price</MenuItem>
        <MenuItem  onClick={() => props.handleClose(OrderOption.LastSummoned)}>Sort by last time summoned</MenuItem>
        <MenuItem  onClick={() => props.handleClose(OrderOption.Newest)}>Sort by newest</MenuItem>
    </Menu>)
}
