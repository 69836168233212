import makeStyles from "@material-ui/core/styles/makeStyles";
import {useButcherData, useButcherWebsockets} from "./ButcherBrowserState";
import WaitingContainer from "../../Wrappers/WaitingContainer";
import ButcherCardGrid from "../ButcherCardGrid";
import HomykAppBar from "../HomykAppBar";
import React from "react"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {StateSetter} from "../../CommonUtils/CommonTypes";
import {UserDataHandlers} from "../../Views/ButcherBrowseView/ButcherBrowseViewState";
import {DirectorDataHandlers} from "../../CommonUtils/DataFetchers/DirectorData";
import {PassiveLoginState} from "../../Views/ExtensionView/ExtensionViewState";

const useStyles = makeStyles((theme) => ({
    newButcherButton: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.up("lg")]: {
            bottom: theme.spacing(4),
            right: theme.spacing(4),
        }
    },
    addButcherIcon: {
        margin: "8px",
    },
    pageButton: {
        width: "90%",
        margin: "auto",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        },
        color: "white",
    }
}))

export interface ButcherBrowserProps {
    embedded?: boolean,
    setHoldHover?: StateSetter<boolean>,
    userData: UserDataHandlers,
    directorData: DirectorDataHandlers,
    requestTwitchIdShare?: () => void,
    paginated: boolean,
    onDoneBrowsing: () => void,
    isMobile?: boolean,
    passiveLoginState?: PassiveLoginState,
    currentPageNumber: number,
    setCurrentPageNumber: (newPage: number) => void,
}

export default function ButcherBrowser(props: ButcherBrowserProps){
    let {loadingTracker: loadingUserDataTracker, currentUserData} = props.userData;
    let { loadingTracker: stateLoadingTracker } = props.directorData;
    const butcherData = useButcherData();
    let {loadingTracker: loadingButchersTracker, butchers: butchersSource, searchFilter, setSearchFilter, setSearchOrder,
        modifyButcher } = butcherData;

    let cardSize = 180;
    if (window.innerHeight < 700){
        cardSize = 120;
    }
    const heightRowBreakpoint = props.isMobile ? 460 : 570;
    let rows = window.innerHeight < heightRowBreakpoint ? 1 : 2;
    let pageSize = Math.trunc(window.innerWidth / (cardSize + 12)) * rows;

    useButcherWebsockets(butcherData, props.userData, props.directorData);

    const classes = useStyles();

    const paginated = props.paginated;
    let butchers = butchersSource;
    if (paginated && butchersSource){
        butchers = butchersSource.slice(pageSize * (props.currentPageNumber - 1), pageSize * props.currentPageNumber);
        if (props.currentPageNumber > Math.ceil(butchersSource.length / pageSize)){
            props.setCurrentPageNumber(Math.ceil(butchersSource.length / pageSize));
        }

        if (butchersSource.length > 0 && props.currentPageNumber === 0){
            props.setCurrentPageNumber(1);
        }
    }

    const nextPage = () => props.setCurrentPageNumber(props.currentPageNumber + 1);
    const prevPage = () => props.setCurrentPageNumber(props.currentPageNumber - 1);

    return(
        <WaitingContainer nonDisruptive={props.embedded} waitingTrackers={[loadingUserDataTracker, loadingButchersTracker, stateLoadingTracker]}>
            <HomykAppBar onClickBack={props.onDoneBrowsing}
                         embedded={props.embedded ?? false}
                         userData={currentUserData}
                         searchFilter={searchFilter}
                         setSearchFilter={setSearchFilter}
                         setSearchOrder={setSearchOrder}
                         isMobile = {props.isMobile}
            />

            <ButcherCardGrid embedded={props.embedded ?? false}
                             onDoneBrowsing={props.onDoneBrowsing}
                             butchers={butchers}
                             modifyButcher={modifyButcher}
                             requestTwitchIdShare={props.requestTwitchIdShare}
                             passiveLoginState={props.passiveLoginState}
                             userData={currentUserData}
                             cardWidth = {cardSize}
                             minHeight = {(cardSize + 36) * rows}
                             paginated = {paginated}
            />
            {paginated &&
            <Grid container>
                <Grid item xs={6}>
                    {props.currentPageNumber > 1 &&
                    <Button variant={"contained"} className={classes.pageButton} onClick={prevPage}>Previous page</Button>}
                </Grid>
                <Grid item xs={6}>
                    {butchers && ((butchersSource?.length ?? 0) > (props.currentPageNumber * pageSize)) &&
                        <Button variant={"contained"} className={classes.pageButton} onClick={nextPage}>Next page</Button>
                    }
                </Grid>
            </Grid>}

        </WaitingContainer>);
}