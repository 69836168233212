import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    useActivationWebsockets,
    usePassiveLogin
} from "./ExtensionViewState";
import {useButcherActivations} from "../../CommonUtils/DataFetchers/ButcherActivations";
import {useDirectorData} from "../../CommonUtils/DataFetchers/DirectorData";
import WaitingContainer from "../../Wrappers/WaitingContainer";
import ButcherBrowser from "../../Components/ButcherBrowser/ButcherBrowser";
import ExtensionView from "./ExtensionView";
import TwitchHelper from "../../CommonUtils/Twitch/TwitchHelper";
// TODO: scenario where there's a shit ton of butchers, add ellipsis or second column

const mainOpacity = 0.6;
const useStyles = makeStyles((theme) => ({
    regularText: {
        display: "inline-block",
        borderRadius: "0px 12px 12px 0px",
        padding: "2px 12px 0px 12px",
        fontSize: "1em",
        opacity: 1,
        margin: "8px 0px",
    },
    lowOpacity: {
        opacity: mainOpacity
    },
    detailedViewContainer: {
        position: "fixed"
    },
    collapsedDetailedView:{
        opacity: mainOpacity,
    },
}))

export interface MobileViewProps{

}
export default function MobileView(props: MobileViewProps){
    const classes = useStyles();
    let butcherActivationData = useButcherActivations();
    let { activationData, loadingTracker} = butcherActivationData;
    let directorData = useDirectorData();
    let { loadingTracker: stateLoadingTracker, fullDirectorData} = directorData;
    let { loadingTracker: loginWaitingTracker, login, passiveLoginState, currentUserData, modifyTokenAmount, hasTokens, hotReload} = usePassiveLogin();
    let userDataBundle = {currentUserData, loadingTracker: loginWaitingTracker, modifyTokenAmount, hotReload} // TODO: modifyTokenAMount function needed?

    let {showNewRedemptionButton, cancelRedemptionNotification} = useActivationWebsockets(
        butcherActivationData,
        directorData,
        currentUserData);

    // @ts-ignore Twitch is added to window
    const twitch = window.Twitch ? window.Twitch.ext : null;
    const requestTwitchIdShare = () => TwitchHelper.requestTwitchIdShare(twitch);
    useEffect(() => {
        if (currentUserData === null && twitch) {
            twitch.onAuthorized((auth: {token: string}) => {
                login(auth.token, twitch)
            })
        }
    }, [currentUserData]);

    // token reminder logic
    let tokenReminderControls = {
        hasTokens: hasTokens,
        dismissTokenReminder: cancelRedemptionNotification,
        tokenReminderDismissButtonActive: () => showNewRedemptionButton && hasTokens()
    }

    // collapsed view controls
    let [showButcherBrowser, setShowButcherBrowser] = useState(false);
    let [currentPage, setCurrentPage] = useState<number>(1);
    
    return (
        <React.Fragment>
            <WaitingContainer waitingTrackers={[loadingTracker, stateLoadingTracker]}>
                {/*// TODO: extract this div to a component*/}
                <div className={classes.detailedViewContainer} style={{margin: "auto", width: "100%"}}>
                    {showButcherBrowser ?
                        <div style={{width: "100vw"}}>
                            <ButcherBrowser
                                embedded
                                requestTwitchIdShare={requestTwitchIdShare}
                                onDoneBrowsing={() => setShowButcherBrowser(false)}
                                paginated = {true}
                                directorData={directorData}
                                userData={userDataBundle}
                                isMobile={true}
                                passiveLoginState={passiveLoginState}
                                currentPageNumber={currentPage}
                                setCurrentPageNumber={setCurrentPage}
                            />
                        </div>
                        :
                        <ExtensionView directorData={fullDirectorData}
                                       butcherActivationData={activationData}
                                       {...tokenReminderControls}
                                       collapse={() => {}}
                                       startBrowsing = {() => setShowButcherBrowser(true)}
                                       isMobile
                        />
                    }
                </div>
            </WaitingContainer>
        </React.Fragment>
    )
}