import axios from "axios";
import {backendConfig} from "./Config";
import {CharityIncentiveType} from "../Views/ObsComponents/Halovember/OverlayTimersView";

const baseURL = backendConfig.getBaseRoute();
const url = baseURL + "Redemption/";

const instance = axios.create(({
    withCredentials: true
}))

function ActivateHalovemberTimer(type: CharityIncentiveType, donor: string){
    return instance.put<void>(url + "halovember/" + type + "?donorName=" + donor);
}

const CharityAPI = {
    ActivateHalovemberTimer
}

export default CharityAPI;