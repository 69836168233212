import {PricingTier} from "../Constants/PricingCategories";
import {UserInfo} from "../BackendLayer/AuthAPI";

export const verifyEnoughTokens = (userData: UserInfo, pricingCategory: PricingTier) => {
    if (userData == null){
        return false;
    }

    if (pricingCategory === null || pricingCategory === undefined){
        return userData.butcherTokens > 0 && userData.brutalButcherTokens > 0;
    }

    // TODO: had to cast to the enum becasue for some reason it was interpreting pricingCategory as Brütal, not the whole enum.
    if (pricingCategory as PricingTier === PricingTier.Base){
        return userData.butcherTokens > 0;
    }
    if (pricingCategory as PricingTier === PricingTier.Brutal){
        return userData.brutalButcherTokens > 0;
    }

    return false;
}