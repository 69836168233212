import {UserInfo} from "../../../BackendLayer/AuthAPI";
import {useState} from "react";
import {PricingTier} from "../../../Constants/PricingCategories";
import {Button, MenuItem, Select, Switch} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButcherAPI from "../../../BackendLayer/ButcherAPI";



export interface GiveTokensFormProps extends UserInfo{

}
const useStyles = makeStyles((theme) => ({
    tokenAmountField: {
        width: "4em",
        margin: "8px"
    },
    typeSelectField: {
        width: "10em",
        margin: "8px",
    },
    switchField: {
        margin: "8px"
    },
    container: {
        padding: "16px"
    },
    button: {
        margin: "8px"
    },
    errorButton:{
        margin: "8px",
        backgroundColor: "red"
    }
}))

enum GiveTokensFormStatus{
    Idle,
    Changing,
    Error,
    Complete
}
export default function GiveTokensForm(props: GiveTokensFormProps){
    const classes = useStyles();
    let [amount, setAmount] = useState(0)
    let [type, setType] = useState(PricingTier.Base);
    let [isRemoval, setIsRemoval] = useState(false);
    let [formState, setFormState] = useState(GiveTokensFormStatus.Idle);

    const submit = () => {
        const actualAmount = isRemoval ? -1 * amount : amount;
        setFormState(GiveTokensFormStatus.Changing);
        ButcherAPI.ChangeTokenAmount(props.twitchUserId, type, actualAmount)
            .then(() => setFormState(GiveTokensFormStatus.Complete))
            .catch(() => setFormState(GiveTokensFormStatus.Error))
    }


    return (
        <div>
        <div className={classes.container}>
            <div>
            Amount of tokens to give:
            <input className={classes.tokenAmountField} type={"number"} value={amount} onChange={(event) => setAmount(parseInt(event.target.value))}/>
            </div>
            <div>
            Token type:
            <Select className={classes.typeSelectField} value={type} onChange={(event) => setType(event.target.value as PricingTier)}>
                <MenuItem key={"normal"} value={PricingTier.Base}>Base</MenuItem>
                <MenuItem key={"normal"} value={PricingTier.Brutal}>BRÜTAL</MenuItem>
            </Select>
            </div>
            <div>
                Remove tokens instead?:
                <Switch color="primary" className={classes.switchField} checked={isRemoval} onChange={(event) => setIsRemoval(event.target.checked)}/>
            </div>
            <div>
                {formState === GiveTokensFormStatus.Complete && <div>DONE! The extension and site should reload for the user, but tell them to reload anyway.</div>}
                {formState === GiveTokensFormStatus.Error && <div>Something went wrong. Reload and try again, maybe.</div>}
                {formState === GiveTokensFormStatus.Idle &&
                    <Button variant={"contained"} color={"primary"} className={classes.button} onClick={submit}>{isRemoval ? "Tak つ ◕_◕ ༽つ" : "Giv つ ◕_◕ ༽つ"}</Button>}
                {formState === GiveTokensFormStatus.Changing &&
                    <Button variant={"contained"} color={"primary"} className={classes.button}>{isRemoval ? "Removing" : "Giving"} tokens, hold on...</Button>}
                {formState === GiveTokensFormStatus.Idle && <Button variant={"contained"} color={"secondary"} className={classes.button} onClick={() => window.location.reload()}>Cancel</Button>}
                {(formState === GiveTokensFormStatus.Complete || formState === GiveTokensFormStatus.Error) && <Button variant={"contained"} color={"secondary"} className={classes.button} onClick={() => window.location.reload()}>Go back</Button>}
            </div>
        </div>
    </div>)
}