import OrderOption from "../../Constants/OrderOptions";
import {ButcherPresentation} from "../../BackendLayer/ButcherAPI";

function filter(butchers: ButcherPresentation[] | null, filter: string){
    if (!butchers || !filter ){
        return butchers;
    }
    filter = filter.toLowerCase();
    return butchers.filter(butcher =>
        butcher.name?.toLowerCase().includes(filter)
        || butcher.description?.toLowerCase().includes(filter)
        || butcher.tagline?.toLowerCase().includes(filter)
        || butcher.tags?.toLowerCase().includes(filter)
        || butcher.origin?.toLowerCase().includes(filter)
    );
}

function compareStrings(a: string, b: string) : number
{
    let aUpperCase = a.toUpperCase();
    let bUpperCase = b.toUpperCase();
    if (aUpperCase < bUpperCase){
        return -1;
    }
    if (aUpperCase > bUpperCase){
        return 1;
    }
    return 0;
}

function orderActiveFirst(butchers: ButcherPresentation[] | null){
    if (!butchers){
        return butchers;
    }

    return butchers.sort((butcherA: ButcherPresentation, butcherB: ButcherPresentation) => butcherB.status - butcherA.status);
}

function order(butchers: ButcherPresentation[] | null, orderOption: OrderOption | null){
    if (!butchers){
        return butchers;
    }
    switch(orderOption)
    {
        case OrderOption.Popularity:
            return butchers.sort((a, b) => b.timesSummoned - a.timesSummoned)
        case OrderOption.Pricing:
            return butchers.sort((a, b) => b.pricingCategory - a.pricingCategory);
        case OrderOption.LastSummoned:
            // @ts-ignore (date arithmetic is correct)
            return butchers.sort((a, b) => new Date(b.lastSummonedAt) - new Date(a.lastSummonedAt));
        case OrderOption.Newest:
            // @ts-ignore (date arithmetic is correct)
            return butchers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        case OrderOption.Name:
            return butchers.sort((a, b) => compareStrings(a.name ?? "zzz", b.name ?? "zzz"))
        default:
            return butchers.sort((a, b) => b.timesSummoned - a.timesSummoned)

    }
}

const ButcherLogic = {
    filter,
    order,
    orderActiveFirst,
}

export default ButcherLogic;