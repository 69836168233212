import ButcherAPI, {ButcherActivation} from "../../BackendLayer/ButcherAPI";
import {useFetchAsync} from "../Fetch";
import {CreateWaitingTracker, WaitingTracker} from "../LongOp";
import {subscriptionSetter} from "../../BackendLayer/WebsocketsWrapper";

export interface ActivationsDataHandlers{
    activationData: ButcherActivation[] | null,
    loadingTracker: WaitingTracker,
    addActivation: (newActivation: ButcherActivation) => void,
    removeActivation: (butcherId: string) => void,
    hotReload: () => void,
}

const activationsFetcher = () => ButcherAPI.GetAllCurrentButcherActivations();
const fetchActivationsError: string = "Could not fetch the currently active brawlers";
export function useButcherActivations() : ActivationsDataHandlers
{
    let {loading, data: activationData, setData: setActivationData, fetchError, hotReload} = useFetchAsync<ButcherActivation[], void>(null, activationsFetcher, fetchActivationsError);
    const loadingTracker = CreateWaitingTracker(loading, fetchError, "Loading active brawlers", false);

    const addActivation = (newActivation: ButcherActivation) => {
        if (activationData !== null){
            const existingActivationIndex = activationData.findIndex(activation => activation.butcher.id === newActivation.butcher.id);
            if (existingActivationIndex !== -1){
                activationData[existingActivationIndex] = newActivation;
            }
            else{
                activationData.push(newActivation);
            }
            setActivationData(activationData.slice());
        }
    }

    const removeActivation = (butcherId: string) => {
        if (activationData !== null){
            setActivationData(activationData.filter(activation => activation.butcher.id !== butcherId));
        }
    }
    return { activationData, loadingTracker, addActivation, removeActivation, hotReload}
}

export function setActivationsSubscriptions(
            createOrResetSubscription: subscriptionSetter,
                activations: ButcherActivation[] | null,
            addActivation: (newActivation: ButcherActivation) => void,
            removeActivation: (id: string) => void,
            extraOnActivate?: (newActivation: ButcherActivation) => void | null,
            extraOnEnqueueing?: (newEnqueued: ButcherActivation) => void | null,
            extraOnDeactivate?: (id: string) => void | null)
{
    createOrResetSubscription("NewActivation", "ButcherActivationSync", (butcherActivation) => {
        ButcherAPI.CompleteImageUrls(butcherActivation.butcher);
        addActivation(butcherActivation)
        if (extraOnActivate) {
            extraOnActivate(butcherActivation)
        }
    });
    createOrResetSubscription("Enqueued", "ButcherActivationSync", (butcherActivation) => {
        ButcherAPI.CompleteImageUrls(butcherActivation.butcher);
        addActivation(butcherActivation)
        if (extraOnEnqueueing) {
            extraOnEnqueueing(butcherActivation);
        }
    });
    createOrResetSubscription("Deactivation", "ButcherActivationSync", (deactivatedButcherId) => {
        removeActivation(deactivatedButcherId)
        if (extraOnDeactivate) {
            extraOnDeactivate(deactivatedButcherId)
        }
    });
}