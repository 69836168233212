import AuthAPI, {UserInfo} from "../../BackendLayer/AuthAPI";
import {Fetcher, useFetchAsync} from "../../CommonUtils/Fetch";
import {CreateWaitingTracker, WaitingTracker} from "../../CommonUtils/LongOp";
import {PricingTier} from "../../Constants/PricingCategories";

export interface UserDataHandlers {
    currentUserData: UserInfo | null,
    loadingTracker: WaitingTracker,
    modifyTokenAmount: (type: PricingTier, amount: number) => void,
    hotReload: () => void,
}

const preFetchUserData = null;
const userDataFetcher: Fetcher<UserInfo | null> = () => AuthAPI.GetCurrentUserInfo();
const fetchUserDataError = () => "FetchUserDataError";
export function useUserData() : UserDataHandlers
{
    let {loading, data: currentUserData, setData: setCurrentUserData, fetchError, hotReload} = useFetchAsync<UserInfo | null, void>(preFetchUserData, userDataFetcher, fetchUserDataError());

    const loadingTracker= CreateWaitingTracker(loading, fetchError, "Loading user data", false);
    // function increaseTokens(amount) {
    //     setCurrentUserData(prevState => ({...prevState, butcherTokens: prevState.butcherTokens + amount}));
    // }

    function modifyTokenAmount(type: PricingTier, amount: number) {
        if (!currentUserData) {
            console.error("Attempted to modify token amount, but user info was not yet fetched");
        } else {
            if (type === PricingTier.Base) {
                setCurrentUserData({...currentUserData, butcherTokens: currentUserData.butcherTokens + amount})
            } else if (type === PricingTier.Brutal) {
                setCurrentUserData({...currentUserData, brutalButcherTokens: currentUserData.brutalButcherTokens + amount})
            } else {
                console.error("ModifyTokenAmount called for unknown type" + type);
            }
        }
    }

    return { currentUserData, loadingTracker, modifyTokenAmount, hotReload}
}

