import {SpringValue, useChain, useSpring} from "react-spring";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {animated} from "react-spring";
import Typography from "@material-ui/core/Typography";
import "./fonts/metalFont.css"
import brushStain from "./fonts/brushStain.png"
import {Variant} from "@material-ui/core/styles/createTypography";
import {backendConfig} from "../../BackendLayer/Config";

const useStyles = makeStyles((theme) => ({
    animatedText: {
        position: "absolute",
        top: "15px",
        margin: "16vh 0px",
    },
    textWithGradient: {
        webkitBackgroundClip: "text",
        webkitTextFillColor: "transparent",
    }
}))

interface animation {
    left: SpringValue<string>,
    top: SpringValue<string>,
}

export interface SmashAnimationProps {
    title: string,
    tagline: string,
    imageUrl: string,
    animating: boolean,
    onAnimationEnd: () => void
}
export default function SmashAnimation(props: SmashAnimationProps){

    const classes = useStyles();
    const title = props.title;//"Macho Man";
    const tagline= props.tagline;//"OOH YEAH"
    const duration = 3000;
    const imageURL = backendConfig.getBaseRoute() + props.imageUrl;// "https://www.wwe.com/f/styles/og_image/public/photo/image/2009/05/10206530.jpg";

    const easingFunction = (x: any) => x//1 - Math.pow(1 - x, 4);
    let titleVariant: Variant = "h1";
    let titleBrushOffset = "5px";
    if (title.length > 20){
        titleVariant = "h3"
        titleBrushOffset = "-40px"
    }
    else if (title.length > 10){
        titleVariant = "h2";
        titleBrushOffset = "-20px"
    }

    let animation = useSpring({left: props.animating ? "250px" : "200px",
        top: props.animating ? "300px" : "320px",
        scale: props.animating ? "1.5" : "1",
        config: {duration: duration, easing: easingFunction},
        onRest: () => {
            props.onAnimationEnd();
        }})
    let tagLineAnimation = useSpring({
        left: props.animating ? "280px" : "230px",
        top: props.animating ? "400px" : "420px",
        scale: props.animating ? "1.2" : "1",
        config: {duration: duration, easing: easingFunction}})
    let fadeOut = useSpring({opacity: props.animating ? 0: 1, delay: duration, duration: 2000});
    let pictureAnimation = useSpring({
        // @ts-ignore
        left: props.animating ? "0px" : "50px",
        top: props.animating ? "-20px" : "20px",
        scale: props.animating ? "0.8" : "1",
        config: {duration: duration, easing: easingFunction}})
    return <div>
        {/*<Button variant={"contained"} onClick={() => setAnimating(!!!animating)}>Do the thing</Button>*/}
        {props.animating &&
            <animated.div style={{...fadeOut}}>
                <animated.div style={{position: "fixed", ...pictureAnimation}}>
                    <img src={imageURL} style={{boxShadow: "20px 20px 10px black"}}/>
                </animated.div>
                <animated.div style={{position: "fixed", ...animation}}>
                    <img src={brushStain} style={{position: "absolute", top: titleBrushOffset, left: "-20px", width: "100%"}}/>
                    <Typography variant={titleVariant} className={classes.textWithGradient} style={{
                        fontFamily: "clearMetal7",
                        // background: "linear-gradient(24deg, rgba(237,237,237,1) 0%, rgba(213,213,213,1) 53%, rgba(187,187,187,1) 100%)",
                        transform: `rotate3d(0, -1, 0, 25deg) rotate3d(1, 0, 0, 10deg) skew(-10deg, 0deg)`,
                        textShadow: "5px 5px #000000",
                        color: "#b7b7b7",
                    }}>{title}</Typography>
                </animated.div>
                <animated.div style={{position: "fixed", ...tagLineAnimation}}>
                    <Typography variant={"h3"} className={classes.textWithGradient} style={{
                        fontFamily: "clearMetal7",
                        // background: "linear-gradient(24deg, rgba(237,237,237,1) 0%, rgba(213,213,213,1) 53%, rgba(187,187,187,1) 100%)",
                        transform: "rotate3d(0, -1, 0, 25deg) rotate3d(1, 0, 0, 10deg) skew(-10deg, 0deg)",
                        textShadow: "5px 5px #000000",
                        color: "#2389b3",
                        maxWidth: "70vw"
                    }}>{tagline}</Typography>
                </animated.div>
            </animated.div>

        }
    </div>
}