function drawTextCentered(ctx, text, position, xTotalWidth = 0, xScale = 1)
{
    let width = ctx.measureText(text).width * xScale;
    ctx.fillText(text, (position.x + xTotalWidth/2  - (width/2)) * 1/xScale, position.y);
}

function drawMultiLineText(ctx, text, position, xTotalWidth, lineHeight, lettersToDrawNow = null){
    let lines= divideTextIntoLines(ctx, text, xTotalWidth);
    let lettersDrawn = 0;
    for (let i = 0; i < lines.length; i++){
        if (lettersToDrawNow != null && lettersDrawn + lines[i].length > lettersToDrawNow){
            lines[i] = lines[i].substr(0, lettersToDrawNow - lettersDrawn);
        }
        drawTextCentered(ctx, lines[i], {x: position.x, y: position.y + i* lineHeight}, xTotalWidth, 1);
        lettersDrawn += lines[i].length;
    }
}

function divideTextIntoLines(ctx, text, maxWidth){
    const words = text.split(" ");
    let completeLines = [];
    let lineWidths = [];
    let line = "";
    for(const word of words){
        if (line.length === 0){
            line = word;
            continue;
        }
        let tempLine = line + " " + word;
        let width =ctx.measureText(tempLine).width;
        if (width > maxWidth){
            completeLines.push(line);
            lineWidths.push(ctx.measureText(line).width);
            line = word;
            continue;
        }
        line = tempLine;
    }
    lineWidths.push(ctx.measureText(line).width);
    completeLines.push(line);
    return completeLines;
}

function getRelativeCoords(event, canvas) {
    // this.canvas coords start in the upper left corner
    const rect = canvas.getBoundingClientRect();
    return {
        x: (event.clientX - rect.left),
        y: (event.clientY - rect.top)
    };
}
function isInside(coords, rect){
    return coords && rect && (coords.x > rect.x && coords.x < rect.x + rect.width)
        && (coords.y > rect.y && coords.y < rect.y + rect.height);
}

export default {
    drawTextCentered,
    drawMultiLineText,
    getRelativeCoords,
    isInside
}