import React from "react"

import {useFetchAsync} from "../../CommonUtils/Fetch";
import ConfigurationApi from "../../BackendLayer/ConfigurationAPI";
import WaitingContainer from "../../Wrappers/WaitingContainer";
import useLongOp, {CreateWaitingTracker} from "../../CommonUtils/LongOp";
import ConfigurationForm from "./ConfigurationForm";

const preFetch = null;
const fetcher = () => ConfigurationApi.GetConfiguration();
const fetchErrorMessage = "FetchConfigurationError";
function useConfigurationData(){
    let {loading, data, fetchError} = useFetchAsync(preFetch, fetcher, fetchErrorMessage);
    const loadingTracker= CreateWaitingTracker(loading, fetchError, "Loading configuration", false);
    return {configuration: data, loadingTracker}
}

export interface ExtensionConfigurationViewProps {

}
export default function ExtensionConfigurationView(props: ExtensionConfigurationViewProps)
    {
        const saveOp = useLongOp("Saving your configuration", null, null, false, true);
        const save = (payload: any) => saveOp.runner(() => {
            return ConfigurationApi.UpdateConfiguration(payload);
        })

        let {configuration, loadingTracker} = useConfigurationData();
        return (
            <WaitingContainer showContentsOnError={true} waitingTrackers={[loadingTracker, saveOp.waitingTracker]}>
                <ConfigurationForm save={save} configuration={configuration}/>
            </WaitingContainer>
        )
    }