import Grid from "@material-ui/core/Grid";
import ButcherCard from "./ButcherCard";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import ButcherDetailsComponent from "./ButcherDetailsComponent";
import Popover from "@material-ui/core/Popover";
import {ArrowBack} from "@material-ui/icons";
import {UserInfo} from "../BackendLayer/AuthAPI";
import {ButcherPresentation} from "../BackendLayer/ButcherAPI";
import {PassiveLoginState} from "../Views/ExtensionView/ExtensionViewState";

export interface ButcherCardGridProps {
    paginated: boolean,
    cardWidth: number,
    minHeight: number,
    embedded: boolean,
    butchers: ButcherPresentation[] | null,
    requestTwitchIdShare: (() => void) | undefined,
    onDoneBrowsing: () => void,
    modifyButcher: (butcherId: string, changes: object) => void,
    userData: UserInfo | null,
    passiveLoginState?: PassiveLoginState,
    isMobile?: boolean,
}

export default function ButcherCardGrid(props: ButcherCardGridProps){
    const useStyles = makeStyles((theme) => ({
        grid: {
            margin: "0",
            width: "100%" // this is to prevent the odd horizontal scroll bar,
        },
        popover: {
            width: "50vw",
            border: "2px solid " + theme.palette.primary.main,
            [theme.breakpoints.down("md")]:{
                width: "80vw"
            },
            [theme.breakpoints.down("sm")]:{
                width: "100vw"
            }
        },
        closeButton: {
            position: "absolute",
            left: 0,
            top: 0,
            margin: "8px"
        },
        card: {
            width: "180px",
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                boxShadow: "0 0 5px 5px " + theme.palette.primary.dark,
            },
            margin: "0 0 4px 0"
        },
    }));
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedButcherData, setSelectedButcherData] = useState<ButcherPresentation | null>(null);
    const handleCardClick = (event: any, butcherData: ButcherPresentation) =>{
        setSelectedButcherData(butcherData)
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    }
    const classes = useStyles();
    return (
    <div>
        <Grid container direction="row" className={classes.grid} wrap={"wrap"} justify={"center"} spacing={1} style={props.paginated ? {minHeight: props.minHeight } : {}}>
                {props.butchers?.map((butcherData) => (
                    <Grid key={butcherData.id} item onClick={(event) => handleCardClick(event, butcherData)}>
                        <ButcherCard {...butcherData} cardClass={classes.card} width={props.paginated ? props.cardWidth : null}/>
                    </Grid>
                ))}
        </Grid>
        <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{className: classes.popover}}
            >
            <div className={classes.closeButton} onClick={() => handleClose()}><ArrowBack/></div>
            <ButcherDetailsComponent
                embedded={props.embedded}
                requestTwitchIdShare={props.requestTwitchIdShare}
                passiveLoginState={props.passiveLoginState}
                onError={() => !props.embedded && handleClose}
                onDoneBrowsing={props.onDoneBrowsing}
                modifyButcher={props.modifyButcher}
                userData={props.userData}
                clearSelectedButcher = {() => {setSelectedButcherData(null); setOpen(false);}}
                {...selectedButcherData}
            />
        </Popover>
    </div>
    );
}