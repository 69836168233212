import React, {useState} from "react";
import ButcherEdition from "../../Components/ButcherEdition/ButcherEdition";
import ButcherAPI from "../../BackendLayer/ButcherAPI";
import useLongOp from "../../CommonUtils/LongOp";
import WaitingContainer from "../../Wrappers/WaitingContainer";
import CreationCompleteMessage from "../../Components/ButcherEdition/CreationCompleteMessage";
import {ButcherWithoutPictures} from "../../Components/ButcherEdition/ButcherEditionForm";

function CreateDefaultButcher(){
    return {};
}

export interface ButcherCreationViewProps {
    onComplete: () => void,
    onError: () => void,
    createDirectly: boolean,
    isMobile: boolean,
}

export default function ButcherCreationView(props: any) {
    let saveOp = useLongOp("Creating the new brawler");
    let [showCompletionMessage, setShowCompletionMessage] = useState(false);
    function saveChanges(butcherFormFields: ButcherWithoutPictures, newImageFiles: any) {
        const apiCallParams = {...butcherFormFields, ...newImageFiles};
        
        saveOp.runner(() =>ButcherAPI.CreateNewButcher(apiCallParams),
            "/",
            () => {
                setShowCompletionMessage(true);
                setTimeout(props.onComplete, 8000)
            },
            props.onError);
    }

    return (
        <WaitingContainer showContentsOnError waitingTrackers={[saveOp.waitingTracker]}>
            {showCompletionMessage
            ? <CreationCompleteMessage createDirectly ={props.createDirectly} onDismiss={props.onComplete}/>
            : <ButcherEdition
                                                     butcherDetails={CreateDefaultButcher()}
                                                     saveActionName={"Create"}
                                                     onSave={saveChanges}
                                                     isNewButcher={true}
            />}
        </WaitingContainer>
    )
}