import React from "react";

export default class NamePopUp extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            name: props.name
        }
    }

    updateName(event){
        this.setState({name: event.target.value})
    }

    render() {
        return (<div className={"halfcenter"}>
            <div className={"popup"}>
                <div>
                    Name: <input type="text" value={this.state.name} placeholder={"Enter the name"} onChange={(event) => this.updateName(event)}/>
                </div>
                <div style={{margin: "0.5em"}}>
                    <span className={"button"} onClick={(event) => this.props.onConfirm(this.state.name)}>Confirm</span>
                    <span className={"button"} onClick={() => this.props.onCancel()}>Cancel</span>
                </div>
            </div>
        </div>)
    }
}