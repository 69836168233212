import React from "react"

export class ExportPopUpComponent extends React.Component{
    render(){
        return(
            <div className="popUpComponent">
                <p className={"popUpPrelude"}>Save this text somewhere. You can use it to import the current settings:</p>
                <div className={"exportText"}>
                    {this.props.exportedText}
                </div>
                <input type={"button"} value="Got it!" onClick={this.props.onClose}/>
            </div>)
    }
}