import React, {useState} from "react";
import ButcherEditionForm, {ButcherWithoutPictures} from "./ButcherEditionForm";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Crop from "../PictureUpload/Crop";
import PictureUpload from "../PictureUpload/PictureUpload";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
    butcherEditionContainer: {
        width: "100vh",
        margin: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    cropperModal: {
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translate(-50%, -10%)",
        width: "50%",
        [theme.breakpoints.down('md')]: {
            width: "90%"
        },
        outline: "none"
    },
}));

export interface ButcherEditionProps
{
    isNewButcher: boolean,
    butcherDetails: any, //ButcherDetails | null | ButcherWithoutPictures,
    saveActionName: "Save" | "Create",
    onSave: (param: ButcherWithoutPictures, pictures: any) => void,
}

export default function ButcherEdition(props: any){
    const classes = useStyles();

    // menus
    let [showImageUpload, setShowImageUpload] = useState(false);
    let [imageUploadAnchor, setImageUploadAnchor] = useState<any>(null);
    let [cropping, setCropping] = useState(false);

    // transient data
    let [uploadedImage, setUploadedImage] = useState<any>(null); // crop result. Used to generate the files.
    let [currentPictureRoute, setCurrentPictureRoute] = useState<string | null>(null);

    // generated files
    let [newImageFiles, setNewImageFiles] = useState<any>(null);

    function cancelCropping(){
        hideCroppingMenus();
    }

    function onCropSave(newFiles: any, croppedImageFileUrl: any){
        setNewImageFiles(newFiles);
        setCurrentPictureRoute(croppedImageFileUrl);
        hideCroppingMenus();
    }

    function hideCroppingMenus(){
        setShowImageUpload(false);
        setCropping(false);
        setUploadedImage(null);
        setImageUploadAnchor(null);
    }

    function getButcherImageRoute(){
        // @ts-ignore
        return currentPictureRoute ?? props.butcherDetails?.croppedPictureRoute;
    }

    function saveButcher(butcherFormFields: ButcherWithoutPictures){
        props.onSave(butcherFormFields, newImageFiles)
    }

    return(
        <React.Fragment>
            <Popover open={showImageUpload} onClose={cancelCropping} anchorEl={imageUploadAnchor}>
                <Paper>
                    <PictureUpload cancel={cancelCropping}
                                   setCropSource={(image: any) => {setUploadedImage(image); setCropping(true)}}
                    />
                </Paper>
            </Popover>
            <Modal open={cropping}>
                <Paper className={classes.cropperModal}>
                    <Crop src={uploadedImage} cancel={cancelCropping} saveCrop={onCropSave}/>
                </Paper>
            </Modal>
            <div className={classes.butcherEditionContainer}>
                <ButcherEditionForm saveActionName={props.saveActionName} picture={getButcherImageRoute()} {...props.butcherDetails}
                                    startCropping={(event: any) => {setShowImageUpload(true); setImageUploadAnchor(event.currentTarget)}}
                                    onSave = {saveButcher}
                                    isNewButcher={props.isNewButcher}
                />
            </div>
        </React.Fragment>
    )
}