import React, {useCallback, useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import {DirectorDataPresentation} from "../../BackendLayer/ButcherAPI";

function formatTime(totalMilliseconds: number){
    if (totalMilliseconds <= 0) {
        return "00:00"
    }
    let minutes = Math.floor(totalMilliseconds/(60*1000));
    let seconds = Math.floor((totalMilliseconds%(60*1000)/1000));
    return padZero(minutes) + ":" + padZero(seconds);
}

function padZero(value: number)
{
    if (value < 10){
        return "0" + value;
    }

    return value;
}

export interface TimerProps extends DirectorDataPresentation {
    message?: string,
    className: string
}

export default function Timer(props: any)
{
    let [currentTimeLeft, setCurrentTimeLeft] = useState(props.fullDuration * 1000)

    const getRemainingTime = useCallback( () => {
        const lastTickingMoment = props.isTimerPaused ? Date.parse(props.lastPauseTime) : Date.now();
        const timeSpanned = lastTickingMoment - Date.parse(props.timerStartTime) - props.timeSpentPaused;
        return props.fullDuration - timeSpanned;
    }, [props])

    useEffect(() => {
        const refreshIntervalTime = 100;
        const timeLeft = getRemainingTime();
        setCurrentTimeLeft(timeLeft);
        const timeoutHandler = setInterval(() => {
            const timeLeft = getRemainingTime();
            setCurrentTimeLeft(timeLeft);
            if (timeLeft <= 0){
                clearInterval(timeoutHandler);
            }
        }, refreshIntervalTime);

        return () => {clearTimeout(timeoutHandler)}
    }, [getRemainingTime])

    return (
        <React.Fragment>
            {props.isTimerActive &&
                <Typography variant={"h5"} className={props.className}>
                    {props.message}{formatTime(currentTimeLeft)}
                </Typography>
            }
        </React.Fragment>
    )

}