import {HomykEventLogEntry} from "../../../BackendLayer/LogsAPI";
import {PricingTier} from "../../../Constants/PricingCategories";


const EventTypes: {[key: number] : string} = {
    0: "TwitchCheerRedemption",
    1: "StreamLabsTipRedemption",
    2: "StreamElementsRedemption",
    3: "AccountCreationThroughExtension",
    4: "AccountCreationThroughWebsite",
    5: "OfficialButcherCreation",
    6: "ButcherProposal",
    7: "ButcherAcceptedForever",
    8: "ButcherAcceptedOnce",
    9: "ButcherRejected",
    10: "ButcherActivation",
    11: "ButcherDeactivation",
    12: "GIV",
    13: "TAK",
    14: "ChatCommandGiv",
    15: "ChannelPointsRedemption",
    16: "StreamStarted",
}

const MessageFormat: {[key: string] : string} = {
    TwitchCheerRedemption: "|user| |%%earned| a |tokenType| by |$$cheering|. New token balance: |tokenBalance|",
    StreamLabsTipRedemption: "|user| |%%earned| a |tokenType| by |$$donating through StreamLabs|. New token balance: |tokenBalance|",
    StreamElementsRedemption: "|user| |%%earned| a |tokenType| via the |$$StreamElements store|. New token balance: |tokenBalance|",
    AccountCreationThroughExtension: "|%%Created account| for |user| directly through the extension",
    AccountCreationThroughWebsite: "|%%Created account| for |user| through the website",
    OfficialButcherCreation: "|user| |%%created a new butcher|: |extraInfo|",
    ButcherProposal: "|user| |%%proposed| a new butcher: |extraInfo|",
    ButcherAcceptedForever: "|user| |%%accepted| a butcher forever: |extraInfo|",
    ButcherAcceptedOnce: "|user| |%%accepted| a butcher for a single use: |extraInfo|",
    ButcherRejected: "|user| |%%rejected| a butcher: |extraInfo|",
    ButcherActivation: "|user| used a |tokenType| to |%%activate| |extraInfo|. New token balance: |tokenBalance|",
    ButcherDeactivation: "|user| |%%deactivated| |extraInfo|",
    GIV: "|user| has been |%%GIVen| |extraInfo| of type |tokenType|. New token balance: |tokenBalance|",
    TAK: "|user| has been |%%TAKen away| |extraInfo| of type |tokenType|. New token balance: |tokenBalance|",
    ChatCommandGiv: "|user| has |%%received| a |tokenType| via chat command. New balance: |tokenBalance|. |extraInfo|.",
    ChannelPointsRedemption: "|user| |%%earned| a |tokenType| by |$$redeeming channel points|. New token balance: |tokenBalance|",
    StreamStarted: "TheVocalButcher went live!",

}

// const formatDate = (date: Date) => date.toLocaleString('en-US', {
//     weekday: 'short', // long, short, narrow
//     day: 'numeric', // numeric, 2-digit
//     year: 'numeric', // numeric, 2-digit
//     month: 'short', // numeric, 2-digit, long, short, narrow
//     hour: 'numeric', // numeric, 2-digit
//     minute: 'numeric', // numeric, 2-digit
//     second: 'numeric', // numeric, 2-digit
// })

const formatDate = (date: Date) => date.toISOString().split('.')[0] + "Z";
export function LogEntryToJSX(logEntry: HomykEventLogEntry, classes: any){
    let tokenizedMessage = MessageFormat[EventTypes[logEntry.eventType]].split('|');
    return (<span>
        <span className={classes.date}>[{formatDate(new Date(logEntry.dateTime))}]</span>
        {tokenizedMessage.map((section, index) =>
        {
            if (section === "user"){
                return <span key={index} className={classes.userName}>{logEntry.userDisplayName}</span>
            }
            if (section === "tokenType"){
                return <span key={index} className={classes.tokenType}>{logEntry.tokenTier === PricingTier.Base ? "Normal token" : "Brütal token"}</span>
            }
            if (section === "extraInfo"){
                return <span key={index} className={classes.extraInfo}>{logEntry.extraInfo}</span>
            }
            if (section === "tokenBalance"){
                return <span key={index} className={classes.payment}>{logEntry.newTokenBalance ?? "<not logged>"}</span>
            }
            if (section.startsWith("%%")){
                return <span key={index} className={classes.action}>{section.substring(2)}</span>
            }
            if (section.startsWith("$$")){
                return <span key={index} className={classes.payment}>{section.substring(2)}</span>
            }

            return <span key={index}>{section}</span>
        })}
    </span>);
}