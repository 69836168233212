import ButcherBrowseView from "../Views/ButcherBrowseView/ButcherBrowseView";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import React from "react";
import ButcherEditionView from "../Views/ButcherEditionView/ButcherEditionView";
import ButcherCreationView from "../Views/ButcherCreationView/ButcherCreationView";
import ThemeWrapper from "./ThemeWrapper";
import LoginRedirectView from "../Views/LoginRedirectView/LoginRedirectView";
import AdminPanelView from "../Views/Admin/AdminPanel/AdminPanelView";
import {LoginPage} from "./loginPage";
import {ForbiddenPage} from "./forbiddenPage";
import ExtensionConfigurationView from "../Views/ConfigurationView/ExtensionConfigurationView";
import ExtensionContainer from "../Views/ExtensionView/ExtensionContainer";
import MobileView from "../Views/ExtensionView/MobileView";
import AudioTest from "../audio/AudioTest";
import LogsView from "../Views/Admin/Logs/LogsView";
import ReloadOnDemandPOC from "../POC/ReloadOnDemandPOC";
import AudioTestRefs from "../audio/AudioTestRefs";
import {ActiveButchersSidebar} from "../Views/ObsComponents/ActiveButchersSidebar";
import {UserAccountsList} from "../Views/Admin/UserAccountManagement/UserAccountsList";
import ButcherAlertBox from "../Views/ObsComponents/ButcherAlertBox";
import WheelchanAdmin from "../WheelChan/WheelView/WheelchanAdmin";
import ActiveSkullsViewComponent from "../WheelChan/WheelView/SkullsView/activeSkullsView";
import {WheelPageComponent} from "../WheelChan/WheelView/wheelPageComponent";
import WorthinessVerifier from "../WheelChan/WheelView/WorthinessVerifier";
import OverlayTimersView from "../Views/ObsComponents/Halovember/OverlayTimersView";
import HalovemberTimersTriggerer from "../Views/Admin/HalovemberTimers/HalovemberTimersTriggerer";
import GeneralAlertBox from "../Views/ObsComponents/GeneralAlertBox/GeneralAlertBox";
import ObsComponentsIndex from "../Views/ObsComponentsIndex";
import ErrorHelpView from "../Views/Help/ErrorHelpView";
import HelpView from "../Views/Help/HelpView";

export default function ButcherRouter()
{
    return (
        <Router>
            <Switch>
                <Route path={"/butcherAlertBox"}><ButcherAlertBox/></Route>
                <Route path={"/generalAlertBox"}><GeneralAlertBox/></Route>
                {/*<Route path={"/alert"}><AlertComponent/></Route>*/}
                <Route path={"/audioTest"}><AudioTest/></Route>
                <Route path={"/audioTestRefs"}><AudioTestRefs/></Route>
                <Route path={"/butcherSidebarBig"}><ActiveButchersSidebar showEnqueuedButchers={true} useBigVersion={true}/></Route>
                <Route path={"/butcherSidebar"}><ActiveButchersSidebar showEnqueuedButchers={true} useBigVersion={false}/></Route>
                <Route path={"/halovemberTimers"}><OverlayTimersView/></Route>
                <Route path={"/obsComponentsIndex"}><ThemeWrapper><ObsComponentsIndex/></ThemeWrapper></Route>
                <Route path={"/halovemberTimersTriggerer"}><ThemeWrapper><HalovemberTimersTriggerer/></ThemeWrapper></Route>
                {/*<Route path={"/test1"}>*/}
                {/*    <ThemeWrapper><ProposalsView/></ThemeWrapper>*/}
                {/*</Route>*/}
                {/*<Route path={"/test2"}>*/}
                {/*    <ThemeWrapper isExtension><ExtensionContainer/></ThemeWrapper>*/}
                {/*</Route>*/}
                <Route path={"/reloadTest"}>
                    <ReloadOnDemandPOC/>
                </Route>
                <Route path={"/login"}>
                    <ThemeWrapper><LoginPage/></ThemeWrapper>
                </Route>
                <Route path={"/forbidden"}>
                    <ThemeWrapper><ForbiddenPage/></ThemeWrapper>
                </Route>
                <Route path={"/admin/logs"}>
                    <ThemeWrapper><LogsView/></ThemeWrapper>
                </Route>
                <Route path={"/admin/controlpanel"}>
                    <ThemeWrapper><AdminPanelView/></ThemeWrapper>
                </Route>
                <Route path={"/admin/userAccounts"}>
                    <ThemeWrapper><UserAccountsList/></ThemeWrapper>
                </Route>
                <Route path={"/twitchApiIntegrationRedirect"} render={(props) =>
                    <ThemeWrapper><LoginRedirectView {...props} apiIntegrationType={"twitchApiIntegration"}/></ThemeWrapper>
                }/>
                <Route path={"/streamLabsApiIntegrationRedirect"} render={(props) =>
                    <ThemeWrapper><LoginRedirectView {...props} apiIntegrationType={"streamLabsApiIntegration"}/></ThemeWrapper>
                }/>
                <Route path={"/loggingRedirect"} render={(props) =>
                    <ThemeWrapper><LoginRedirectView {...props}/></ThemeWrapper>
                }/>
                <Route path={"/video_overlay"}>
                    <ThemeWrapper isExtension><ExtensionContainer/></ThemeWrapper>
                </Route>
                <Route path={"/errorhelp"}>
                    <ThemeWrapper><ErrorHelpView/></ThemeWrapper>
                </Route>
                <Route path={"/help"}>
                    <ThemeWrapper><HelpView/></ThemeWrapper>
                </Route>
                <Route path={"/mobile"}>
                    <ThemeWrapper isExtension><MobileView/></ThemeWrapper>
                </Route>
                <Route path={"/config"}>
                    <ThemeWrapper><ExtensionConfigurationView/></ThemeWrapper>
                </Route>
                <Route path={"/create"}
                       render={(props) => <ThemeWrapper><ButcherCreationView {...props}/></ThemeWrapper>}/>
                <Route path={"/edit/:id"}
                       render={(props) => <ThemeWrapper><ButcherEditionView {...props}/></ThemeWrapper>}/>
                <Route path={"/wheelchan-admin"}>
                    <ThemeWrapper><WorthinessVerifier showHaloSeed><WheelchanAdmin/></WorthinessVerifier></ThemeWrapper>
                </Route>
                <Route path={"/wheelchan-instance/:id/skulls"}
                       render={(props) => <ActiveSkullsViewComponent {...props} editMode={true}/>}/>
                <Route path={"/wheelchan-instance/:id/edit"} render={(props) =>
                        (<ThemeWrapper><WorthinessVerifier><WheelPageComponent {...props} editMode={true}/></WorthinessVerifier></ThemeWrapper>)
                }/>
                <Route path={"/wheelchan-instance/:id"} render={(props) =>
                    <ThemeWrapper><WorthinessVerifier><WheelPageComponent {...props}/></WorthinessVerifier></ThemeWrapper>
                }/>

                <Route path={"/"}>
                    <ThemeWrapper><ButcherBrowseView/></ThemeWrapper>
                </Route>
            </Switch>
        </Router>
    )
}