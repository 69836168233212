import React, {useCallback, useEffect, useState} from "react";
import {Typography} from "@material-ui/core";

function formatTime(totalMilliseconds){
    if (totalMilliseconds <= 0) {
        return "00:00"
    }
    let minutes = Math.floor(totalMilliseconds/(60*1000));
    let seconds = Math.floor((totalMilliseconds%(60*1000)/1000));
    if (minutes < 10){
        minutes = "0" + minutes;
    }
    if (seconds < 10){
        seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
}

export default function HalovemberTimer(props)
{
    let [currentTimeLeft, setCurrentTimeLeft] = useState(props.duration * 1000)
    let [finished, setFinished] = useState(false);

    const getRemainingTime = useCallback( () => {
        const lastTickingMoment = Date.now();
        const timeSpanned = lastTickingMoment - new Date(props.timerStartTime);
        const remainingTime = (props.duration) - timeSpanned;

        return remainingTime;
    }, [props])

    useEffect(() => {
        const refreshIntervalTime = 100;
        const timeLeft = getRemainingTime();
        setCurrentTimeLeft(timeLeft);
        const timeoutHandler = setInterval(() => {
            const timeLeft = getRemainingTime();
            setCurrentTimeLeft(timeLeft);
            if (timeLeft <= 0 && !finished){
                setFinished(true);

            }
            if (timeLeft <= -3000){
                clearInterval(timeoutHandler);
                props.onEnd();
            }
        }, refreshIntervalTime);

        return () => {clearTimeout(timeoutHandler)}
    }, [getRemainingTime, props, finished])

    return (
        <React.Fragment>
            <Typography variant={"h5"} className={props.className}>
    {finished ? "Done!" : formatTime(currentTimeLeft)}
    </Typography>
    </React.Fragment>
)

}